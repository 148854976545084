import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import reviewService from "../../services/reviewService";

import "./style.css";
import Review from "../Review/Review";
import { useTranslation } from "react-i18next";

export default function Testimonial() {
  const { t } = useTranslation();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    try {
      reviewService.getApprovedReviews().then((res) => {
        if (res.data && res.data.length) {
          setReviews(res.data);
        }
      });
    } catch (ex) {}
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-area section-padding">
      <div className="container">
        <div className="col-12">
          <div className="wpo-section-title">
            <span>{t("clients-say")}</span>
            <h2>
              {t("our-clients")} <br /> {t("are-important")}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonial-slider clearfix">
              <Slider {...settings}>
                {reviews.map((item, index) => (
                  <Review item={item} key={index} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
