import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";
import AdminAllReviewsPage from "../AdminAllReviewsPage/AdminAllReviewsPage";
import AdminReservations from "../AdminReservations/AdminReservations";
import AdminRoomPage from "../AdminRoomPage/AdminRoomPage";
import AdminVouchersPage from "../AdminVouchersPage/AdminVouchersPage";
import AllOffersPage from "../AllOffersPage/AllOffersPage";
import CreateOfferPage from "../CreateOfferPage/CreateOfferPage";
import LoginPage from "../LoginPage";
import SubscribersPage from "../SubscribersPage/SubscribersPage";

export default function AdminRoot() {
  const history = useHistory();
  const jwt = localStorage.getItem("jwt");
  const path = history.location.pathname;
  if (!jwt && path !== "/admin/login") {
    return (
      <Redirect
        to={{
          pathname: "/admin/login",
        }}
      />
    );
  }

  return (
    <Router basename="/admin">
      <Switch>
        <Route path="/create-offer" component={CreateOfferPage} />
        <Route path="/all-offers" component={AllOffersPage} />
        <Route path="/reviews" component={AdminAllReviewsPage} />
        <Route path="/vouchers" component={AdminVouchersPage} />
        <Route path="/subscribers" component={SubscribersPage} />
        <Route path="/rooms" component={AdminRoomPage} />
        <Route path="/reservations" component={AdminReservations} />
        <Route path="/login" component={LoginPage} />
        <Route path="/" component={AdminRoomPage} />
        <Route component={AdminRoomPage} />
      </Switch>
    </Router>
  );
}
