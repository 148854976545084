import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Joi from "joi";
import { validate } from "../../../../utils/validation";

import reviewService from "../../../../services/reviewService";
import classNames from "classnames";
import "./addReview.css";
import { useTranslation } from "react-i18next";

const schema = Joi.object({
  name: Joi.string().required().min(3).max(50),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  stars: Joi.number().required(),
  review: Joi.string().required().min(3),
});

const defaultState = {
  name: "",
  email: "",
  review: "",
  stars: "",
};

const AddReview = (props) => {
  const { t } = useTranslation();
  const submitHandler = async (e) => {
    e.preventDefault();
    const starsNum = stars.filter((x) => x === activeStar).length;
    let submitData = { ...formData };
    submitData.stars = starsNum;
    if ((await validate(submitData, schema, setErrors)) === null) {
      try {
        await reviewService.createReview({
          stars: starsNum,
          text: formData.review,
          senderName: formData.name,
          email: formData.email,
          status: "pending",
        });
        toast.success(t("send-review-success"));
        setFormData(defaultState);
      } catch (ex) {}
    }
  };

  const [formData, setFormData] = useState(defaultState);

  const totalStars = 5;
  const initial = 1;
  const [initialStarsState, setInitialStarsState] = useState();
  const [stars, setStars] = useState([]);
  const [errors, setErrors] = useState({});

  const star = "flaticon-star-1";
  const activeStar = "flaticon-star";

  useEffect(() => {
    // Define the initial state of the stars array.
    let initialStarsState = [];
    for (let i = 0; i < totalStars; i++) {
      if (i + 1 <= initial) {
        initialStarsState.push(activeStar);
      } else {
        initialStarsState.push(star);
      }
    }

    setInitialStarsState(initialStarsState);
    setStars(initialStarsState);
  }, [initial, totalStars]);

  function onStarHover(noStar) {
    let newStars = [...stars];

    // Set all previous stars as active
    for (let i = 0; i <= noStar; i++) {
      newStars[i] = activeStar;
    }

    // Reset all next stars
    for (let i = noStar + 1; i < initialStarsState.length; i++) {
      newStars[i] = star;
    }

    // setNoStars(noStar + 1);
    setStars(newStars);
    handleChange("stars", newStars);
  }

  const handleChange = (field, value) => {
    let dataCopy = { ...formData };
    dataCopy[field] = value;
    setFormData({ ...dataCopy });
  };

  return (
    <div className="add-review">
      <div className="room-title">
        <h2>{t("add-review")}</h2>
      </div>
      <div className="wpo-blog-single-section">
        <div className="review-text">
          <div className="stars-container">
            <ul>
              {stars.map((star, index) => {
                return (
                  <li key={index} onMouseEnter={() => onStarHover(index)}>
                    <div>
                      <i className={`fi ${star}`}></i>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="review-add">
          <div className="custom-comment-respond">
            <form
              onSubmit={submitHandler}
              method="post"
              id="commentform"
              className="custom-comment-form"
            >
              <div className="custom-form-inputs-container">
                <InputWithError
                  onChange={(e) => handleChange("name", e.currentTarget.value)}
                  value={formData.name}
                  placeholder={t("name")}
                  type="text"
                  error={errors.name}
                  classes={["custom-form-input"]}
                />

                <InputWithError
                  onChange={(e) => handleChange("email", e.currentTarget.value)}
                  value={formData.email}
                  placeholder={t("email")}
                  type="email"
                  error={errors.email}
                  classes={["custom-form-input"]}
                />
              </div>
              <div></div>
              <div className="form-textarea">
                <textarea
                  onChange={(e) =>
                    handleChange("review", e.currentTarget.value)
                  }
                  value={formData.review}
                  id="comment"
                  placeholder={t("your-review")}
                  className="custom-form-textarea"
                ></textarea>
                {errors.review && (
                  <p className="error-paragraph">{t("required-field")}</p>
                )}
              </div>
              {/* <div className="form-check">g
                <div className="shipp pb">
                  <input type="checkbox" id="c2" name="cc" />
                  <label htmlFor="c2">
                    <span></span>Save my name, email, and website in this
                    browser for the next time I comment.
                  </label>
                </div>
              </div> */}
              <div className="custom-form-submit">
                <input id="submit" value={t("send")} type="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputWithError = ({
  name,
  value,
  onChange,
  error,
  classes,
  placeholder,
  type,
}) => {
  const { t } = useTranslation();
  return (
    <div className="custom-input-wrapper">
      <input
        name={name}
        value={value}
        onChange={onChange}
        className={classNames(classes)}
        type={type ? type : ""}
        placeholder={placeholder}
      />
      {error && <p className="error-paragraph">{t("required-field")}</p>}
    </div>
  );
};

export default AddReview;
