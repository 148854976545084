import React from "react";
import Navbar2 from "../../components/Navbar2";
import Hero from "../../components/hero";
import SearchSection from "../../components/SearchSection";
import About2 from "../../components/about2";
import RoomSection from "../../components/RoomSection";
import Features from "../../components/Features";
import Testimonial from "../../components/Testimonial";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import LineSeparator from "../../components/LineSeparator/LineSeparator";
import "./style.css";

import slide1 from "../../images/slider/slide-1.jpg";
import slide2 from "../../images/slider/slide-2.jpg";
import slide3 from "../../images/slider/slide-3.jpg";
import slide4 from "../../images/slider/slide-4.jpg";
import slide5 from "../../images/slider/slide-5.jpg";
import slide6 from "../../images/slider/slide-6.jpg";
import slide7 from "../../images/slider/slide-7.jpg";
import slide8 from "../../images/slider/slide-8.jpg";

const HomePage2 = () => {
  const sliderImages = [
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
  ];
  return (
    <div className="homepage">
      <Navbar2 />
      <Hero heroClass={"hero-style-2"} images={sliderImages} />
      <SearchSection hasDates={true} />
      <About2 isHome />
      <LineSeparator />
      <RoomSection />
      <LineSeparator />
      <Features showImportant={true} />
      <Testimonial />
      <Footer />
      <Scrollbar />
    </div>
  );
};
export default HomePage2;
