import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";

const HeaderTopbar = () => {
  const { t } = useTranslation();
  return (
    <div className="topbar">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-6 col-sm-7 col-12">
            <div className="contact-intro">
              <ul>
                <li>
                  <i className="fi ti-location-pin"></i>
                  {t("hotel-address")}
                </li>
                <li>
                  <i className="fi flaticon-email"></i>{" "}
                  reservation@sofiahotel.net
                </li>
              </ul>
            </div>
          </div>
          <div className="col col-md-6 col-sm-5 col-12">
            <div className="contact-info">
              <ul>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/HotelSofiaBG"
                  >
                    <i className="ti-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/hotelsofiagoldensands?utm_medium=copy_link "
                  >
                    <i className="ti-instagram"></i>
                  </a>
                </li>

                {/* <li><Link className="theme-btn-s2" to="/">Book A Room </Link></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
