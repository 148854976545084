import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory, withRouter } from "react-router-dom";

//Services
import authService from "../../services/authService";

import "./style.scss";
import Joi from "joi";
import { validate } from "../../utils/validation";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .label("Моля, въведи твоя имейл адрес!"),
  password: Joi.string().min(1).label("Моля, въведи твоята парола!"),
});

const LoginPage = () => {
  const history = useHistory();
  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );

  const submitForm = async (e) => {
    e.preventDefault();
    if ((await validate(value, schema, () => {})) === null) {
      validator.hideMessages();
      try {
        await authService.login(value.email, value.password);
        history.push("/rooms");
      } catch (err) {
        toast.error(err.response ? err.response.data : "Нещо се обърка!");
      }
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };
  return (
    <Grid className="loginWrapper">
      <Grid className="loginForm">
        <h2>Вход</h2>
        <form onSubmit={submitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Имейл"
                value={value.email}
                variant="outlined"
                name="email"
                label="Имейл"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("email", value.email, "required|email")}
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="inputOutline"
                fullWidth
                placeholder="Парола"
                value={value.password}
                variant="outlined"
                name="password"
                type="password"
                label="Парола"
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={(e) => changeHandler(e)}
                onChange={(e) => changeHandler(e)}
              />
              {validator.message("password", value.password, "required")}
            </Grid>
            <Grid item xs={12}>
              <Grid className="formFooter">
                <Button fullWidth className="cBtnTheme" type="submit">
                  Вход
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default withRouter(LoginPage);
