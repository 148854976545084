import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

import subscriberService from "../../services/subscribersService";
import Joi from "joi";
import { validateProperty } from "../../utils/validation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import Logo from "../Logo/Logo";
import logoMain from "../../images/logo-main.png";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

const Footer = () => {
  const [subscriber, setSubscriber] = useState("");
  const { t } = useTranslation();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (await validateProperty("email", subscriber, schema, () => {})) {
      try {
        const res = await subscriberService.createSubscriber(subscriber);
        if (res.status === 200) {
          toast.success(t("subscribe-success"));
          setSubscriber("");
        }
      } catch (ex) {
        toast.error("wrong");
      }
    }
  };

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="wpo-footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                {/* <Logo logoColor="white" /> */}
                <img className="logo-main" src={logoMain} alt="" height="150" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <ul id="footer-social-icons">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/HotelSofiaBG"
                    >
                      <i className="ti-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/hotelsofiagoldensands?utm_medium=copy_link "
                    >
                      <i className="ti-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget-title">
                    <h3>{t("contact")} </h3>
                  </div>
                  {/* <p>online store with lots of cool and exclusive features</p> */}
                  <div className="contact-ft">
                    <ul>
                      <li>
                        <i className="fi ti-location-pin"></i>
                        {t("hotel-address")}
                      </li>
                      <li>
                        <i className="fi ti-mobile"></i>
                        <a style={{ color: "white" }} href="tel:+359898774435">
                          + 359 898 774 435
                        </a>
                      </li>
                      <li>
                        <i className="fi flaticon-email"></i>
                        reservation@sofiahotel.net
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>{t("useful-links")}</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        {t("about")}
                      </Link>
                    </li>

                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        {t("contact-us")}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/events">
                        {t("our-events")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="widget newsletter-widget">
                  <div className="widget-title">
                    <h3>{t("newsletter")}</h3>
                  </div>
                  <form onSubmit={SubmitHandler}>
                    <div className="input-1">
                      <input
                        type="email"
                        className="form-control"
                        placeholder={t("email-address")}
                        required=""
                        value={subscriber}
                        onChange={(e) => setSubscriber(e.target.value)}
                      />
                    </div>
                    <div className="submit clearfix">
                      <button type="submit">
                        {t("subscribe")}
                        <i className="ti-angle-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-6 col-md-6 col-12">
              <div className="term">
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/general-terms">
                      {t("general-terms")}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/privacy">
                      {t("privacy")}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="hotel-policy">
                      {t("terms")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="/">
                      Cookies
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col col-lg-6 col-md-6 col-12">
              <p className="copyright">&copy; {t("all-rights-reserved")}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
