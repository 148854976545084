import React from "react";
import loadingSpinner from "../../images/loading-spinner.gif";
import "./style.css";
export default function index() {
  return (
    <div className="loading-spinner-container">
      <img className="loading-spinner" src={loadingSpinner} alt="" />
    </div>
  );
}
