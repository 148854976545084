import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import Navbar from "../../components/Navbar";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import offersService from "../../services/offersService";

import "./style.css";
import useOffers from "../../utils/useOffers";
export default function AllOffersPage() {
  const { i18n } = useTranslation();
  const history = useHistory();

  const { offers, setOffers, loading } = useOffers();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState();

  const lang = i18n.language.toUpperCase();

  const openDeleteModal = (offer) => {
    setIsDeleteModalOpen(true);
    setOfferToDelete(offer._id);
  };

  const closeModal = (e) => {
    e.stopPropagation();
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    try {
      const res = await offersService.deleteOffer(offerToDelete);
      if (res.status && res.status === 200) {
        let deletedOfferId = offerToDelete;
        let offersCopy = [...offers].filter((x) => x._id !== deletedOfferId);
        setOffers([...offersCopy]);
        setIsDeleteModalOpen(false);
      }
    } catch (ex) {
      console.log(ex);
      toast.error("Нещо се обърка. Моля опитай отново");
    }
  };

  const toggleOfferStatus = async (offer) => {
    const res = await offersService.changeOfferStatus(offer._id, offer.status);
    if (res.status === 200) {
      let offersCopy = [...offers];
      let index = offersCopy.findIndex((x) => x._id === offer._id);
      offersCopy[index].status = res.data;
      setOffers(offersCopy);
      toast.success(
        `Успешно ${res.data === "approved" ? "показана" : "скрита"} оферта`
      );
    }
  };

  const editOffer = (offer) => {
    history.push({
      pathname: "/create-offer",
      state: {
        data: offer,
      },
    });
  };

  return (
    <div className="admin-all-offer-page">
      <Navbar />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="admin-all-offers-container">
          {offers && offers.length ? (
            offers.map((offer, index) => {
              return (
                <div key={index} className="offer-container">
                  <div className="offer-container__tools">
                    <Button
                      text={offer.status === "pending" ? "Покажи" : "Скрий"}
                      classes={["offer-status-btn"]}
                      onClick={() => toggleOfferStatus(offer)}
                    />
                    <Button
                      text={"Промени"}
                      classes={["offer-edit-btn"]}
                      onClick={() => editOffer(offer)}
                    />
                    <Button
                      text="Изтрий"
                      classes={["delete-offer-button"]}
                      onClick={() => openDeleteModal(offer)}
                    />
                  </div>
                  <img src={offer.image} alt="" />

                  <div className="offer-container__title">
                    <h4>{offer[`title${lang}`]}</h4>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: offer[`text${lang}`] }}
                  />
                </div>
              );
            })
          ) : (
            <div style={{ width: "100%" }}>
              <h3 className="center-text">Няма създадени оферти</h3>
              <Button
                onClick={() => history.push("/create-offer")}
                text="Създай оферта"
                style={{
                  margin: "0 auto",
                  display: "block",
                  marginTop: "12px",
                }}
              />
            </div>
          )}
        </div>
      )}
      {isDeleteModalOpen && (
        <div onClick={(e) => closeModal(e)} className="delete-offer-modal">
          <div className="delete-offer-modal__content">
            <p>Сигурен ли си, че искаш да изтриеш тази оферта?</p>
            <div className="delete-offer-modal__content-buttons">
              <Button
                text="Да"
                onClick={(e) => handleDelete(e)}
                classes={["confirm-delete-button"]}
              />
              <Button text="Не" onClick={(e) => closeModal(e)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
