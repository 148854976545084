import http from "./httpService";
import { apiUrl } from "../config/config.json";
const apiEndpoint = apiUrl + "/payments";

const createPaymentIntent = async (data) => {
  const paymentIntent = await http.post(
    `${apiEndpoint}/create-payment-intent`,
    data
  );
  return paymentIntent;
};

const exportedFunctions = {
  createPaymentIntent,
};

export default exportedFunctions;
