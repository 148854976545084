import http from "./httpService";
import { apiUrl } from "../config/config.json";

const apiEndpoint = apiUrl + "/bookings";
const getBookings = async () => {
  return await http.get(apiEndpoint);
};

const cancelBooking = async (ref) => {
  return await http.put(apiEndpoint + "/" + ref);
};

const exportedFunctions = {
  getBookings,
  cancelBooking,
};

export default exportedFunctions;
