import React, { useState } from "react";
import Joi from "joi";
import { validate } from "../../utils/validation";
import { useTranslation } from "react-i18next";
import emailSVC from "../../services/emailService";
import { toast } from "react-toastify";
import { fromEmail } from "../../config/config.json";

const ContactForm = () => {
  const { t } = useTranslation();

  const schema = Joi.object({
    firstName: Joi.string()
      .min(3)
      .max(50)
      .required()
      .label(t("required-field")),
    lastName: Joi.string().min(3).max(50).required().label(t("required-field")),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label(t("required-field")),
    subject: Joi.string().min(3).required().label(t("required-field")),
    message: Joi.string().min(3).required().label(t("required-field")),
  });

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState({});

  const changeHandler = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData({ ...dataCopy });
  };

  const subimtHandler = async (e) => {
    e.preventDefault();
    if ((await validate(data, schema, setError)) === null) {
      // send email here
      const emailData = {
        subject: data.subject,
        text: data.message,
        to: fromEmail,
        replyTo: data.email,
        html: `<p>${data.message}</p>`,
      };
      try {
        await emailSVC.sendEmail(emailData);
        setData({
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: "",
        });
        toast.success(t("send-email-success"));
      } catch (err) {
        console.log(err);
        toast.success(t("send-email-fail"));
      }
    }
  };

  return (
    <form onSubmit={subimtHandler} className="contact-form">
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className="form-field">
            <input
              value={data.firstName}
              onChange={(e) =>
                changeHandler("firstName", e.currentTarget.value)
              }
              type="text"
              name="name"
              placeholder={t("name")}
            />
            <p>{error.firstName ? error.firstName : ""}</p>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="form-field">
            <input
              value={data.lastName}
              onChange={(e) => changeHandler("lastName", e.currentTarget.value)}
              type="text"
              name="lastname"
              placeholder={t("last-name")}
            />
            <p>{error.lastName ? error.lastName : ""}</p>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="form-field">
            <input
              onChange={(e) => changeHandler("email", e.currentTarget.value)}
              value={data.email}
              type="email"
              name="email"
              placeholder={t("email-address")}
            />
            <p>{error.email ? error.email : ""}</p>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="form-field">
            <input
              onChange={(e) => changeHandler("subject", e.currentTarget.value)}
              value={data.subject}
              type="text"
              name="subject"
              placeholder={t("subject")}
            />
            <p>{error.subject ? error.subject : ""}</p>
          </div>
        </div>
        <div className="col-12">
          <div className="form-field">
            <textarea
              value={data.message}
              onChange={(e) => changeHandler("message", e.currentTarget.value)}
              name="message"
              placeholder={t("message")}
            ></textarea>
            {error.message && <p>{error.message}</p>}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-submit">
            <button type="submit" className="theme-btn">
              {t("send")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
