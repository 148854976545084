import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import {
  getDateDB,
  getDateView,
  sevenDaysBeforeBooking,
} from "../../utils/date";
import RoomInfo from "../../js/RoomInfo";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import bookingsSVC from "../../services/bookingsService";
import XMLReportSVC from "../../services/XMLReportService";

import "./style.css";

export default function AdminReservations() {
  const { t } = useTranslation();
  const [bookings, setBookings] = useState();

  const [reportMonth, setReportMonth] = useState({ label: "Януари", value: 0 });
  const [reportYear, setReportYear] = useState({ label: "2022", value: 2022 });

  useEffect(() => {
    bookingsSVC.getBookings().then((res) => {
      if (res.status === 200) {
        const reservations = res.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setBookings(reservations);
      }
    });
  }, []);

  const getRoomName = (room) => {
    const roomObject = Object.keys(RoomInfo).find((x) => x === room.roomName);
    return `${t(RoomInfo[roomObject].roomKey)} - ${room.count}бр.`;
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "scheduled":
        return { backgroundColor: "#00BDEB" };
      case "paid":
        return { backgroundColor: "var(--color-green-1)" };
      case "unsuccessful":
        return { backgroundColor: "red" };
      case "cancelled":
        return { backgroundColor: "var(--color-red-1)" };
      case "pending":
        return { backgroundColor: "lightblue" };
      case "reqForCancellation":
        return { backgroundColor: "orange" };
      default:
        break;
    }
  };

  const getStatusText = (status, scheduleDate) => {
    switch (status) {
      case "paid":
        return "Платена";
      case "unsuccessful":
        return "Неуспешна";
      case "scheduled":
        return `Насрочена за плащане на ${getDateView(
          getDateDB(sevenDaysBeforeBooking(scheduleDate))
        )}`;
      case "cancelled":
        return "Отказана";
      case "pending":
        return "Изчакваща";
      case "reqForCancellation":
        return "Заявена за отказване";

      default:
        return "";
    }
  };

  const getReservationDate = (date) => getDateView(getDateDB(date));

  const onGenerateReport = async () => {
    const XMLString = await XMLReportSVC.generate({
      month: reportMonth.value,
      year: reportYear.value,
    });

    // Download the file
    const blob = new Blob([XMLString]);
    const url = URL.createObjectURL(blob);

    let a = document.createElement("A");
    a.href = url;
    a.download = `${reportMonth.label}_${reportYear.label}_report.xml`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderReservation = (reservation, index) => {
    return (
      <tr key={index}>
        <td>{getReservationDate(reservation.date)}</td>
        <td>{reservation.bookingReference}</td>
        <td>{reservation.name}</td>
        <td>{reservation.email}</td>
        <td>{reservation.phone}</td>
        <td>{getReservationDate(reservation.startDate)}</td>
        <td>{getReservationDate(reservation.endDate)}</td>
        <td>
          {reservation.roomType
            .map((x) => getRoomName(x))
            .map((x, index2) => (
              <React.Fragment key={index2}>
                {x}
                <br />
              </React.Fragment>
            ))}
        </td>
        <td>{reservation.adults}</td>
        <td>{reservation.children}</td>
        <td>{reservation.price} лв.</td>
        <td>{reservation.voucherUsed ? reservation.voucherUsed.name : "-"}</td>
        <td style={getStatusStyle(reservation.status)}>
          {getStatusText(reservation.status, reservation.startDate)}
        </td>
        <td>
          {reservation.status === "paid" ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={reservation.receipt}
            >
              Виж
            </a>
          ) : (
            "-"
          )}
        </td>
      </tr>
    );
  };
  return (
    <div>
      <Navbar />
      <div className="reservations-page">
        <div className="report-wrapper">
          <div className="admin-room-page__select-wrapper">
            <h5>Месец</h5>
            <Select
              options={[
                { label: "Януари", value: 0 },
                { label: "Февруари", value: 1 },
                { label: "Март", value: 2 },
                { label: "Април", value: 3 },
                { label: "Май", value: 4 },
                { label: "Юни", value: 5 },
                { label: "Юли", value: 6 },
                { label: "Август", value: 7 },
                { label: "Септември", value: 8 },
                { label: "Октомври", value: 9 },
                { label: "Ноември", value: 10 },
                { label: "Декември", value: 11 },
              ]}
              onChange={(opt) => setReportMonth(opt)}
              defaultValue={{ label: "Януари", value: 0 }}
            />
          </div>
          <div className="admin-room-page__select-wrapper">
            <h5>Година</h5>
            <Select
              options={[
                { label: "2022", value: 2022 },
                { label: "2023", value: 2023 },
                { label: "2024", value: 2024 },
                { label: "2025", value: 2025 },
                { label: "2026", value: 2026 },
                { label: "2027", value: 2027 },
                { label: "2028", value: 2028 },
                { label: "2029", value: 2029 },
                { label: "2030", value: 2030 },
              ]}
              onChange={(opt) => setReportYear(opt)}
              defaultValue={{ label: "2022", value: 2022 }}
            />
          </div>
          <button
            className="main-button admin-room-page__submit-button"
            onClick={() => onGenerateReport()}
          >
            Генерирай Репорт
          </button>
        </div>

        <h3 className="center-text">Резервации</h3>
        <table className="reservations-table">
          <thead>
            <tr>
              <th>Дата</th>
              <th>Реф. номер</th>
              <th>Име</th>
              <th>Имейл</th>
              <th>Телефон</th>
              <th>Начална дата</th>
              <th>Крайна дата</th>
              <th>Тип стая</th>
              <th>Възрастни</th>
              <th>Деца</th>
              <th>Цена</th>
              <th>Ваучер</th>
              <th>Статус</th>
              <th>Разписка</th>
            </tr>
          </thead>
          <tbody>
            {bookings &&
              bookings.map((reservation, index) => {
                return renderReservation(reservation, index);
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
