const year = new Date().getFullYear();
export default {
  periodOne: [`${year}-05-01 - ${year}-05-15`, `${year}-09-22 - ${year}-10-10`],
  periodTwo: [`${year}-05-16 - ${year}-06-06`, `${year}-09-13 - ${year}-09-21`],
  periodThree: [
    `${year}-06-07 - ${year}-07-05`,
    `${year}-08-26 - ${year}-09-12`,
  ],
  periodFour: [`${year}-07-06 - ${year}-08-25`],
};
