import React from "react";
import MobileMenu from "../../components/MobileMenu";
import "./style.css";
import NavList from "../navlist";
// import Logo from "../Logo/Logo";
import logoMain from "../../images/logo-main.png";
import { useHistory } from "react-router-dom";
const Header = (props) => {
  const history = useHistory();
  return (
    <div className="middle-header">
      <div className={`header-style-3 ${props.hClass}`}>
        <div className="container-fluid">
          <div className="header-content">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                {/* <Logo logoColor="#192b4e" /> */}
                <img
                  onClick={() => history.push("/")}
                  className="logo-image"
                  src={logoMain}
                  alt=""
                />
              </div>
              <NavList />

              <div className="col-md-8 col-sm-8 col-8">
                <MobileMenu />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
