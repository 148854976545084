import React from "react";
import { Link, useHistory } from "react-router-dom";
import beach from "../../images/services/beach.jpg";
import pool from "../../images/services/pool.jpg";
import parking from "../../images/services/parking.jpg";
import bikeRent from "../../images/services/bike.jpg";
import playground from "../../images/services/playground.jpg";
import lobbyBar from "../../images/services/lobby.jpg";
import gym from "../../images/services/gym.jpg";
import "./style.css";
import { useTranslation } from "react-i18next";

const Features = ({ showImportant = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  let features = [
    {
      image: beach,
      heading: t("own-beach"),
      link: "/services",
      description: t("beach-description"),
    },
    {
      image: pool,
      heading: t("outdoor-pool"),
      link: "/services",
    },
    {
      image: parking,
      heading: t("parking"),
      link: "/services",
      description: t("parking-description"),
    },
    {
      image: playground,
      heading: t("playground"),
      link: "/services",
    },
    {
      image: bikeRent,
      heading: t("bike-rent"),
      link: "/services",
    },
    {
      image: lobbyBar,
      heading: t("lobby-bar"),
      link: "/services",
    },
    {
      image: gym,
      heading: t("fitness"),
      link: "/services",
    },
  ];

  const ClickHandler = () => {
    window.scrollTo(10, 0);
    console.log("scroll");
  };
  if (showImportant) features = features.slice(0, 6);
  const toServices = () => {
    if (!showImportant) return;
    ClickHandler();
    history.push("/services");
  };
  return (
    <div className="featured-area featured-sub">
      <div className="container-fluid">
        {showImportant && <h2 className="features-heading">{t("services")}</h2>}
        <div className="features-container">
          <div className="row grid features-map">
            {features.map((feature, i) => (
              <div className="col-lg-4 items col-md-6 col-sm-6 col-12" key={i}>
                <div className="featured-wrap" onClick={toServices}>
                  <div className="featured-img">
                    <img
                      className="img img-responsive"
                      src={feature.image}
                      alt=""
                      width="440"
                      height="294"
                    />
                    {!feature.description ? (
                      <div className="featured-content">
                        <Link onClick={ClickHandler} to={feature.link}>
                          {feature.heading}
                        </Link>
                      </div>
                    ) : (
                      <div className="hidden-content">
                        <h5>{feature.heading}</h5>
                        <p>{feature.description}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
