import React, { useEffect, useState } from "react";
import Pricing from "../Rcomponents/descontent/pricing";
import roomService from "../../../services/roomService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShower,
  faWifi,
  faEye,
  faUser,
  faPhone,
  faBaby,
  faTv,
  faVault,
  faWineGlass,
  faFan,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import RoomInfo from "../../../js/RoomInfo";

const Description = ({ room }) => {
  const { t } = useTranslation();
  const [roomData, setRoomData] = useState();
  const roomName = Object.keys(RoomInfo).filter(
    (x) => RoomInfo[x].name === room.name
  );
  useEffect(() => {
    roomService.getRoomByName(roomName).then((res) => {
      setRoomData(res.data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const yearCap = t("year-cap").replace("(", "").replace(")", "");
  const capacityString = `${t("maximum")}: ${room.adults} ${t("adults")} ${
    room.children ? `+ ${room.children} ${t("children")} ${yearCap}` : ""
  }`;
  return (
    <div className="Room-details-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="room-description">
              <div className="room-title">
                <h2>{t("description")}</h2>
              </div>
              <p className="p-wrap">{t(`${roomName}-description`)}</p>
            </div>
            <div className="room-details-service">
              <div className="row">
                <div className="room-details-item">
                  <div className="row">
                    <div className="col-md-5 col-sm-12 col-12">
                      <div className="room-d-text">
                        <div className="room-title">
                          <h2>{t("services")}</h2>
                        </div>
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faEye}
                            />
                            <span>{t(room.view)}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faWifi}
                            />
                            <span>WiFi</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faShower}
                            />
                            <span>{t("showerhead")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faPhone}
                            />
                            <span>{t("phone")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faBaby}
                            />
                            <span>{t("baby")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-12">
                      <div className="room-d-img">
                        <img src={RoomInfo[room.roomKey].image1} alt="" />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-12">
                      <div className="room-d-img">
                        <img src={RoomInfo[room.roomKey].image2} alt="" />
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12 col-12">
                      <div className="room-d-text2">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faTv}
                            />
                            <span>{t("tv")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faWineGlass}
                            />
                            <span>{t("minibar")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faVault}
                            />
                            <span>{t("vault")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faFan}
                            />
                            <span>{t("conditioner")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faWind}
                            />
                            <span>{t("dryer")}</span>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              size={"lg"}
                              style={{ marginRight: 15, width: 28 }}
                              icon={faUser}
                            />
                            <span>{capacityString}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {roomData && <Pricing roomData={roomData} />}
            {/* <AddReview /> */}
            {/* <OtherRoom /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
