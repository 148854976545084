import React, { Fragment } from "react";
import LineSeparator from "../../components/LineSeparator/LineSeparator";
import Navbar from "../../components/Navbar";
import cover from "../../images/restaurants/cover.jpg";
import restaurantMain from "../../images/restaurants/main.jpg";
import mediteranno from "../../images/restaurants/mediteranno.jpg";
import lobbyBar from "../../images/restaurants/lobby.jpg";
import poolBar from "../../images/restaurants/pool-bar.jpg";
import beachBar from "../../images/restaurants/beach-bar.jpg";
import { Icon } from "../../components/Icon/Icon";
import "./style.css";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import PageTitle from "../../components/pagetitle";
import { useTranslation } from "react-i18next";

const restaurants = [
  {
    name: "main-restaurant",
    description: "main-restaurant-description",
    dresscode: "official-dresscode",
    food: "international",
    workingHours: "07:30-10:00 / 12:30-14:00 / 18:00-20:00",
    image: restaurantMain,
  },
  {
    name: "a-la-carte-full",
    description: "mediterano-description",
    image: mediteranno,
    dresscode: "official-dresscode",
    reservation: true,
    food: "mid-international",
    workingHours: "12:00-00:00",
  },
  {
    name: "lobby-bar",
    description: "lobby-bar-description",
    image: lobbyBar,
    dresscode: "casual-dresscode",
    workingHours: "10:00 – 22:30",
  },
  {
    name: "pool-bar",
    description: "pool-bar-description",
    image: poolBar,
    dresscode: "casual-dresscode",
    workingHours: "09:00 – 19:00 ",
  },
  {
    name: "beach-bar",
    description: "beach-bar-description",
    image: beachBar,
    dresscode: "casual-dresscode",
    workingHours: "09:30 – 18:00",
  },
];

export default function RestaurantPage() {
  const { t } = useTranslation();
  return (
    <div>
      <Navbar />
      <PageTitle pageTitle={t("bars-restaurants")} img={cover} />
      <div className="restaurant-page-container">
        {restaurants.map((res, index) => (
          <Fragment>
            <SingleRestaurant res={res} key={index} t={t} />
            {index !== restaurants.length - 1 && <LineSeparator />}
          </Fragment>
        ))}
      </div>
      <Footer />
      <Scrollbar />
    </div>
  );
}

const SingleRestaurant = ({ res, t }) => {
  return (
    <div className="restaurant-item">
      <div className="restaurant-item__image">
        <img
          className="img img-responsive"
          // height="200"
          src={res.image}
          alt=""
        />
      </div>
      <div className="restaurant-item__information">
        <h3>{t(res.name)}</h3>
        <p>{t(res.description)}</p>
      </div>
      <div className="restaurant-item__additional-information">
        <div className="information-item">
          <Icon icon="hat" />
          <span>
            {t("dresscode")}: {t(res.dresscode)}
          </span>
        </div>
        <div className="information-item">
          <i className="fi flaticon-calendar"></i>
          <span>
            {res.reservation ? t("with-reservation") : t("no-reservation")}
          </span>
        </div>
        {res.food && (
          <div className="information-item">
            <Icon icon="fork-knife" />
            <span style={{ wordBreak: "break-all" }}>{t(res.food)}</span>
          </div>
        )}
        <div className="information-item">
          <i className="fi ti-location-pin"></i>
          <span>
            {t("location")}:
            <a
              className="see-map"
              href="https://www.google.com/maps/place/%D0%A1%D0%BE%D1%84%D0%B8%D1%8F+%D0%A5%D0%BE%D1%82%D0%B5%D0%BB/@43.2798694,28.0408537,16z/data=!4m8!3m7!1s0x0:0xfaf3e6201159e0ba!5m2!4m1!1i2!8m2!3d43.2796273!4d28.0406391"
            >
              {" "}
              {t("see-map")}
            </a>
          </span>
        </div>
        <div className="information-item">
          <i className="fa fa-clock-o"></i>
          <span>
            {t("working-hours")}: {res.workingHours}
          </span>
        </div>
      </div>
    </div>
  );
};
