import http from "./httpService";
import { apiUrl } from "../config/config.json";

const apiEndpoint = apiUrl + "/reviews";

const getAllReviews = async () => {
  return await http.get(apiEndpoint);
};

const createReview = async (data) => {
  const review = await http.post(apiEndpoint, data);
  return review;
};

const getApprovedReviews = async () => {
  const reviews = await http.get(`${apiEndpoint}/approved`);
  return reviews;
};

const approveReview = async (id) => {
  const review = await http.put(`${apiEndpoint}/approve/${id}`);
  return review;
};

const deleteReview = async (id) => {
  const review = await http.delete(`${apiEndpoint}/${id}`);
  return review;
};

const exportedFunctions = {
  getAllReviews,
  createReview,
  getApprovedReviews,
  deleteReview,
  approveReview,
};

export default exportedFunctions;
