import React from "react";
import sprite from "../../images/sprite.svg";
import classNames from "classnames";

export const Icon = ({ classes, icon, onClick }) => {
  return (
    <svg className={`icon ${classNames(classes)}`} onClick={onClick}>
      <use href={`${sprite}#${icon}`}></use>
    </svg>
  );
};
