import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import RoomSection from "../../components/RoomSection";
import SearchSection from "../../components/SearchSection";
import { useLocation } from "react-router-dom";
import hero3 from "../../images/room-hero.jpg";
import RoomInfo from "../../js/RoomInfo";
import RoomBookingView from "../../components/RoomBookingView";
import LoadingSpinner from "../../components/LoadingSpinner";
const RoomPage = () => {
  const location = useLocation();
  const [bookingData, setBookingData] = useState();
  const [roomsToDisplay, setRoomsToDisplay] = useState(Object.keys(RoomInfo));
  const [isRoomsLoading, setIsRoomsLoading] = useState(true);
  const [showBookingView, setShowBookingView] = useState(false);

  useEffect(() => {
    if (location.state) {
      setBookingData(location.state.data);
    } else {
      setIsRoomsLoading(false);
    }
  }, [location]);

  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"accommodation"} img={hero3} />
      <SearchSection
        data={bookingData}
        hasDates={true}
        setBookingData={setBookingData}
        roomsToDisplay={roomsToDisplay}
        setRoomsToDisplay={setRoomsToDisplay}
        setIsRoomsLoading={setIsRoomsLoading}
        setShowBookingView={setShowBookingView}
      />
      {!isRoomsLoading ? (
        showBookingView ? (
          <RoomBookingView
            isRoomsLoading={isRoomsLoading}
            roomsToDisplay={roomsToDisplay}
            data={bookingData}
          />
        ) : (
          <RoomSection
            isRoomsLoading={isRoomsLoading}
            roomsToDisplay={roomsToDisplay}
            data={bookingData}
          />
        )
      ) : (
        <LoadingSpinner />
      )}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default RoomPage;
