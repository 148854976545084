import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const SearchSection = ({ room, bookingData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const finalBookingData = {
    ...bookingData,
    price: room.price,
    roomType: room.roomKey,
  };
  const submitHandler = (e) => {
    e.preventDefault();
    history.push({
      pathname: "payment",
      state: {
        bookingData: finalBookingData,
      },
    });
  };

  const yearCap = t("year-cap").replace("(", "").replace(")", "");
  const capacityString = `${t("maximum")}: ${room.adults} ${t("adults")} ${
    room.children ? `+ ${room.children} ${t("children")} ${yearCap}` : ""
  }`;

  return (
    <div className="wpo-select-section-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="wpo-select-wrap">
              <div className="wpo-select-area">
                <form onSubmit={submitHandler} className="clearfix">
                  <div className="select-sub">
                    <span>{t("room-size")}</span>
                    <h2>
                      {room.size}
                      {t("meters")}
                    </h2>
                  </div>
                  <div className="select-sub">
                    <span>{t("capacity")}</span>
                    <h2>{capacityString}</h2>
                  </div>

                  <div className="select-sub">
                    {room.price ? (
                      <h5>
                        {t("price")} <span>{room.price}</span>
                        {t("bgn")} / {t("night")}
                      </h5>
                    ) : null}
                    {bookingData && (
                      <button className="theme-btn-s2" type="submit">
                        {t("book")}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
