import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function GalleryFilter({ tabs, activeTab, handleClick }) {
  const { t } = useTranslation();
  return (
    <div className="gallery-filters">
      <Nav tabs>
        {tabs.map((tab, index) => {
          const tabId = String(index + 1);
          return (
            <NavItem key={`nav-item-${index}`}>
              <NavLink
                className={classnames({ active: activeTab === tabId })}
                onClick={() => {
                  handleClick(tabId);
                }}
              >
                {t(tab)}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </div>
  );
}

const GalleryContent = ({ items, renderItem, activeTab, selectImage }) => {
  return (
    <div className="gallery-container">
      <TabContent activeTab={activeTab}>
        {Object.keys(items).map((item, index) => {
          return (
            <TabPane tabId={String(index + 1)}>
              {items[item].map((itm, index1) => {
                return (
                  <div
                    onClick={() => selectImage(itm)}
                    className="gallery-item"
                    key={"gallery-item" + index1}
                  >
                    {renderItem(itm)}
                  </div>
                );
              })}
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
};

const FullScreenGallery = ({ image, onClose }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}
      className={`full-screen-gallery`}
    >
      <div className="content">
        <img
          onClick={(e) => {
            e.stopPropagation();
          }}
          src={image}
          alt=""
        />
        <FontAwesomeIcon
          className="full-screen-gallery-close"
          color="white"
          icon={faXmark}
        />
      </div>
    </div>
  );
};

export { GalleryContent, FullScreenGallery };
