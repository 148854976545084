import doublePark from "../images/rooms/double-park.jpg";
import doublePark21 from "../images/rooms/double-park-2-1.jpg";
import doublePark22 from "../images/rooms/double-park-2-2.jpg";
import triplePark from "../images/rooms/triple-park.jpg";

// Main pictures
import doubleSea from "../images/rooms/double-sea.jpg";
import doubleSea21 from "../images/rooms/double-sea-2-1.jpg";
import doubleSea22 from "../images/rooms/double-sea-2-2.jpg";
import tripleSea from "../images/rooms/triple-sea.jpg";
import apartment from "../images/rooms/apartment.jpg";

// Secondary from Gallery
import apartment1 from "../images/gallery/rooms/room-1.jpg";
import apartment2 from "../images/gallery/rooms/room-2.jpg";
import apartment3 from "../images/gallery/rooms/room-4.jpg";

import tripleSea1 from "../images/gallery/rooms/room-15.jpg";
import tripleSea2 from "../images/gallery/rooms/room-13.jpg";
import tripleSea3 from "../images/gallery/rooms/room-11.jpg";

import triplePark1 from "../images/gallery/rooms/room-14.jpg";
import triplePark2 from "../images/gallery/rooms/room-10.jpg";
import triplePark3 from "../images/gallery/rooms/room-7.jpg";

import doubleSea221 from "../images/gallery/rooms/room-9.jpg";
import doubleSea222 from "../images/gallery/rooms/room-12.jpg";
import doubleSea223 from "../images/gallery/rooms/room-11.jpg";

import doublePark221 from "../images/gallery/rooms/room-5.jpg";
import doublePark222 from "../images/gallery/rooms/room-6.jpg";
import doublePark223 from "../images/gallery/rooms/room-7.jpg";

import doubleSea211 from "../images/gallery/rooms/room-9.jpg";
import doubleSea212 from "../images/gallery/rooms/room-12.jpg";
import doubleSea213 from "../images/gallery/rooms/room-11.jpg";

import doublePark211 from "../images/gallery/rooms/room-6.jpg";
import doublePark212 from "../images/gallery/rooms/room-8.jpg";
import doublePark213 from "../images/gallery/rooms/room-10.jpg";

import doubleSea1 from "../images/gallery/rooms/room-9.jpg";
import doubleSea2 from "../images/gallery/rooms/room-12.jpg";
import doubleSea3 from "../images/gallery/rooms/room-11.jpg";

import doublePark1 from "../images/gallery/rooms/room-6.jpg";
import doublePark2 from "../images/gallery/rooms/room-8.jpg";
import doublePark3 from "../images/gallery/rooms/room-10.jpg";

export default {
  "double-park": {
    name: "Двойна стая изглед парк",
    view: "park",
    image: doublePark,
    size: 40,
    adults: 2,
    children: 0,
    roomKey: "double-park",
    image1: doublePark1,
    image2: doublePark2,
    image3: doublePark3,
  },
  "double-sea": {
    name: "Двойна стая изглед море",
    view: "sea",
    image: doubleSea,
    size: "18",
    adults: 2,
    children: 0,
    roomKey: "double-sea",
    image1: doubleSea1,
    image2: doubleSea2,
    image3: doubleSea3,
  },
  "double-sea-one-kid": {
    name: "Двойна стая изглед море 2 + 1",
    view: "sea",
    image: doubleSea21,
    size: "18",
    adults: 2,
    children: 1,
    roomKey: "double-sea-one-kid",
    image1: doubleSea211,
    image2: doubleSea212,
    image3: doubleSea213,
  },
  "double-park-one-kid": {
    name: "Двойна стая изглед парк 2 + 1",
    view: "park",
    image: doublePark21,
    size: "18",
    adults: 2,
    children: 1,
    roomKey: "double-park-one-kid",

    image1: doublePark211,
    image2: doublePark212,
    image3: doublePark213,
  },

  "double-park-two-kids": {
    name: "Двойна стая изглед парк 2+2",
    view: "park",
    image: doublePark22,
    size: "18",
    adults: 2,
    children: 2,
    roomKey: "double-park-two-kids",
    image1: doublePark221,
    image2: doublePark222,
    image3: doublePark223,
  },
  "double-sea-two-kids": {
    name: "Двойна стая изглед море 2+2",
    view: "sea",
    image: doubleSea22,
    size: "18",
    adults: 2,
    children: 2,
    roomKey: "double-sea-two-kids",
    image1: doubleSea221,
    image2: doubleSea222,
    image3: doubleSea223,
  },
  "triple-park": {
    name: "Тройна стая изглед парк",
    view: "park",
    size: "18",
    image: triplePark,
    bed: "1 двойно",
    adults: 3,
    children: 0,
    roomKey: "triple-park",
    image1: triplePark1,
    image2: triplePark2,
    image3: triplePark3,
  },
  "triple-sea": {
    name: "Тройна стая изглед море",
    view: "sea",
    size: 18,
    image: tripleSea,
    adults: 3,
    children: 0,
    roomKey: "triple-sea",
    image1: tripleSea1,
    image2: tripleSea2,
    image3: tripleSea3,
  },
  apartment: {
    name: "Апартамент с 2 спални",
    view: "sea",
    image: apartment,
    size: 40,
    adults: 6,
    children: 0,
    roomKey: "apartment",
    image1: apartment1,
    image2: apartment2,
    image3: apartment3,
  },
};

const boardPrices = {
  "bed-and-breakfast": 0,
  "half-board": 30,
  "full-board": 55,
  "all-light": 75,
};

export { boardPrices };
