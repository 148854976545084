import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import RoomSingleSection from "../../components/RoomSingleSection";
import { useHistory, useLocation } from "react-router-dom";

import cover from "../../images/gallery/cover.jpg";

const RoomSinglePage = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({
    room: "",
    bookingData: "",
  });

  useEffect(() => {
    let stateRoom, bookingData;
    if (location.state) {
      stateRoom = location.state.room;
      bookingData = location.state.bookingData;
      let dataCopy = { ...data };
      dataCopy["room"] = stateRoom;
      dataCopy["bookingData"] = bookingData;
      setData(dataCopy);
    } else {
      window.scrollTo(10, 0);
      history.push("/room");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Fragment>
      <Navbar />
      <PageTitle
        pageTitle={data.room && data.room.name}
        img={cover}
      />
      {data.room && (
        <RoomSingleSection room={data.room} bookingData={data.bookingData} />
      )}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default RoomSinglePage;
