import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const Counter = (props) => {
  const { t } = useTranslation();
  const counter = [
    {
      Ficon: "fi flaticon-key",
      heading: t("amazing-rooms"),
      data: "127",
    },
    {
      Ficon: "fi flaticon-staff",
      heading: t("staff"),
      data: "80",
    },
    // {
    //   Ficon: "fi flaticon-bowling",
    //   heading: "Delicious Food",
    //   data: "180",
    // },
    // {
    //   Ficon: "fi flaticon-pool",
    //   heading: "Swimming Pools",
    //   data: "8",
    // },
  ];

  return (
    <div className="wpo-counter-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="wpo-counter-grids">
              {counter.map((count, cnt) => (
                <div className="grid" key={cnt}>
                  <div className="wpo-counter-icon">
                    <i className={count.Ficon}></i>
                  </div>
                  <div>
                    <h2>{count.data} +</h2>
                  </div>
                  <p>{count.heading}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
