import React, { Fragment } from "react";
import "./style.css";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footer";
import PageTitle from "../../components/pagetitle";
import LineSeparator from "../../components/LineSeparator/LineSeparator";
import LoadingSpinner from "../../components/LoadingSpinner";
import cover from "../../images/offers/cover.jpg";
import { useTranslation } from "react-i18next";
import useApprovedOffers from "../../utils/useApprovedOffers";

export default function OffersPage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const { offers, loading } = useApprovedOffers(language);
  const text = "text" + language;
  const title = "title" + language;

  return (
    <div className="offers-page">
      <Navbar pageTitle="Оферти" />
      <PageTitle pageTitle="offers" img={cover} />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="offers-container">
          {offers && offers.length ? (
            offers.map((offer, index) => {
              return offer[text] !== "" && offer[title] !== "" ? (
                <Fragment key={index}>
                  <div className="single-offer">
                    <img src={offer.image} alt="" />
                    <div className="offer-content">
                      <div className="offer-title">{offer[title]}</div>
                      <div dangerouslySetInnerHTML={{ __html: offer[text] }} />
                    </div>
                  </div>
                  {index !== offers.length - 1 && <LineSeparator />}
                </Fragment>
              ) : (
                <Fragment key={index} />
              );
            })
          ) : (
            <div
              className="no-available-offers"
              style={{ alignSelf: "center" }}
            >
              {t("no-offers")}
            </div>
          )}
        </div>
      )}

      <Footer />
    </div>
  );
}

// const Offers = ({ offers, lang }) => {
//   const title = "title" + lang;
//   const text = "text" + lang;
//   return (
//     <div className="offers-container">
//       {offers.map((offer, index) => {
//         return (
//           <Fragment key={index}>
//             <div className="single-offer">
//               <img src={offer.image} alt="" />
//               <div className="offer-content">
//                 <h3 className="offer-title">{offer[title]}</h3>
//                 <div dangerouslySetInnerHTML={{ __html: offer[text] }} />
//                 {/* <div>
//                 <p>Цена</p>
//               </div> */}
//               </div>
//             </div>
//             {index !== offers.length - 1 && <LineSeparator />}
//           </Fragment>
//         );
//       })}
//     </div>
//   );
// };
