import React from "react";
import classNames from "classnames";

import "./style.css";
export default function Button({ classes, text, onClick, type, ...rest }) {
  return (
    <button
      type={type ? type : ""}
      className={`main-button ${classNames(classes)}`}
      onClick={onClick}
      {...rest}
    >
      {text}
    </button>
  );
}
