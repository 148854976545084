import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { toast } from "react-toastify";
import offersService from "../../services/offersService";

import Button from "../../components/Button/Button";
import loadingSpinner from "../../images/loading-spinner.gif";
import Footer from "../../components/footer";

import "./style.css";
import Editor from "../../components/TextEditor";
import { useLocation } from "react-router-dom";
import config from "../../config/config.json";
const allLanguages = config.languages;

const initialData = {
  titleBG: "",
  textBG: "",
  titleEN: "",
  textEN: "",
  titleRO: "",
  textRO: "",
  titleDE: "",
  textDE: "",
  titleRU: "",
  textRU: "",
  image: "",
  status: "approved",
};

export default function CreateOfferPage() {
  const location = useLocation();
  const [data, setData] = useState({ ...initialData });
  const [shouldClear, setShouldClear] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      setData({ ...location.state.data });
    }
  }, [location]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    handleChange("image", base64);
  };

  const handleChange = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData({ ...dataCopy });
  };

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    let hasAtleastOneLanguageTexts;
    allLanguages.forEach((lang) => {
      const text = "text" + lang;
      const title = "title" + lang;
      if (data[text] !== "" && data[title]) {
        hasAtleastOneLanguageTexts = true;
      }
    });

    if (!hasAtleastOneLanguageTexts) {
      toast.error("Въведете данни поне за един език");
      setIsSubmitting(false);
      return;
    }

    try {
      if (location.state) {
        await offersService.editOffer(data);
        toast.success("Успешна промяна!");
      } else {
        await offersService.createOffer(data);
        toast.success("Успешно добавено!");
      }
      setIsSubmitting(false);
      setShouldClear(true);
      setData(initialData);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        if (ex.response.data.includes("image")) {
          toast.error("Моля, избери снимка");
        } else {
          toast.error(ex.response.data);
        }
      }
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="create-offer-container">
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          {location.state ? "Промени оферта" : "Добави оферта"}
        </h3>
        <form className="create-offer-form" onSubmit={(e) => handleSubmit(e)}>
          <EditorWithTitle
            titleValue={data.titleBG}
            editorValue={data.textBG}
            onChange={handleChange}
            titleName="titleBG"
            editorName="textBG"
            heading="Български"
            shouldClear={shouldClear}
            setShouldClear={setShouldClear}
          />
          <EditorWithTitle
            titleValue={data.titleEN}
            editorValue={data.textEN}
            onChange={handleChange}
            titleName="titleEN"
            editorName="textEN"
            heading="Английски"
            shouldClear={shouldClear}
            setShouldClear={setShouldClear}
          />
          <EditorWithTitle
            titleValue={data.titleRO}
            editorValue={data.textRO}
            onChange={handleChange}
            titleName="titleRO"
            editorName="textRO"
            heading="Румънски"
            shouldClear={shouldClear}
            setShouldClear={setShouldClear}
          />
          <EditorWithTitle
            titleValue={data.titleDE}
            editorValue={data.textDE}
            onChange={handleChange}
            titleName="titleDE"
            editorName="textDE"
            heading="Немски"
            shouldClear={shouldClear}
            setShouldClear={setShouldClear}
          />
          <EditorWithTitle
            titleValue={data.titleRU}
            editorValue={data.textRU}
            onChange={handleChange}
            titleName="titleRU"
            editorName="textRU"
            heading="Руски"
            shouldClear={shouldClear}
            setShouldClear={setShouldClear}
          />
          {data.image && (
            <img src={data.image} alt="" className="offer-image" />
          )}
          <label
            htmlFor="create-offer-form__image"
            className="create-offer__image-label"
          >
            Избери снимка
          </label>
          <input
            id="create-offer-form__image"
            className="create-ofer-form__image-input"
            type="file"
            onChange={(e) => handleFileRead(e)}
          />
          {isSubmitting ? (
            <img src={loadingSpinner} alt="" className="loading-spinner" />
          ) : (
            <Button
              text={location.state ? "Промени" : "Създай"}
              type="submit"
            />
          )}
        </form>
      </div>
      <Footer />
    </div>
  );
}

const EditorWithTitle = ({
  titleValue,
  editorValue,
  onChange,
  titleName,
  editorName,
  heading,
  shouldClear,
  setShouldClear,
}) => {
  return (
    <React.Fragment>
      <h5>{heading}</h5>
      <input
        id={`create-offer-form__title-${titleName}`}
        className="create-offer-form__title"
        value={titleValue}
        onChange={(e) => onChange(titleName, e.currentTarget.value)}
        placeholder="Заглавие"
      />
      <Editor
        content={editorValue}
        onChange={(e) => onChange(editorName, e)}
        shouldClear={shouldClear}
        setShouldClear={setShouldClear}
      />
    </React.Fragment>
  );
};
