import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import Contactpage from "../../components/Contactpage";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Navbar />
      <h2 style={{ textAlign: "center", marginTop: "20px" }}>{t("contact")}</h2>
      <Contactpage />
      <Footer footerClass={"wpo-ne-footer-2"} />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
