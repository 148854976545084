import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

export default function NavList({ isHomepage = false }) {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const activeTab = window.location.href.split("/")[3];
  const adminTab = window.location.href.split("/")[4];

  const { t, i18n } = useTranslation();
  const isAdmin = activeTab === "admin";

  const tabs = [
    "home",
    "about",
    "room",
    "events",
    "offers",
    "services",
    "restaurants",
    "reviews",
    "gallery",
    "contact",
    "tr",
  ];

  const adminTabs = [
    "rooms",
    "reservations",
    "offers",
    "reviews",
    "vouchers",
    "subscribers",
  ];
  const adminRedirects = {
    offers: "all-offers",
  };
  const adminDropdowns = {
    offers: ["all-offers", "create-offer"],
  };

  const navTabs = isAdmin ? adminTabs : tabs;
  const dropdowns = isAdmin ? adminDropdowns : {};

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("hotel-sofia-lang", lang);
  };

  return (
    <div className="col-xl-10 col-lg-10 d-lg-block d-none navlist">
      <nav>
        <ul>
          {navTabs.map((tab, index) => {
            const dropdown = dropdowns[tab];
            const redirect = isAdmin
              ? adminRedirects[tab]
                ? adminRedirects[tab]
                : tab
              : tab;
            if (tab === "tr")
              return (
                <li className="translation-list-item" key={index}>
                  <FontAwesomeIcon
                    color={isHomepage ? "white" : "#283a5e"}
                    icon={faGlobe}
                  />
                  <ul className="translation-dropdown">
                    <li onClick={() => changeLanguage("bg")}>
                      <p>BG</p>
                    </li>
                    <li onClick={() => changeLanguage("en")}>
                      <p>EN</p>
                    </li>
                    <li onClick={() => changeLanguage("ro")}>
                      <p>RO</p>
                    </li>
                    <li onClick={() => changeLanguage("de")}>
                      <p>DE</p>
                    </li>
                    <li onClick={() => changeLanguage("ru")}>
                      <p>RU</p>
                    </li>
                  </ul>
                </li>
              );
            else
              return (
                <li
                  className={`${
                    (redirect === activeTab || redirect === adminTab) &&
                    "nav-active"
                  }`}
                  key={index}
                >
                  <Link onClick={ClickHandler} to={`/${redirect}`} title="">
                    {t(tab)}
                  </Link>
                  {dropdown && (
                    <ul>
                      {dropdown.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link
                              onClick={ClickHandler}
                              to={`/${item}`}
                              title=""
                            >
                              {t(item)}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
          })}
        </ul>
      </nav>
    </div>
  );
}
