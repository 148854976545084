import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import About from "../../components/about";
import Features from "../../components/Features";
import Counter from "../../components/Counter";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import hotelHero1 from "../../images/hotel-hero-1.jpg";
import Team from "../../components/Team/Team";

const AboutPage = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle
        pageTitle={"welcome"}
        pagesub={"feel-relax"}
        img={hotelHero1}
      />
      <About />
      <Features showImportant />
      <Team />
      <Counter />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
