import React from "react";
import about2 from "../../images/about-2.jpg";
import about from "../../images/about.jpg";
import "./style.css";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="wpo-about-area section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={about} alt="" />
            </div>
          </div>
          <div className="col-lg-5 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-section-title">
                <h3>{t("about-title-1")}</h3>
              </div>
              <p>{t("about-text-4")}</p>
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-lg-5 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <p>{t("about-text-5")}</p>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={about2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
