import React from "react";
import "./style.css";

export default function UnderlinedTitle({ title }) {
  return (
    <div className="underlined-title-container">
      <h2 className="underlined-title">{title}</h2>
    </div>
  );
}
