import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer";

import Navbar from "../../components/Navbar";
import AddReview from "../../components/RoomSingleSection/Rcomponents/descontent/addReview";
import Testimonial from "../../components/Testimonial";
import "./style.css";

export default function ReviewPage() {
  const { t } = useTranslation();
  return (
    <div className="review-page">
      <Navbar />
      <h2 className="contact-heading">{t("reviews")}</h2>
      <Testimonial />
      <AddReview />
      <Footer />
    </div>
  );
}
