import { utcToZonedTime } from "date-fns-tz";

/**
 * Format date from the database YYYY-MM-DD to DD.MM.YYYY
 *
 * @param {string} date the date received from the DB in format YYYY-MM-DD
 * @returns {string} the formatted date DD.MM.YYYY
 */
function getDateView(date) {
  const datePattern = /(\d{4})-(\d{2})-(\d{2})/;

  return date.replace(datePattern, "$3.$2.$1");
}

/**
 * Format date from the database YYYY-MM-DD to DD.MM.YYYY
 *
 * @param {string} date the date received from the DB in format YYYY-MM-DD
 * @returns {string} the formatted date DD.MM.YYYY
 */
function getDateViewNoYear(date) {
  const datePattern = /(\d{4})-(\d{2})-(\d{2})/;

  return date.replace(datePattern, "$3.$2");
}

/**
 * Format date from the database YYYY-MM-DD to DD.MM.YYYY
 *
 * @param {string} date the date received from the DB in format YYYY-MM-DD
 * @returns {string} the formatted date DD.MM.YYYY
 */
function getDateViewFromPeriod(date) {
  const datePattern = /(\d{4})-(\d{2})-(\d{2})/;
  const dates = date.split(" - ");
  const dateOne = dates[0].replace(datePattern, "$3.$2");
  const dateTwo = dates[1].replace(datePattern, "$3.$2");
  return `${dateOne} - ${dateTwo}`;
}
/**
 * Format date from the js Date object Thu Mar 25 2021 00:00:00 GMT+0000 (Greenwich Mean Time) to YYYY-MM-DD
 *
 * @param {Date} date the formatted js Date object
 * @returns {string} the formatted DB date YYYY-MM-DD
 */
function getDateDB(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function getTomorrowDate() {
  return new Date(new Date().setDate(new Date().getDate() + 1));
}

function getDateDbFromPeriod(date) {
  const dateSplit = date.split(" - ");
  const dateOne = getDateViewFromPeriod(dateSplit[0]);
  const dateTwo = getDateViewFromPeriod(dateSplit[1]);
  return `${dateOne} - ${dateTwo}`;
}

/**
 *
 * @param {String} startDate in format YYYY-MM-DD
 * @param {String} endDate in format YYYY-MM-DD
 * @returns {Array} dates in format YYYY-MM-DD
 */
function getDatesInRange(startDate, endDate) {
  // const date = new Date(
  //   new Date(new Date(startDate).setHours(7, 0, 0)).getTime()
  // );
  let date = toBulgarianTimezone(startDate);
  const endDateFormatted = toBulgarianTimezone(endDate);
  const dates = [];

  while (date <= endDateFormatted) {
    const dateToPush = getDateDB(date);
    dates.push(dateToPush);
    date = new Date(new Date(date.toDateString()).setHours(0, 0, 0));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

/**
 *
 * @param {String} date in a valid string format ex. YYYY-MM-DD
 * @returns
 */
const getDateZeroHours = (date) => {
  return toBulgarianTimezone(date);
};

const sevenDaysBeforeBooking = (date) => {
  const dateFormatted = new Date(date);

  return new Date(dateFormatted.setDate(dateFormatted.getDate() - 7));
};

const toBulgarianTimezone = (date) => {
  const bulgarianTimezoneDate = utcToZonedTime(new Date(date), "Europe/Sofia");
  return bulgarianTimezoneDate;
};

export {
  getDateView,
  getDateViewFromPeriod,
  getDateDB,
  getTomorrowDate,
  getDatesInRange,
  getDateDbFromPeriod,
  getDateViewNoYear,
  getDateZeroHours,
  sevenDaysBeforeBooking,
  toBulgarianTimezone,
};
