import React from "react";
import UnderlinedTitle from "../UndrelinedTitle";
import wedding from "../../images/events/wedding.jpg";
import conference from "../../images/events/conference.jpg";
import { useTranslation } from "react-i18next";
import "./style.css";

export default function Event({ title }) {
  const { t } = useTranslation();
  return (
    <div className="event-wrapper">
      <UnderlinedTitle title={t(title)} />
      {title !== "seminars" ? (
        <div
          className={`event-container ${title !== "seminars" && "row-reverse"}`}
        >
          <img src={wedding} alt="" className="event-picture" />
          <WeddingContent t={t} />
        </div>
      ) : (
        <div>
          <p className="event-title conference">{t("seminars-text-1")}</p>
          <ConferenceContent t={t} />
        </div>
      )}
    </div>
  );
}

const ConferenceContent = ({ t }) => {
  const listItems = [
    "sunlight",
    "blinds",
    "projector-wall",
    "projector",
    "wifi-access",
    "dif-alignment",
    "custom-ac",
    "free-access",
    "close-to-restaurant",
    "separated",
    "paid-parking",

    // "business-meetings",
    // "conferences",
    // "seminars",
    // "tutoring",
    // "events-lowercase",
    // "presentation",
    // "others",
  ];
  return (
    <div className="event-container row-reverse">
      <div>
        <ul className="conference-content-list">
          {listItems.map((item, index) => (
            <li className="event-list-item" key={index}>
              <span>{t(item)}</span>
            </li>
          ))}
        </ul>
      </div>
      <img
        style={{ marginRight: "25px" }}
        src={conference}
        alt=""
        className="event-picture"
      />
    </div>
  );
};

const WeddingContent = ({ t }) => {
  return (
    <div>
      <p className="wedding-title">{t("wedding-text")}</p>
    </div>
  );
};
