import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      rooms: "Rooms",
      sea: "Sea",
      park: "Park",

      // Navigation tabs
      home: "Home",
      about: "About us",
      room: "Rooms",
      events: "Events",
      offers: "Offers",
      reviews: "Reviews",
      gallery: "Gallery",
      contact: "Contact us",
      services: "Services",
      restaurants: "Restaurants",

      // Admin panel
      "create-offer": "Create offer",
      "all-offers": "All offers",
      "all-reviews": "All reviews",
      "approve-reviews": "Одобри ревюта",
      vouchers: "Vouchers",

      // Room names
      "double-park": "Double room park view",
      "double-sea": "Double room sea view",
      "double-park-two-kids": "Double room park view 2+2",
      "double-sea-two-kids": "Double room sea view 2+2",
      "double-park-one-kid": "Double room park view 2+1",
      "double-sea-one-kid": "Double room sea view 2+1",
      "triple-park": "Triple room park view",
      "triple-sea": "Triple room sea view",
      apartment: "Apartment with 2 bedrooms",

      // Services names
      "own-beach": "Private beach",
      "outdoor-pool": "Outdoor swimming pool",
      parking: "Parking",
      playground: "Play area for children",
      "pool-table": "Snooker",
      "lobby-bar": "Lobby bar",
      "bike-rent": "Bicycle hire service",
      animation: "Animation",
      fitness: "Outdoor gym corner",
      vault: "Safe",
      "currency-exchange": "Currency Exchange Office",
      "a-la-carte": "A-la carte restaurant",
      conference: "Conference hall",
      laundry: "Dry cleaning",
      "football-field": "Mini football or volleyball sand pitch",

      subscribers: "Subscribers",
      "check-availability": "Check availability",
      "contact-us": "Contact us",
      "useful-links": "Useful links",
      "our-events": "Our events",
      newsletter: "Newsletter",
      subscribe: "Subscribe",
      "email-address": "Email address",
      others: "Others",
      name: "First Name",
      "last-name": "Last Name",
      subject: "Subject",
      message: "Message",
      "send-message": "Send a message",
      telephone: "Phone number",
      email: "Email",
      address: "Address",
      "add-review": "Add review",
      "your-review": "Your review",
      send: "Send",
      privacy: "Privacy Policy",
      terms: "Booking Terms",
      weddings: "Weddings",
      seminars: "Seminars",
      welcome: "SOFIA HOTEL",
      accommodation: "Accommodation",
      "check-in": "Check - in",
      "check-out": "Check -out",
      children: "Children",
      guests: "Guests",
      book: "Book",
      beds: "Beds",
      "room-size": "Room size",
      capacity: "Capacity",
      bathroom: "Bathroom",
      price: "Price",
      night: "Night",
      all: "All",
      hotel: "Hotel",
      pool: "Pool",
      dining: "Dining",
      "hotel-sofia": "Hotel Sofia",
      "dream-vacation": "Dream vacation!",
      "more-about-us": "More about us",
      "hotel-address": "9007 Golden Sands, Varna",
      "all-rights-reserved": "2022 Hotel Sofia. All rights reserved",
      "see-more": "See more",
      "our-clients": "What the guests share about",
      "are-important": "their stay in the Sofia Hotel.",
      "clients-say": "Our guests' opinion is important to us!",

      // Restaurant page
      "a-la-carte-full": "Mediterraneo Restaurant à la carte",
      "pool-bar": "Pool bar",
      "beach-bar": "Beach bar",
      dresscode: "Dresscode",
      location: "Location",
      international: "International",
      "no-reservation": "Reservation not required",
      casual: "Casual",
      "bars-restaurants": "Restaurants and Bars",
      payment: "Payment",
      pay: "Pay",
      "card-details": "Card details",
      details: "Details",
      "payment-success": "Successfull payment",
      staff: "Dedicated staff",
      "amazing-rooms": "Amazing rooms",
      "beach-description": `Hotel "Sofia" has
                          with its own beach and beach bar, where guests can
                          enjoy just a few meters from the hotel.`,
      "double-park-description":
        "Enjoy your stay relaxing in the cozy atmosphere of the double room overlooking the park. The rooms have exclusive amenities. Each room is equipped with double or twin beds, a bathroom with a shower, minibar, safe and balcony overlooking the hotel park.",
      "double-sea-description":
        "Enjoy your stay relaxing in the cozy atmosphere of the double room with a sea view. The rooms have exclusive amenities. Each room is equipped with double or twin beds, a bathroom with a shower, minibar, safe and balcony with panoramic sea views.",
      "double-park-two-kids-description":
        "Enjoy your stay relaxing in the cozy atmosphere of the double room with a park view. The ideal option when traveling with friends or family. The rooms have exclusive amenities. Each room is equipped with double or twin beds, a sofa bed, a bathroom with a shower, minibar, safe and balcony with view to the hotel park.",
      "double-sea-two-kids-description":
        "Enjoy your stay relaxing in the cozy atmosphere of the double room with a sea view. Ideal when traveling with friends or family. The rooms have exclusive amenities. Each room is equipped with double or twin beds, a sofa bed, a bathroom with a shower, minibar, safe and balcony with panoramic sea view.",
      "double-park-one-kid-description":
        "Enjoy your stay relaxing in the cozy atmosphere of the double room with a park view. The ideal option when traveling with friends or family. The rooms have exclusive amenities. Each room is equipped with double or twin beds, a sofa bed, a bathroom with a shower, minibar, safe and balcony with view to the hotel park.",
      "double-sea-one-kid-description":
        "Enjoy your stay relaxing in the cozy atmosphere of the double room with a sea view. Ideal when traveling with friends or family. The rooms have exclusive amenities. Each room is equipped with double or twin beds, a sofa bed, a bathroom with a shower, minibar, safe and balcony with panoramic sea view.",

      "triple-park-description":
        "The triple rooms with a park view are ideal for traveling with friends or family. They have exclusive amenities designed to satisfy even the most demanding guests.The modern décor and atmosphere provide a feeling of well‒being during your stay and allow you to feel at home. The room has a double or twin beds and a sofa bed.",
      "triple-sea-description":
        "Triple rooms with sea views are ideal when traveling with friends or family. They have exclusive amenities designed to satisfy even the most demanding guests. The modern décor and atmosphere provide a feeling of well‒being during your stay, and allow you to feel at home. The room has a double or twin beds and a sofa bed.",
      "apartment-description":
        "The Sofia Suite has two separate rooms. The ideal option when traveling with friends or family. The apartments have a bathroom with a shower, double or twin beds, a sofa bed, minibar and safe. You can enjoy a panoramic sea view from the balcony while drinking your morning coffee!",
      "additional-text": "Additional text",
      pricing: "Pricing",
      meters: "m²",
      souvenir: "Souvenir shop",
      "see-map": "See map",
      "about-subtitle-1":
        'Summer, sea, sand, sun, smile, Sofia everything beautiful starts with a "S"',
      "home-text-1":
        'Hotel "Sofia" is one of the most cozy, holiday, completely renovated for the 2015 season hotel on the Bulgarian Black Sea coast. Located 100 meters from the beaches of the resort "Golden Sands"',
      "about-text-1":
        'Hotel "Sofia" is one of the luxury, holiday, completely renovated for the 2015 season hotel on the Bulgarian Black Sea coast. It is located 100 meters from the beaches of the Golden Sands resort. Here you can enjoy comfort, tranquility and excellent conditions for an unforgettable vacation. Modern hotel complex, offering a unique combination of modern architecture, cozy atmosphere, remarkable sea or park panorama and attractive prices.',
      "about-text-2":
        "We combine impressive amenities, professional service and exquisite cuisine to provide the stylish atmosphere and unforgettable memories you have dreamed of remembering your vacation.",
      "wedding-text-1":
        "Sofia Restaurant has 250 seats in the lounge and a summer terrace with 100 seats and welcomes its guests in a modern, pleasant and relaxing atmosphere. It offers style and sophistication for organizing your events - weddings, cocktails, birthdays, anniversaries.",
      "wedding-text-2":
        "Your wedding celebration will leave unforgettable memories in you and your loved ones. We combine excellent service and exquisite cuisine to provide the stylish and luxurious atmosphere in which you dreamed of spending your wedding day.",
      "wedding-text-3":
        "We will help you organize a unique wedding celebration - with the possibility of forming a menu with different prices, holiday music, wedding cake, champagne, beautiful flower arrangements and decorations.",
      "seminars-text-1":
        "Sofia Conference Hall provides all the conditions for conducting:",
      "business-meetings": "business meetings",
      conferences: "conferences",
      tutoring: "tutoring",
      "events-lowercase": "events",
      presentation: "presentation",
      "other-events": "others",
      "booking-summary": "Booking summary",
      bgn: "BGN",
      view: "View",
      description: "Description",
      showerhead: "Shower (cubicle)",
      baby: "Crib (at request)",
      tv: "TV",
      conditioner: "Air conditioner",
      dryer: "Hair dryer",
      phone: "Telephone",
      minibar: "Minibar",
      adults: "Adults",
      team: "Meet the team",
      wrong: "Something went wrong",
      "send-review-success": "Successfully sent you review!",
      "send-email-success": "Successfully sent your message!",
      "send-email-fail": "There was a problem sending your message!",
      "subscribe-success": "Successfully subscribed to our newsletter!",
      "mediterano-description":
        "You wish to immerse yourself in a Mediterranean atmosphere with typical dishes ‒ visit the Mediterraneo à la carte restaurant! Choose between a themed interior or dinner in the restaurant’s garden",
      "lobby-bar-description":
        "Enjoy a cup of aromatic coffee or indulge in shared moments, cocktail in hand. The lobby bar of the Sofia Hotel is located in the spacious lobby and is a wonderful place to unwind in a casual atmosphere.",
      "pool-bar-description":
        "The pool bar is located near the Sofia Hotel outdoor pool. Here you can enjoy refreshing cocktails, aromatic coffee and revitalising drinks.",
      "beach-bar-description": `The bar is at the sea and the sea is at the bar ‒ the beach bar is right next to the beach of the Sofia Hotel, where you can refresh yourself with an assortment of drinks while enjoying the time spent in the sun.`,
      "no-offers": "There are no offers at the moment.",
      "invalid-period": "Please select valid period.",
      reservations: "Reservations",
      cancellation: "Cancel booking",
      "ref-number": "Reference number",
      "ref-not-found": "Invalid reference number",
      "booking-cancelled": "Booking cancelled",
      apply: "Apply",
      "not-found": "There is no voucher found.",
      voucher: "Voucher",
      "voucher-expired": "The voucher is expired",
      "wrong-room-type": "The voucher can't be used for this type of room",
      "voucher-success": "Voucher successfully applied",
      "no-room-found": "No available rooms for the chosen period",
      "enjoy-dream-vacation": "Enjoy the dream vacation.",
      size: "Size",
      "capacity-single": "Capacity",
      "total-price": "Total price",
      continue: "Continue",
      "dates-were-booked": "The dates are already booked",
      "no-rooms": "No available rooms for the desired period.",
      "required-field": "The field is required!",

      "room-number": "№ rooms",
      "home-title-1": "Escape the ordinary",
      "team-text-1": "Relax, enjoy and let us take care of you!",
      "team-text-2": "Our team can't wait to meet you!",
      "team-text-3": "We are creative",
      "team-text-4": "We are responsible",
      "team-text-5": "We love what we do",
      "team-text-6": "We are always ready to assist our guests!",
      "feel-relax": "Feel. Relax. Enjoy",
      "about-title-1": "A sip of coffee with a view of the sea and the sun!",
      "about-text-4":
        "Perfectly located next to the beach, the hotel has 127 rooms and 9 suites, most of which overlook the beautiful sea of ​​the Golden Sands Resort. The combination of a cozy atmosphere, varied cuisine and the opportunity to escape from everyday life, contributes to the perfect choice for your summer vacation, romantic moment or important event.",
      "about-text-5":
        "Our guests have at their disposal a beach with a beach bar adjacent to the hotel, controlled access parking lot, à la carte Mediterranean style restaurant, a completely renovated main restaurant and an outdoor swimming pool.",
      "wedding-text": `A wedding fairy tale in a beautiful place ‒ the Sofia Hotel, Golden Sands!
      You trust us and we make your dreams come true!
      Your wedding celebration will leave unforgettable memories in you and your loved ones. We will help you organise a unique wedding celebration ‒ with the opportunity to choose the menu, holiday music, beautiful flower arrangements and decorations. We combine excellent service and a fine cuisine to provide the stylish and luxurious atmosphere in which you dreamed of spending your wedding day`,
      sunlight: "Natural sunlight",
      blinds: "Blackout curtains",
      "projector-wall": "Projector whiteboard",
      projector: "Projector and flipchart",
      "wifi-access": "Wi-Fi",
      "dif-alignment": "Different seating arrangements possible",
      "custom-ac": "Individually controlled air conditioning",
      "free-access": "Free access for handicapped guests",
      "close-to-restaurant":
        "Located in close proximity to the Sofia main restaurant",
      separated: "Separate from the stream of hotel guests",
      "paid-parking": "Unguarded paid parking zone",
      "mid-international": "Mediterranean/International",
      "with-reservation": "Booking required",
      "official-dresscode": "Formal",
      "casual-dresscode": "Casual",
      "main-restaurant-description": `The main restaurant Sofia is open for breakfast, lunch and dinner buffet.
      The restaurant has a renovated interior, which contributes to the cozy and refined atmosphere. Take your culinary passport to the tastes of international cuisine. For our guests' enjoyment, we offer a trip to near and far countries through the themed evenings organised by us with a choice of typical dishes.`,
      "main-restaurant": "Main Restaurant Sofia",
      "working-hours": "Working hours",
      "year-cap": "(to 11.99y.)",
      maximum: "Maximum",
      "general-terms": "Terms and Conditions",
      "parking-description":
        "the hotel has a paid parking area with 80 parking spaces in close proximity to the hotel reception hall. No preliminary booking of parking spaces. The parking spaces are offered until fully occupied.In the event the parking area is fully occupied, the hotel does not undertake to find a free parking space but we can assist with information about other parking spaces in close proximity.",
      "already-paid":
        "The reservation is already paid. Please, contact us for more information.",
      "voucher-min-nights":
        "The voucher can be used for bookings of a minimum of {{minNights}} nights",
    },
  },
  bg: {
    translation: {
      rooms: "Стаи",
      sea: "Море",
      park: "Парк",

      home: "Начало",
      about: "За нас",
      room: "Стаи",
      events: "Събития",
      offers: "Оферти",
      reviews: "Ревюта",
      gallery: "Галерия",
      contact: "Контакти",
      services: "Удобства",
      restaurants: "Ресторанти",

      "create-offer": "Създай оферта",
      "all-offers": "Всички оферти",
      "all-reviews": "Всички ревюта",
      "approve-reviews": "Одобри ревюта",
      vouchers: "Ваучери",

      // Room names
      "double-park": "Двойна стая изглед парк",
      "double-sea": "Двойна стая изглед море",
      "double-park-two-kids": "Двойна стая изглед парк 2+2",
      "double-sea-two-kids": "Двойна стая изглед море 2+2",
      "double-park-one-kid": "Двойна стая изглед парк 2+1",
      "double-sea-one-kid": "Двойна стая изглед море 2+1",
      "triple-park": "Тройна стая изглед парк",
      "triple-sea": "Тройна стая изглед море",
      apartment: "Апартамент с 2 спални",

      // Services names
      "own-beach": "Собствен плаж",
      "outdoor-pool": "Външен басейн",
      parking: "Паркинг",
      playground: "Детски кът",
      "pool-table": "Билярд",
      "lobby-bar": "Лоби бар",
      "bike-rent": "Наем на велосипед",
      animation: "Анимация",
      fitness: "Открит фитнес кът",
      vault: "Сейф",
      "currency-exchange": "Обменно бюро",
      "a-la-carte": "А-ла карт ресторант",
      conference: "Конферентна зала",
      laundry: "Химическо чистене",
      "football-field": "Пясъчно игрище за мини-футбол или волейбол",

      subscribers: "Абонати",
      "check-availability": "Провери наличност",
      "contact-us": "Свържи се с нас",
      "useful-links": "Полезни линкове",
      "our-events": "Нашите събития",
      newsletter: "Бюлетина",
      subscribe: "Абонирай се",
      "email-address": "Имейл адрес",
      others: "Други",
      name: "Име",
      "last-name": "Фамилия",
      subject: "Тема",
      message: "Съобщение",
      "send-message": "Изпрати съобщение",
      telephone: "Телефон",
      email: "Имейл",
      address: "Адрес",
      "add-review": "Добави ревю",
      "your-review": "Твоето ревю",
      send: "Изпрати",
      privacy: "Политика за поверителност",
      terms: "Условия за ползване",
      weddings: "Сватби",
      seminars: "Семинари",
      welcome: "Добре Дошли в Хотел София !",
      accommodation: "Настаняване",
      "check-in": "Настаняване",
      "check-out": "Напускане",
      children: "Деца",
      guests: "Гости",
      book: "Запази",
      beds: "Легла",
      "room-size": "Размер на стаята",
      capacity: "Капацитет на стаята",
      bathroom: "Баня",
      price: "Цена",
      night: "Вечер",
      all: "Всички",
      hotel: "Хотел",
      pool: "Басейн",
      dining: "Заведения",
      "hotel-sofia": "Хотел София",
      "dream-vacation": "Мечтаната почивка!",
      "more-about-us": "Повече за нас",
      "hotel-address": "9007 Златни пясъци, Варна",
      "all-rights-reserved": "2022 Хотел София. Всички права запазени",
      "see-more": "Виж повече",
      "our-clients": "Мнението на нашите гости",
      "are-important": "е важно за нас!",
      "clients-say": "Какво споделят гостите за престоя си в хотел “София”",

      // Restaurants page
      "a-la-carte-full": "А-ла карт ресторант “Медитеранео”",
      "pool-bar": "Бар-басейн",
      "beach-bar": "Плажен бар",
      dresscode: "Дрескод",
      location: "Локация",
      international: "Интернационална",
      "no-reservation": "Без резервация",
      casual: "Небрежен",
      "bars-restaurants": "Ресторанти и Барове",

      payment: "Плащане",
      pay: "Плати",
      "card-details": "Детайли на картата",
      details: "Детайли",
      "payment-success": "Успешно плащане",
      staff: "Отдаден персонал",
      "amazing-rooms": "Страхотни стаи",
      "beach-description": `Хотел “София” разполага
      със собствен плаж и плажен бар, на които гостите могат да
      се насладят само на няколко метра от хотела.
      `,
      "double-park-description":
        "Насладете се на престоя си, като се отпуснете в уютната атмосфера на двойната стая с изглед парк. Стаите разполагат с ексклузивни удобства. Всяка стая е оборудвана с двойно или две отделни легла, баня с душ, минибар, сейф и балкон с гледка към парковата част на хотела.",
      "double-sea-description":
        "Насладете се на престоя си, като се отпуснете в уютната атмосфера на двойната стая с изглед море. Стаите разполагат с ексклузивни удобства. Всяка стая е оборудвана с двойно или две отделни легла, баня с душ, минибар, сейф и балкон с панорамна морска гледка.",
      "double-park-two-kids-description":
        "Насладете се на престоя си, като се отпуснете в уютната атмосфера на двойната стая с изглед парк. Идеалният вариант, когато пътувате с приятели или семейство.Стаите разполагат с ексклузивни удобства. Всяка стая е оборудвана с двойно или две отделни легла, разтегателен диван, баня с душ, минибар, сейф и балкон с гледка към парковата част на хотела.",
      "double-sea-two-kids-description":
        "Насладете се на престоя си, като се отпуснете в уютната атмосфера на двойната стая с изглед море. Идеалният вариант, когато пътувате с приятели или семейство. Стаите разполагат с ексклузивни удобства. Всяка стая е оборудвана с двойно или две отделни легла, разтегателен диван, баня с душ, минибар, сейф и балкон с панорамна морска гледка.",
      "double-park-one-kid-description":
        "Насладете се на престоя си, като се отпуснете в уютната атмосфера на двойната стая с изглед парк. Идеалният вариант, когато пътувате с приятели или семейство.Стаите разполагат с ексклузивни удобства. Всяка стая е оборудвана с двойно или две отделни легла, разтегателен диван, баня с душ, минибар, сейф и балкон с гледка към парковата част на хотела.",
      "double-sea-one-kid-description":
        "Насладете се на престоя си, като се отпуснете в уютната атмосфера на двойната стая с изглед море. Идеалният вариант, когато пътувате с приятели или семейство. Стаите разполагат с ексклузивни удобства. Всяка стая е оборудвана с двойно или две отделни легла, разтегателен диван, баня с душ, минибар, сейф и балкон с панорамна морска гледка.",
      "triple-park-description":
        "Тройните стаи с изглед към парка са идеалният вариант, когато пътувате с приятели или семейство. Те разполагат с ексклузивни удобства, предназначени да задоволят и най-претенциозните гости. Модерният декор и атмосфера осигуряват усещане за благополучие по време на престоя и Ви позволяват да се почувствате като у дома си. Стаята разполага с двойно или две отделни легла и разтегателен диван.",
      "triple-sea-description":
        "Тройните стаи с изглед към морето са идеалният вариант, когато пътувате с приятели или семейство. Те разполагат с ексклузивни удобства, предназначени да задоволят и най-претенциозните гости. Модерният декор и атмосфера осигуряват усещане за благополучие по време на престоя, и Ви позволяват да се почувствате като у дома си. Стаята разполага с двойно или две отделни легла и разтегателен диван.",
      "apartment-description":
        'Апартамент "София" разполага с две отделни стаи. Идеалният вариант, когато пътувате с приятели или семейство. Апартаментите разполагат с баня с душ, двойно или две отделни легла, разтегателен диван, мини-бар и сейф. Може да се насладите на панорамна морска гледка от балкона, докато пиете сутрешната глътка кафе!',
      "additional-text": "Допълнителен текст.",
      pricing: "Цени",
      meters: "м²",
      souvenir: "Сувенирен магазин",
      "see-map": "Виж карта",
      "home-text-1":
        "Хотел „София” е един от най-уютните, ваканционни, изцяло реновиран за сезон 2015 г. хотел на българското Черноморие. Намира се на 100 м от плажовете на курортен комплекс “Златни пясъци”",
      "about-subtitle-1":
        'Summer, sea, sand, sun, smile, Sofia everything beautiful starts with a "S"',

      "about-text-1":
        "Хотел „София” е един от луксозните, ваканционни, изцяло реновиран за сезон 2015 г. хотел на българското Черноморие. Намира се на 100 м от плажовете на курортен комплекс ‘Златни пясъци’. Тук можете да се насладите на удобство, спокойствие и отлични условия за незабравима ваканция. Модерен хотелски комплекс, предлагащ уникално съчетание на съвременна архитектура, уютна атмосфера, забележителна морска или паркова панорама и атрактивни цени.",
      "about-text-2":
        "Ние съчетаваме впечатляващи удобства, професионално обслужване и изискана кухня, за да осигурим стилната атмосфера и незабравимите спомени, с които сте мечтали да запомните Вашата ваканция",
      "wedding-text-1":
        "Ресторант „София“ разполага с 250 места в салон и лятна тераса с 100 места и посреща своите гости в модерна, приятна и отпускаща обстановка.Предлага стил и изтънченост за организиране на Вашите мероприятия – сватби, коктейли, рождени дни, юбилеи.",
      "wedding-text-2":
        "Вашето сватбено тържество ще остави незабравими спомени у вас и вашите близки. Ние съчетаваме отлично обслужване и изискана кухня, за да осигурим стилната и луксозна атмосфера, в която сте мечтали да премине вашия сватбен ден.",
      "wedding-text-3":
        "Ще ви помогнем да организирате неповторимо сватбено тържество – с възможност за формиране на меню с различни цени ,празнична музика, сватбена торта, шампанско, красиви цветни аранжировки и декорации.",
      "seminars-text-1":
        "Конферентна зала „София“ осигурява всички условия за провеждане на вашите бизнес срещи, семинари, обучения, събития презентация и др. Разполага с 70 места, модерен дизайн и уютна обстановка.",
      "business-meetings": "бизнес срещи",
      conferences: "конференции",
      tutoring: "обучения",
      "events-lowercase": "събития",
      presentation: "презентации",
      "other-events": "други",
      "booking-summary": "Обобщение",
      bgn: "лв.",
      view: "Гледка",
      description: "Описание",
      showerhead: "Душ (кабина)",
      baby: "Бебешка кошара (по заявка)",
      minibar: "Мини-бар",
      tv: "Телевизор",
      conditioner: "Климатик",
      dryer: "Сешоар",
      phone: "Телефон",
      adults: "Възрастни",
      team: "Нашият екип",
      wrong: "Нещо се обърка",
      "send-review-success": "Успешно изпратихте вашият отзив!",
      "send-email-success": "Успешно изпратихте вашето съобщение!",
      "send-email-fail": "Възникна проблем с изпращането на вашето съобщение!",
      "subscribe-success": "Успешно се абонирахте за нашите новини!",
      "mediterano-description":
        "Искате да се потопите в Средиземноморска атмосфера с типични ястия - посетете а - ла карт ресторант “Медитеранео”!. Изберете между тематична вътрешна част или вечеря в градината на ресторанта",
      "pool-bar-description":
        "Бар-басейнът е разположен в близост до откритият басейн на хотел София. Тук може да се насладите на освежаващи деня коктейли, ароматно кафе и тонизиращи напитки.",
      "lobby-bar-description":
        "Отпийте с наслада чаша ароматно кафе или се отдайте на споделени моменти с коктейл в ръка. Лоби-бара на хотел София е разположен в просторното фоайе и е прекрасно място да се отпуснете в непретенциозна обстановка. ",
      "beach-bar-description":
        "“Бара е до морето и морето е до бара” - в непосредствена близост до плажа на хотел” София” се намира и плажният бар, където може да се освежите с асортимент от напитки, докато се наслаждавате на времето прекарано под слънчевите лъчи.",
      "no-offers": "Няма намерени оферти.",
      "invalid-period": "Моля изберете валиден период.",
      reservations: "Резервации",
      cancellation: "Отказване на резервация",
      "ref-number": "Референтен номер",
      "ref-not-found": "Невалиден референтен номер",
      "booking-cancelled": "Резервацията е отказана",
      apply: "Приложи",
      "not-found": "Няма намерен ваучер.",
      "voucher-expired": "Ваучерът е изтекъл",
      "wrong-room-type": "Ваучерът не може да бъде използван за този тип стая",
      voucher: "Ваучер",
      "voucher-success": "Ваучерът беше успешно приложен.",
      "no-room-found": "Няма свободни стаи за избрания период.",
      "enjoy-dream-vacation": "Насладете се на мечтаната почивка!",
      size: "Площ",
      "capacity-single": "Капацитет",
      "total-price": "Обща цена",
      continue: "Продължи",
      "dates-were-booked": "Избраните дати са заети.",
      "no-rooms": "Няма свободни стаи за избрания период.",
      "required-field": "Полето е задължително!",

      "room-number": "Брой стаи",
      "team-text-1":
        "Отпуснете се, забавлявайте се и ни позволете да се погрижим за вас!",
      "team-text-2": "Нашият екип с нетърпение очаква да Ви посрещне!",
      "team-text-3": "Ние сме креативни",
      "team-text-4": "Ние сме отговорни",
      "team-text-5": "Ние обичаме това което правим",
      "team-text-6": "Винаги сме готови да съдействаме на нашите гости!",
      "feel-relax": "Feel. Relax. Enjoy",
      "about-title-1": "Глътка кафе с изглед море и слънчеви лъчи!",
      "about-text-4":
        "С отличното си местоположение в непосредствена близост до плажа, хотелът разполага със 127 стаи и 9 апартамента, от които по-голямата част са с гледка към красивото море на курортен комплекс Златни Пясъци. Съчетанието от уютна атмосфера, разнообразна кухня и възможността да избягате от ежедневието, допринася  за перфектния избор на Вашата лятна почивка, романтичен момент или важно събитие",
      "about-text-5":
        "На разположение на нашите гости са плаж и плажен бар прилежащи към хотела, паркинг с контролиран достъп, а - ла карт ресторант в средиземноморски стил, изцяло реновиран основен ресторант и открит външен басейн.",
      "wedding-text": `Сватбена приказка на красиво място - хотел "София", Златни пясъци! 
      Вие ни се доверявате, а ние сбъдваме мечтите Ви!
      Вашето сватбено тържество ще остави незабравими спомени у вас и вашите близки. Ще ви помогнем да организирате неповторимо сватбено тържество – с възможност за формиране на меню, празнична музика, красиви цветни аранжировки и  декорации. Ние съчетаваме отлично обслужване и изискана кухня, за да осигурим стилната и луксозна атмосфера, в която сте мечтали да премине вашия сватбен ден.`,
      sunlight: "Естествена слънчева светлина",
      blinds: "Завеси за пълно затъмнение",
      "projector-wall": "Бяла стена за прожектиране",
      projector: "Проектор и флипчарт",
      "wifi-access": "Wi-Fi достъп",
      "dif-alignment": "Възможност за различен тип подредба на залата",
      "custom-ac": "Климатизация с  индивидуална възможност за контролиране",
      "free-access": "Свободен достъп на хора с увреждания",
      "close-to-restaurant": "В близост до основен ресторант „София“",
      separated: "Отделеност от потока хотелски гости",
      "paid-parking": "Неохраняем паркинг срещу заплащане",
      "mid-international": "Средиземноморска/Интернационална",
      "with-reservation": "С предварителна резервация",
      "official-dresscode": "Официален",
      "casual-dresscode": "Небрежен",
      "main-restaurant-description": `Основен ресторант София е отворен за закуска, обяд и вечеря на бюфет.
      Ресторантът е с реновиран интериор, който допринася за уютната и изискана обстановка. Вземете Вашият кулинарен паспорт към вкусовете на интернационалната кухня. За разнообразие на нашите гости предлагаме пътешествие до близки и далечни страни, чрез организираните от нас тематични вечери с подбрани типични ястия.`,
      "main-restaurant": "Основен ресторант София",
      "working-hours": "Работно време",
      "home-title-1": "Избягайте от обичайното!",
      "year-cap": "(до 11.99г.)",
      maximum: "Максимум",
      "general-terms": "Общи условия",
      "parking-description":
        "Хотелът разполага с платен паркинг с 80 места в непосредствена близост до рецепция. Резервация на паркомясто не е възможна. Паркоместата са до изчерпване на наличните свободни. В случай на пълна заетост, хотелът не се ангажира с осигуряване на свободно място за паркиране, но бихме съдействали с информация за други в близост.",
      "already-paid": "Резервацията вече е платена.",
      "cannot-cancel":
        "Резервацията не може да бъде анулирана. Моля, свържете се с нас за съдействие.",
      "type-reference-number": "Моля, въведете референтен номер.",
      "already-cancelled": "Резервацията вече е анулирана.",

      "voucher-min-nights":
        "Ваучерът може да се използва при резервации от минимум {{minNights}} нощувки ",
    },
  },
  de: {
    translation: {
      rooms: "Räume",
      sea: "Meer",
      park: "Park",
      room: "Zimmer",

      home: "Startseite",
      about: "Über uns",
      accommodation: "Unterkunft",
      dining: "Lokale",
      events: "Events",
      offers: "Angebote",
      reviews: "Bewertungen",
      gallery: "Bildergalerie",
      contact: "Kontakt",
      services: "Ausstattung",
      restaurants: "Restaurants",

      "create-offer": "Създай оферта",
      "all-offers": "Всички оферти",
      "all-reviews": "Всички ревюта",
      "approve-reviews": "Одобри ревюта",
      vouchers: "Ваучери",

      // Room names
      "double-park": "Doppelzimmer mit Parkblick",
      "double-sea": "Doppelzimmer mit Meerblick",
      "double-park-two-kids": "Doppelzimmer Parkblick 2+2",
      "double-sea-two-kids": "Doppelzimmer mit Meerblick 2+2",
      "double-park-one-kid": "Doppelzimmer Parkblick 2+1",
      "double-sea-one-kid": "Doppelzimmer mit Meerblick 2+1",
      "triple-park": "- Dreibettzimmer mit Blick auf den Park",
      "triple-sea": "Dreibettzimmer mit Meerblick",
      apartment: "Suite mit zwei Schlafzimmern und Meerblick",

      // Services names
      "own-beach": "Eigener Strand",
      "outdoor-pool": "Schwimmbad im Freien",
      parking: "Parken",
      playground: "Spielplatz für Kinder",
      "pool-table": "Billard",
      "lobby-bar": "Lobby Bar",
      "bike-rent": "Fahrradverleih",
      animation: "Animation",
      fitness: "Fitnessbereich im Freien",
      vault: "Tresor",
      "currency-exchange": "Währungsumtausch",
      "a-la-carte": "A la carte Restaurant ",
      conference: "Konferenzraum",
      laundry: "Reinigung",
      "football-field": "Sandspielplatz für Mini‒Fußball oder Volleyball",

      subscribers: "Абонати",

      "check-availability": "Die Verfügbarkeit prüfen",
      "contact-us": "Kontakt",
      "useful-links": "Nützliche Links",
      "email-address": "E-Mail addresse",
      name: "Name",
      "last-name": "Nachname",
      subject: "Thema",
      message: "Botschaft",
      "send-message": "Nachricht senden",
      email: "E-Mail",
      address: "Addresse",
      fax: "Fax",
      "add-review": "Bewertung hinzufügen",
      send: "Senden",
      "general-terms": "Bedingungen und Konditionen",
      privacy: "Datenschutz",
      terms: "Nutzungsbedingungen",
      seminars: "Seminare",
      "check-in": "Einchecken",
      "check-out": "Auschecken",
      children: "Kinder",
      child: "Kind",
      guests: "Gäste",
      book: "Buchen",
      beds: "Betten",
      "room-size": "Zimmergröße",
      capacity: "Raumkapazität",
      price: "Preis",
      night: "Nacht",
      all: "Alle",
      hotel: "Hotel",
      pool: "Pool",
      bar: "Bar",
      beach: "Strand",
      "room-details": "Zimmerdetails",
      showerhead: "Duschkabine",
      baby: "Babybett (auf Anfrage)",
      minibar: "Minibar",
      telephone: "Telefon",

      "hotel-sofia": "Hotel Sofia",
      "more-about-us": "Mehr über uns",
      "hotel-address": "9007 Goldstrand, Varna",
      "all-rights-reserved": "2022 Hotel Sofia. Alle Rechte vorbehalten",
      "see-more": "Mehr sehen",
      "our-clients": "Die Meinung unserer Gäste",
      "are-important": "ist uns wichtig!",
      "clients-say": "Was Gäste über ihren Aufenthalt im Sofia Hotel sagen",

      // Restaurants page
      "a-la-carte-full": "Mediterraneo A la Carte Restaurant",
      "pool-bar": "Pool Bar",
      "beach-bar": "Strandbar",
      dresscode: "Kleiderordnung",
      location: "Ort",
      international: "International",
      "no-reservation": "Keine Reservierung",
      "bars-restaurants": "Restaurants und Bars",
      staff: "Engagiertes Personal",
      "amazing-rooms": "Tolle Zimmer",
      "beach-description": `Eigener Strand und Strandbar - Das Hotel Sofia verfügt über einen eigenen Strand und eine Strandbar, die Sie nur wenige Meter vom Hotel entfernt genießen können.`,
      "double-park-description":
        "Genießen Sie Ihren Aufenthalt und entspannen Sie sich in der gemütlichen Atmosphäre des Doppelzimmers mit Parkblick. Die Zimmer sind mit exklusiven Annehmlichkeiten ausgestattet. Jedes Zimmer ist mit einem Doppel- oder zwei Einzelbetten, einem Bad mit Dusche, einer Minibar, einem Tresor und einem Balkon mit Blick auf den Park des Hotels ausgestattet.",
      "double-sea-description":
        "Genießen Sie Ihren Aufenthalt und entspannen Sie sich in der gemütlichen Atmosphäre des Doppelzimmers mit Meerblick. Die Zimmer sind mit exklusiven Annehmlichkeiten ausgestattet. Jedes Zimmer ist mit einem Doppel- oder zwei Einzelbetten, einem Bad mit Dusche, einer Minibar, einem Tresor und einem Balkon mit Panoramablick auf das Meer ausgestattet.",
      "double-park-two-kids-description":
        "Genießen Sie Ihren Aufenthalt und entspannen Sie sich in der gemütlichen Atmosphäre des Doppelzimmers mit Parkblick. Die ideale Wahl, wenn Sie mit Freunden oder der Familie reisen, denn die Zimmer sind mit exklusiven Annehmlichkeiten ausgestattet. Jedes Zimmer ist mit einem Doppel- oder zwei Einzelbetten, einem Schlafsofa, einem Bad mit Dusche, einer Minibar, einem Tresor und einem Balkon mit Blick auf den Park des Hotels ausgestattet.",
      "double-sea-two-kids-description":
        "Genießen Sie Ihren Aufenthalt und entspannen Sie sich in der gemütlichen Atmosphäre des Doppelzimmers mit Meerblick. Die ideale Option, wenn Sie mit Freunden oder der Familie reisen. Die Zimmer sind mit exklusiven Annehmlichkeiten ausgestattet. Jedes Zimmer ist mit einem Doppel- oder zwei Einzelbetten, einem Schlafsofa, einem Badezimmer mit Dusche, einer Minibar, einem Tresor und einem Balkon mit Panoramablick aufs Meer ausgestattet.",
      "double-park-one-kid-description":
        "Genießen Sie Ihren Aufenthalt und entspannen Sie sich in der gemütlichen Atmosphäre des Doppelzimmers mit Parkblick. Die ideale Wahl, wenn Sie mit Freunden oder der Familie reisen, denn die Zimmer sind mit exklusiven Annehmlichkeiten ausgestattet. Jedes Zimmer ist mit einem Doppel- oder zwei Einzelbetten, einem Schlafsofa, einem Bad mit Dusche, einer Minibar, einem Tresor und einem Balkon mit Blick auf den Park des Hotels ausgestattet.",
      "double-sea-one-kid-description":
        "Genießen Sie Ihren Aufenthalt und entspannen Sie sich in der gemütlichen Atmosphäre des Doppelzimmers mit Meerblick. Die ideale Option, wenn Sie mit Freunden oder der Familie reisen. Die Zimmer sind mit exklusiven Annehmlichkeiten ausgestattet. Jedes Zimmer ist mit einem Doppel- oder zwei Einzelbetten, einem Schlafsofa, einem Badezimmer mit Dusche, einer Minibar, einem Tresor und einem Balkon mit Panoramablick aufs Meer ausgestattet.",

      "triple-park-description":
        "Dreibettzimmer mit Blick auf den Park sind die perfekte Wahl, wenn Sie mit Freunden oder der Familie reisen. Sie bieten exklusive Annehmlichkeiten, die selbst die anspruchsvollsten Gäste zufriedenstellen. Die moderne Einrichtung und das Ambiente sorgen für ein Gefühl des Wohlbefindens während Ihres Aufenthalts und lassen Sie sich wie zu Hause fühlen. Das Zimmer hat ein Doppel- oder zwei Einzelbetten und ein Schlafsofa. ",
      "triple-sea-description":
        "Dreibettzimmer mit Meerblick sind die ideale Wahl, wenn Sie mit Freunden oder der Familie reisen. Sie bieten exklusive Annehmlichkeiten, die selbst die anspruchsvollsten Gäste zufriedenstellen. Die moderne Einrichtung und das Ambiente sorgen für ein Gefühl des Wohlbefindens während Ihres Aufenthalts und ermöglichen es Ihnen, sich wie zu Hause zu fühlen. Das Zimmer hat ein Doppel- oder zwei Einzelbetten und ein Schlafsofa.",
      "apartment-description":
        'Suite "Sofia" hat zwei separate Zimmer. Die ideale Option, wenn Sie mit Freunden oder der Familie reisen. Die Apartments verfügen über ein Badezimmer mit Dusche, Doppel- oder Einzelbetten, ein Schlafsofa, eine Minibar und einen Safe. Vom Balkon aus können Sie den Panoramablick auf das Meer genießen, während Sie Ihren Morgenkaffee trinken!',
      "see-map": "Karte ansehen",
      "seminars-text-1":
        "Die Sofia Conference Hall bietet alle Voraussetzungen für Ihre Geschäftstreffen, Seminare, Schulungen, Präsentationsveranstaltungen usw. Es hat 70 Sitzplätze, modernes Design und eine gemütliche Atmosphäre.",
      // "business-meetings": "бизнес срещи",
      // conferences: "конференции",
      // tutoring: "обучения",
      // "events-lowercase": "събития",
      // presentation: "презентации",
      // "other-events": "други",

      "mediterano-description":
        "Wenn Sie in eine mediterrane Atmosphäre mit typischen Gerichten eintauchen möchten, besuchen Sie das a la carte Restaurant Mediterraneo! Wählen Sie zwischen einem thematisch gestalteten Innenbereich oder einem Essen im Garten des Restaurants",
      "pool-bar-description":
        "Die Poolbar befindet sich in der Nähe des Außenpools des Sofia Hotels. Hier können Sie tageserfrischende Cocktails, aromatischen Kaffee und erfrischende Getränke genießen.",
      "lobby-bar-description":
        "Nippen Sie an einer köstlichen Tasse aromatischen Kaffees oder gönnen Sie sich gemeinsame Momente mit einem Cocktail in der Hand. Die Lobby Bar des Sofia Hotels befindet sich in der geräumigen Lobby und ist ein wunderbarer Ort zum Entspannen in unprätentiöser Umgebung. ",
      "beach-bar-description": `"Die Bar liegt am Meer und das Meer liegt an der Bar" - direkt am Strand des Hotels Sofia befindet sich die Strandbar, wo Sie sich mit einer Auswahl an Getränken erfrischen können, während Sie Ihre Zeit unter der Sonne genießen.`,

      payment: "Zahlung",
      pay: "Zahlen",
      "card-details": "Kartendetails",
      details: "Einzelheiten",
      "payment-success": "Erfolgreiche Zahlung",
      pricing: "Preise",
      meters: "m²",
      "booking-summary": "Zusammenfassung",
      bgn: "BGN",
      view: "Aussicht",
      tv: "Fernseher",
      conditioner: "Klimaanlage",
      dryer: "Haartrockner",
      phone: "Telefon",
      adults: "Erwachsene",
      wrong: "Etwas ist schief gelaufen",
      "send-review-success": "Sie haben Ihre Bewertung erfolgreich gesendet!",
      "send-email-success": "Sie haben Ihre Nachricht erfolgreich gesendet!",
      "send-email-fail": "Es gab ein Problem beim Senden Ihrer Nachricht!",
      "no-offers": "Keine Angebote gefunden.",
      "invalid-period": "Bitte wählen Sie einen gültigen Zeitraum.",
      reservations: "Reservierungen",
      cancellation: "Reservierung stornieren",
      "ref-number": "Referenznummer",
      "ref-not-found": "Ungültige Referenznummer",
      "booking-cancelled": "Buchung storniert",
      apply: "Anwenden",
      "not-found": "Kein Gutschein gefunden.",
      "voucher-expired": "Der Gutschein ist abgelaufen.",
      "wrong-room-type":
        "Gutschein kann für diesen Zimmertyp nicht verwendet werden.",
      voucher: "Gutschein",
      "voucher-success": "Der Gutschein wurde erfolgreich angewendet.",
      "no-room-found": "Keine Zimmer für den gewählten Zeitraum verfügbar",
      "enjoy-dream-vacation": "Genießen Sie Ihren Traumurlaub!",
      size: "Bereich",
      "capacity-single": "Bereich",
      "total-price": "Gesamtkosten",
      continue: "Mach weiter",
      "dates-were-booked": "Die ausgewählten Termine sind vergeben.",
      "no-rooms": "Keine Zimmer für den gewählten Zeitraum verfügbar.",

      "required-field": "Das Feld ist obligatorisch!",
      "room-number": "Anzahl der Räume",
      "year-cap": "(bis 11.99J.)",
      team: "Treffen Sie das Team",
      "team-text-1":
        "Entspannen Sie sich, haben Sie Spaß und lassen Sie uns für Sie sorgen!",
      "team-text-2": "Unser Team freut sich darauf, Sie willkommen zu heißen!",
      "team-text-3": "Wir sind kreativ",
      "team-text-4": "Wir sind verantwortlich",
      "team-text-5": "Wir lieben, was wir tun",
      "team-text-6": "Wir sind immer bereit, unseren Gästen zu helfen!",
      "feel-relax": "Fühlen. Entspannen. Genießen.",
      "about-subtitle-1":
        'Summer, sea, sand, sun, smile, Sofia everything beautiful starts with a "S"',

      "about-title-1":
        "Ein Schluck Kaffee mit Blick auf das Meer und die Sonne!",
      "about-text-4":
        "Mit seiner hervorragenden Lage direkt am Strand verfügt das Hotel über 127 Zimmer und 9 Suiten, von denen die meisten einen Blick auf das schöne Meer des Golden Sands Resort bieten. Die Kombination aus gemütlicher Atmosphäre, abwechslungsreicher Küche und der Möglichkeit, dem Alltag zu entfliehen, ist die perfekte Wahl für Ihren Sommerurlaub, einen romantischen Moment oder ein wichtiges Ereignis.",
      "about-text-5":
        "Unseren Gästen stehen ein Strand und eine Strandbar direkt neben dem Hotel, ein Parkplatz mit kontrolliertem Zugang, ein A-la-carte-Restaurant im mediterranen Stil, ein komplett renoviertes Hauptrestaurant und ein Außenpool zur Verfügung.",
      "wedding-text": `Hochzeitsmärchen an einem wunderschönen Ort - Hotel Sofia, Golden Sands! 
          Sie vertrauen uns und wir lassen Ihre Träume wahr werden!
          Ihre Hochzeitsfeier wird bei Ihnen und Ihren Lieben unvergessliche Erinnerungen hinterlassen. Wir helfen Ihnen, eine einzigartige Hochzeitsfeier zu organisieren - mit der Möglichkeit, ein Menü zu gestalten, festlicher Musik, schönen Blumenarrangements und Dekorationen. Wir kombinieren exzellenten Service und feine Küche, um Ihnen die stilvolle und luxuriöse Atmosphäre zu bieten, die Sie sich für Ihren Hochzeitstag erträumt haben.`,
      sunlight: "Natürliches Sonnenlicht",
      blinds: "Verdunkelungsvorhänge",
      "projector-wall": "Weiße Projektionswand",
      projector: "Projektor und Flipchart",
      "wifi-access": "Wi-Fi-Zugang",
      "dif-alignment": "Möglichkeit für verschiedene Arten der Raumaufteilung",
      "custom-ac": "Klimaanlage mit individueller Steuerbarkeit",
      "free-access": "Freier Zugang für Menschen mit Behinderungen",
      "close-to-restaurant": 'In der Nähe des Hauptrestaurants "Sofia"',
      separated: "Abtrennung vom Strom der Hotelgäste",
      "paid-parking": "Ungesicherte Parkplätze gegen Gebühr",
      "mid-international": "Mediterran/International",
      "with-reservation": "Nach Reservierung",
      "official-dresscode": "Formell",
      "casual-dresscode": "Leger",
      "main-restaurant-description": `Hauptrestaurant Sofia ist zum Frühstück, Mittag- und Abendessen in Buffetform geöffnet.
      Das Restaurant hat ein renoviertes Interieur, das zu der gemütlichen und raffinierten Atmosphäre beiträgt. Nehmen Sie Ihren kulinarischen Reisepass mit zu den Geschmäckern der internationalen Küche. Zur Abwechslung bieten wir unseren Gästen eine Reise in nahe und ferne Länder an, und zwar durch unsere Themenabende mit ausgewählten typischen Gerichten.`,
      "main-restaurant": "Hauptrestaurant Sofia",
      "working-hours": "Öffnungszeit",
      "home-title-1": "Entfliehen Sie dem Gewöhnlichen",
      "room-single": "Zimmer",
      "already-paid":
        "Die Buchung ist bezahlt. Kontaktieren Sie uns gerne, wenn Sie Unterstützung benötigen.",
      "cannot-cancel":
        "The reservation cannot be cancelled. Please, contact us for more information.",
      "type-reference-number": "Geben Sie bitte eine Buchungsnummer ein.",
      "already-cancelled": "Diese Buchung wurde bereits storniert.",
      subscribe: "Hier abonnieren",
      newsletter: "Abonnenten",
      "invalid-email": "Ungültige Mailadresse",
      "subscribe-success":
        "Sie haben sich erfolgreich für unseren Newsletter abonniert.",
      reserve: "Buchen",
      "our-events": "Unsere Veranstaltungen",
      maximum: "Maximal",
      "parking-description":
        "Das Hotel verfügt über einen gebührenpflichtigen Parkplatz mit 80 Stellplätzen neben der Rezeption. Die Reservierung eines Parkplatzes ist nicht möglich. Die Parkplätze unterliegen der Verfügbarkeit.Im Falle einer Vollbelegung verpflichtet sich das Hotel nicht, einen kostenlosen Parkplatz zur Verfügung zu stellen, aber wir helfen Ihnen gerne mit Informationen über andere Parkplätze in der Nähe.",

      "voucher-min-nights":
        "Der Gutschein kann für Buchungen von mindestens {{minNights}} Nächten verwendet werden",
    },
  },
  ru: {
    translation: {
      rooms: "Номера",
      sea: "Море",
      park: "Парк",

      home: "Начало",
      about: "О нас",
      room: "Номер",
      events: "События",
      offers: "Предложения",
      reviews: "Отзывы",
      gallery: "Галерея",
      contact: "Контакты",
      services: "Удобства",
      restaurants: "Рестораны",

      "create-offer": "Създай оферта",
      "all-offers": "Всички оферти",
      "all-reviews": "Всички ревюта",
      "approve-reviews": "Одобри ревюта",
      vouchers: "Ваучери",

      // Room names
      "double-park": "Двухместный номер с видом на парк",
      "double-sea": "Двухместный номер с видом на море",
      "double-park-two-kids": "Двухместный номер с видом на парк 2+2",
      "double-sea-two-kids": "Двухместный номер с видом на море 2+2",
      "double-park-one-kid": "Двухместный номер с видом на парк 2+1",
      "double-sea-one-kid": "Двухместный номер с видом на море 2+1",
      "triple-park": "Трехместный номер с видом на парк",
      "triple-sea": "Трехместный номер с видом на море",
      apartment: "Трехкомнатный апартамент с видом на море",

      // Services names
      "own-beach": "Собственный пляж и пляжный бар",
      "outdoor-pool": "Открытый бассейн",
      parking: "Парковка",
      "parking-description":
        " В гостинице есть платная парковка на 80 мест рядом со стойкой регистрации. Бронирование парковочного места невозможно. Парковочные места исчерпаны. При полной занятости гостиница не обязуется предоставлять бесплатное парковочное место, но мы можем помочь с информацией для других поблизости.",
      playground: "Детский уголок",
      "pool-table": "Бильярд",
      "lobby-bar": "Лобби-бар",
      "bike-rent": "Прокат велосипедов",
      animation: "Анимация",
      fitness: "Открытая фитнес-зона",
      vault: "Сейф",
      "currency-exchange": "Обмен валюты",
      "a-la-carte": "Ресторан с обслуживанием по меню",
      conference: "Конференц–зал",
      laundry: "Химчистка",
      "football-field": "Песчаное поле для мини-футбола или волейбола",

      subscribers: "Абонати",
      "check-availability": "Проверять наличие",
      "contact-us": "Свяжитесь с нами",
      "useful-links": "Полезные ссылки",
      "our-events": "Наши мероприятия",
      newsletter: "Бюллетень",
      subscribe: "Подписывайся",
      "email-address": "Электронный адрес",
      others: "Другие",
      name: "Име",
      "last-name": "Фамилия",
      subject: "Тема",
      message: "Сообщение",
      "send-message": "Послать сообщение",
      telephone: "Телефон",
      email: "Эл. Адрес",
      address: "Адрес",
      "add-review": "Добавить отзыв",
      "your-review": "Ваш обзор",
      send: "Отправлять",
      privacy: "Политика конфиденциальности",
      terms: "Условия эксплуатации",
      weddings: "Свадьба",
      seminars: "Конференции и семинары",
      welcome: "Добре Дошли в Хотел София !",
      accommodation: "Проживание",
      "check-in": "Проживание",
      "check-out": "Уход",
      children: "Ребенок",
      guests: "Гости",
      book: "Запази",
      beds: "Легла",
      "room-size": "Размер номера",
      capacity: "Вместимость номера",
      bathroom: "Баня",
      price: "Цена",
      night: "Вечер",
      all: "Все",
      hotel: "Гостиница",
      pool: "Бассейн",
      dining: "Рестораны",
      "hotel-sofia": "Гостиница «София»",
      "dream-vacation": "Мечтаната почивка!",
      "more-about-us": "Подробнее о нас",
      "hotel-address": "9007 Златни пясъци, Варна",
      "all-rights-reserved": "2022 Хотел София. Всички права запазени",
      "see-more": "Подробнее",
      "our-clients": "Нам важно мнение",
      "are-important": "наших гостей!",
      "clients-say":
        " Что гости рассказывают о своем пребывании в Гостиница «София»",

      // Restaurants page
      "a-la-carte-full": "А ля карт ресторан «Медитеранео»",
      "pool-bar": "Бар у бассейна",
      "beach-bar": "Пляжный бар",
      dresscode: "Дресс-код",
      location: "Локация",
      international: "Международный",
      "no-reservation": "Без бронирования",
      casual: "Небрежный",
      "bars-restaurants": "Рестораны и Бары",

      payment: "Оплата",
      pay: "Платить",
      "card-details": "Детали карты",
      details: "Детали",
      "payment-success": "Успешный платеж",
      staff: "Выделенный персонал",
      "amazing-rooms": "Отличные номера",
      "beach-description": `Гостиница «София» имеет собственный пляж и пляжный бар, которыми гости могут насладиться всего в нескольких метрах от отеля.`,
      "double-park-description":
        "Насладитесь отдыхом, расслабившись в уютной атмосфере двухместного номера с видом на парк. В номерах есть эксклюзивные удобства. В каждом номере есть двуспальная или две односпальные кровати, ванная комната с душем, мини-бар, сейф и балкон с видом на парк гостиницы.",
      "double-sea-description":
        "Насладитесь отдыхом, расслабившись в уютной атмосфере двухместного номера с видом на море. В номерах есть эксклюзивные удобства. Каждый номер оборудован двуспальной или двумя односпальными кроватями, ванной комнатой с душем, мини-баром, сейфом и балконом с панорамным видом на море.",
      "double-park-two-kids-description":
        "Насладитесь отдыхом, расслабившись в уютной атмосфере двухместного номера с видом на парк. Идеальный вариант для путешествий с друзьями или семьей. В номерах есть эксклюзивные удобства. Каждый номер оборудован двуспальной или двумя односпальными кроватями, раскладным диваном, ванной комнатой с душевой кабиной, мини-баром, сейфом и балконом с видом на парковую зону гостиницы.",
      "double-sea-two-kids-description":
        "Насладитесь отдыхом, расслабившись в уютной атмосфере двухместного номера с видом на море. Идеально подходит для путешествий с друзьями или семьей. В номерах есть эксклюзивные удобства. Каждый номер оборудован двуспальной или двумя односпальными кроватями, диваном–кроватью, ванной комнатой с душем, мини-баром, сейфом и балконом с панорамным видом на море.",
      "double-park-one-kid-description":
        "Насладитесь отдыхом, расслабившись в уютной атмосфере двухместного номера с видом на парк. Идеальный вариант для путешествий с друзьями или семьей. В номерах есть эксклюзивные удобства. Каждый номер оборудован двуспальной или двумя односпальными кроватями, раскладным диваном, ванной комнатой с душевой кабиной, мини-баром, сейфом и балконом с видом на парковую зону гостиницы.",
      "double-sea-one-kid-description":
        "Насладитесь отдыхом, расслабившись в уютной атмосфере двухместного номера с видом на море. Идеально подходит для путешествий с друзьями или семьей. В номерах есть эксклюзивные удобства. Каждый номер оборудован двуспальной или двумя односпальными кроватями, диваном–кроватью, ванной комнатой с душем, мини-баром, сейфом и балконом с панорамным видом на море.",

      "triple-park-description":
        "Трехместные номера с видом на парк идеально подходят для путешествий с друзьями или семьей. У них есть эксклюзивные удобства, разработанные, чтобы удовлетворить даже самых требовательных гостей. Современный декор и атмосфера обеспечивают хорошее самочувствие во время вашего пребывания и позволяют вам чувствовать себя как дома. В номере есть двуспальная или две односпальные кровати и раскладной диван.",
      "triple-sea-description":
        "Трехместные номера с видом на море идеально подходят для путешествий с друзьями или семьей. У них есть эксклюзивные удобства, разработанные, чтобы удовлетворить даже самых требовательных гостей. Современный декор и атмосфера обеспечивают хорошее самочувствие во время пребывания и позволяют чувствовать себя как дома. В номере есть двуспальная или две односпальные кровати и раскладной диван.",
      "apartment-description":
        "Апартамент «София» имеет две отдельные комнаты. Идеально подходит для путешествий с друзьями или семьей. В апартаментах есть ванная комната с душем, двуспальная или две односпальные кровати, диван-кровать, мини-бар и сейф. Вы можете наслаждаться панорамным видом на море с балкона, попивая утренний кофе!",
      "additional-text": "Допълнителен текст.",
      pricing: "Цены",
      meters: "м²",
      souvenir: "Сувенирен магазин",
      "see-map": "Посмотреть карту",
      "home-text-1":
        "Хотел „София” е един от най-уютните, ваканционни, изцяло реновиран за сезон 2015 г. хотел на българското Черноморие. Намира се на 100 м от плажовете на курортен комплекс “Златни пясъци”",
      "about-subtitle-1":
        'Лето, море, песок, солнце, улыбка, «София», все прекрасное начинается на "С"',
      "about-text-1":
        "Хотел „София” е един от луксозните, ваканционни, изцяло реновиран за сезон 2015 г. хотел на българското Черноморие. Намира се на 100 м от плажовете на курортен комплекс ‘Златни пясъци’. Тук можете да се насладите на удобство, спокойствие и отлични условия за незабравима ваканция. Модерен хотелски комплекс, предлагащ уникално съчетание на съвременна архитектура, уютна атмосфера, забележителна морска или паркова панорама и атрактивни цени.",
      "about-text-2":
        "Ние съчетаваме впечатляващи удобства, професионално обслужване и изискана кухня, за да осигурим стилната атмосфера и незабравимите спомени, с които сте мечтали да запомните Вашата ваканция",
      "wedding-text-1":
        "Ресторант „София“ разполага с 250 места в салон и лятна тераса с 100 места и посреща своите гости в модерна, приятна и отпускаща обстановка.Предлага стил и изтънченост за организиране на Вашите мероприятия – сватби, коктейли, рождени дни, юбилеи.",
      "wedding-text-2":
        "Вашето сватбено тържество ще остави незабравими спомени у вас и вашите близки. Ние съчетаваме отлично обслужване и изискана кухня, за да осигурим стилната и луксозна атмосфера, в която сте мечтали да премине вашия сватбен ден.",
      "wedding-text-3":
        "Ще ви помогнем да организирате неповторимо сватбено тържество – с възможност за формиране на меню с различни цени ,празнична музика, сватбена торта, шампанско, красиви цветни аранжировки и декорации.",
      "seminars-text-1":
        "Конференц–зал «София» обеспечивает все условия для проведения Ваших деловых встреч, семинаров, тренингов, мероприятий, презентаций и многого другого. В нем 70 посадочных мест, современный дизайн и уютная атмосфера.",
      "business-meetings": "бизнес срещи",
      conferences: "конференции",
      tutoring: "обучения",
      "events-lowercase": "събития",
      presentation: "презентации",
      "other-events": "други",
      "booking-summary": "Резюме",
      bgn: "лв.",
      view: "Вид",
      description: "Описание",
      showerhead: "Душевая (кабинка)",
      baby: " Детская кроватка (по запросу)",
      minibar: "Мини-бар",
      tv: "ТВ",
      conditioner: "Кондиционер",
      dryer: "Фен",
      phone: "Телефон",
      adults: "Взрослых",
      team: "Наша команда",
      wrong: "Нещо се обърка",
      "send-review-success": "Вы успешно отправили свой отзыв!",
      "send-email-success": "Вы успешно отправили свое сообщение!",
      "send-email-fail": "Не удалось отправить ваше сообщение!",
      "subscribe-success": "Вы успешно подписались на наши новости!",
      "mediterano-description":
        "Хотите погрузиться в средиземноморскую атмосферу с типичными блюдами – посетите ресторан А ля карт ресторан «Медитеранео» Выберите тематический интерьер или ужин в саду ресторана.",
      "pool-bar-description":
        "«Бар рядом с морем, а море рядом с баром» – рядом с пляжем отеля «София» находится пляжный бар, где можно освежиться ассортиментом напитков, наслаждаясь временем, проведенным на солнце.",
      "lobby-bar-description":
        "Наслаждайтесь чашечкой ароматного кофе или наслаждайтесь общими моментами с коктейлем в руке. Лобби–бар гостиницы «София» расположен в просторном вестибюле и является прекрасным местом для отдыха в неприхотливой атмосфере.",
      "beach-bar-description":
        "Бар у бассейна расположен рядом с открытым бассейном гостиницы «София». Здесь вы можете насладиться освежающими коктейлями, ароматным кофе и тонизирующими напитками.",
      "no-offers": "Не найдено предложений.",
      "invalid-period": "Пожалуйста, выберите допустимый период.",
      reservations: "Бронирование",
      cancellation: "Отмена бронирования",
      "ref-number": "Справочный номер",
      "ref-not-found": "Неверный ссылочный номер",
      "booking-cancelled": "Бронирование было отклонено",
      apply: "Применять",
      "not-found": "Ваучер не найден.",
      "voucher-expired": "Ваучер просрочен",
      "wrong-room-type":
        "Ваучер не может быть использован для этого типа номера.",
      voucher: "Ваучер",
      "voucher-success": "Ваучер успешно применен.",
      "no-room-found": "На выбранный период нет свободных номеров.",
      "enjoy-dream-vacation": "Насладете се на мечтаната почивка!",
      size: "Площадь",
      "capacity-single": "Вместимость",
      "total-price": "Общая стоимость",
      continue: "Продолжать",
      "dates-were-booked": "Выбранные даты заняты.",
      "no-rooms": "На выбранный период нет свободных номеров.",
      "required-field": "Поле обязательно!",

      "room-number": "Количество номеров.",
      "team-text-1":
        "Расслабьтесь, получайте удовольствие, а мы позаботимся о вас!",
      "team-text-2": "Наша команда с нетерпением ждет встречи с вами!",
      "team-text-3": "Мы креативны",
      "team-text-4": "Мы несем ответственность",
      "team-text-5": "Мы любим то, что мы делаем",
      "team-text-6": "Мы всегда готовы прийти на помощь нашим гостям!",
      "feel-relax": "Почувствуйте. Расслабьтесь. Наслаждайтесь.",
      "about-title-1": "Глоток кофе с видом на море и солнце!",
      "about-text-4":
        "Благодаря отличному расположению рядом с пляжем, в гостинице 127 номеров и 9 люксов, большинство из которых выходят на прекрасное море курорта Золотые пески. Сочетание уютной атмосферы, разнообразной кухни и возможности отвлечься от повседневной жизни способствует идеальному выбору для Вашего летнего отпуска, романтического момента или важного события.",
      "about-text-5":
        "Ресторан а ля карт в средиземноморском стиле, полностью обновленный главный ресторан и открытый бассейн.",
      "wedding-text": `Свадебная сказка в красивом месте – гостиница «София», Золотые Пески!
          Вы доверяете нам, а мы воплощаем ваши мечты в жизнь!Ваше свадебное торжество оставит незабываемые воспоминания у вас и ваших близких. Мы поможем вам организовать уникальное свадебное торжество – с возможностью формирования меню, праздничной музыки, красивых цветочных композиций и декораций. Мы сочетаем отличный сервис и изысканную кухню, чтобы обеспечить стильную и роскошную атмосферу, в которой вы мечтали провести свой свадебный день.`,
      sunlight: "Естественный солнечный свет",
      blinds: "Шторы для полного затемнения",
      "projector-wall": "Белая стена для проекции",
      projector: "Проектор и флипчарт",
      "wifi-access": "Wi-Fi доступ",
      "dif-alignment": "Возможность различного вида обустройства зала",
      "custom-ac": "Кондиционер с индивидуальным контролем",
      "free-access":
        "Бесплатный доступ для людей с ограниченными возможностями",
      "close-to-restaurant": "Рядом с основным рестораном «София»",
      separated: "Отделение от потока гостей отеля",
      "paid-parking": "Платная неохраняемая парковка",
      "mid-international": "Средиземноморский/Международный",
      "with-reservation": "С предварительным бронированием",
      "official-dresscode": "Официален",
      "casual-dresscode": "Небрежный",
      "main-restaurant-description": `Главный ресторан «София» открыт на завтрак, обед и ужин «шведский стол». Ресторан имеет обновленный интерьер, что способствует созданию уютной и изысканной атмосферы. Возьмите свой кулинарный паспорт со вкусами интернациональной кухни. Для разнообразия наших гостей мы предлагаем путешествие в ближние и дальние страны, через организованные нами тематические вечера с избранными типичными блюдами.`,
      "main-restaurant": "Главный ресторан Софии",
      "working-hours": "Время работы",
      "home-title-1": "Отойдите от привычного!",
      maximum: "Maximum",
      "general-terms": "Общие условия",
      "already-paid":
        "Бронь уже оплачена. Пожалуйста, свяжитесь с нами для получения дополнительной информации.",
      "cannot-cancel":
        "Бронирование не может быть отменено. Пожалуйста, свяжитесь с нами для получения дополнительной информации.",
      "already-cancelled": "Бронирование уже отменено.",
      "type-reference-number": "Пожалуйста, введите ссылочный номер.",

      "voucher-min-nights":
        "Ваучер можно использовать при бронировании не менее {{minNights}} ночей.",
    },
  },
  ro: {
    translation: {
      rooms: "Camere",
      sea: "Mare",
      park: "Parc",

      // Navigation tabs
      home: "Start",
      about: "Despre noi",
      room: "Camere",
      events: "Evenimente",
      offers: "Oferte",
      reviews: "Prezentări",
      gallery: "Galerie",
      contact: "Contacte",
      services: "Facilități",
      restaurants: "Restaurante",

      // Admin panel
      "create-offer": "Create offer",
      "all-offers": "All offers",
      "all-reviews": "All reviews",
      "approve-reviews": "Одобри ревюта",
      vouchers: "Vouchers",

      // Room names
      "double-park": "Cameră dublă vedere parc",
      "double-sea": "Cameră dublă vedere mare",
      "double-park-two-kids": "Cameră dublă vedere parc 2+2",
      "double-sea-two-kids": "Cameră dublă vedere mare 2+2",
      "double-park-one-kid": "Cameră dublă vedere parc 2+1",
      "double-sea-one-kid": "Cameră dublă vedere mare 2+1",
      "triple-park": "Cameră triplă cu vedere la parc",
      "triple-sea": "Cameră triplă cu vedere la mare",
      apartment: "Apartament cu 2 dormitoare vedere mare",

      // Services names
      "own-beach": "Proprie plajă și bar pe plajă",
      "outdoor-pool": "Piscina in aer liber",
      parking: "Parcare",
      playground: "Colțul copiilor",
      "pool-table": "Biliard",
      "lobby-bar": "Bar Lobby",
      "bike-rent": "Închiriere de biciclete",
      animation: "Animaţie",
      fitness: "Zonă de fitness in aer liber",
      vault: "Seif",
      "currency-exchange": "Schimb valutar",
      "a-la-carte": "Restaurant a la carte",
      conference: "Sală de conferință",
      laundry: "Curățătorie chimică",
      "football-field": "Teren de nisip pentru minifotbal sau volei",
      "parking-description":
        "Nu este posibilă rezervarea unui loc de parcare. Locurile de parcare sunt până la epuizarea celor libere. În caz de ocupare completă, hotelul nu se angajează să ofere un loc de parcare gratuit, dar vă vom ajuta cu informații pentru cei din apropiere.",
      massage: "Masaj",

      subscribers: "Abonați",
      "check-availability": "Verificați disponibilitatea",
      "contact-us": "Contactați-ne",
      "useful-links": "Legături utile",
      "our-events": "Evenimentele noastre",
      newsletter: "Buletin informativ",
      subscribe: "Abonează-te",
      "email-address": "Adresa de e-mail",
      others: "Mai multe",
      name: "Prenume",
      "last-name": "Nume",
      subject: "Subiect",
      message: "Mesaj",
      "send-message": "Trimite un mesaj",
      telephone: "Număr de telefon",
      email: "E-mail",
      address: "Adresa",
      "add-review": "Adaugă recenzie",
      "your-review": "Recenzia ta",
      send: "Trimis",
      privacy: "Politica de confidentialitate",
      terms: "Termeni și conditii",
      weddings: "Nuntă",
      seminars: "Conferințe și seminarii",
      welcome: "SOFIA HOTEL",
      accommodation: "Cazare",
      "check-in": "Check - in",
      "check-out": "Check -out",
      children: "Copil",
      guests: "Invitati",
      book: "Rezervă",
      beds: "Paturi",
      "room-size": "Dimensiunea camerei",
      capacity: "Capacitate",
      bathroom: "Baie",
      price: "Preț",
      night: "Noapte",
      all: "Toate",
      hotel: "Hotel",
      pool: "Piscina",
      dining: "Cina",
      "hotel-sofia": "Hotel Sofia",
      "more-about-us": "Mai multe despre noi",
      "hotel-address": "9007 Golden Sands, Varna",
      "all-rights-reserved": "2022 Hotel Sofia. Toate drepturile rezervate",
      "see-more": "Vezi mai multe",
      "our-clients": "Ce împărtășesc oaspeții despre ",
      "are-important": "șederea lor la Hotel Sofia",
      "clients-say": "Opinia oaspeților noștri este importantă pentru noi!",

      // Restaurant page
      "a-la-carte-full": "Restaurant a la carte Mediteranean",
      "pool-bar": "Barul lângă piscina",
      "beach-bar": "Barul de pe plajă",
      dresscode: "Cod vestimentar",
      location: "Location",
      international: "International",
      "no-reservation": "Fără rezervare",
      casual: "Casual",
      "bars-restaurants": "Restaurante și baruri",
      payment: "Plata",
      pay: "Platește",
      "card-details": "Detalii card",
      details: "Detalii",
      "payment-success": "Tranzacție reusita",
      staff: "Personal dedicat",
      "amazing-rooms": "Camere uimitoare",
      "beach-description": `Hotelul „Sofia” dispune de bar propriu și plajă, de care oaspeții se pot bucura la doar câțiva metri de hotel.`,
      "double-park-description":
        "Bucurați-vă de șederea relaxându-vă în atmosfera confortabilă a camerei duble cu vedere la parc. Camerele au facilități exclusive. Fiecare camera este dotata cu paturi duble sau separate, baie cu duș, minibar, seif si balcon cu vedere la parcul hotelului.",
      "double-sea-description":
        "Bucurați-vă de șederea relaxându-vă în atmosfera confortabilă a camerei duble cu vedere la mare. Camerele au facilități exclusive. Fiecare cameră este dotată cu paturi duble sau separate, baie cu duș, minibar, seif si balcon cu vedere panoramică la mare.",
      "double-park-two-kids-description":
        "Bucurați-vă de șederea relaxându-vă în atmosfera confortabilă a camerei duble cu vedere la parc. Opțiunea ideală atunci când călătoriți cu prietenii sau familia.Camerele au dotări exclusive. Fiecare camera este dotata cu paturi duble sau separate, canapea extensibila, baie cu dus, minibar, seif si balcon cu vedere la zona parcului hotelului.",
      "double-sea-two-kids-description":
        "Bucurați-vă de șederea relaxându-vă în atmosfera confortabilă a camerei duble cu vedere la parc. Opțiunea ideală atunci când călătoriți cu prietenii sau familia.Camerele au dotări exclusive. Fiecare camera este dotata cu paturi duble sau separate, canapea extensibila, baie cu dus, minibar, seif si balcon cu vedere panoramică la mare",
      "double-park-one-kid-description":
        "Bucurați-vă de șederea relaxându-vă în atmosfera confortabilă a camerei duble cu vedere la parc. Opțiunea ideală atunci când călătoriți cu prietenii sau familia.Camerele au dotări exclusive. Fiecare camera este dotata cu paturi duble sau separate, canapea extensibila, baie cu dus, minibar, seif si balcon cu vedere la zona parcului hotelului.",
      "double-sea-one-kid-description":
        "Bucurați-vă de șederea relaxându-vă în atmosfera confortabilă a camerei duble cu vedere la parc. Opțiunea ideală atunci când călătoriți cu prietenii sau familia.Camerele au dotări exclusive. Fiecare camera este dotata cu paturi duble sau separate, canapea extensibila, baie cu dus, minibar, seif si balcon cu vedere panoramică la mare",

      "triple-park-description":
        "Camerele triple cu vedere la parc sunt opțiunea ideală pentru călătorii cu prietenii sau familia. Dispun de facilități exclusive concepute pentru a satisface chiar și cei mai pretențioși oaspeți. Decorul și atmosfera modernă oferă o senzație de bunăstare în timpul șederii și vă permit să vă simțiți ca acasă. Camera dispune de un pat dublu sau două separate și o canapea extensibilă.",
      "triple-sea-description":
        "Camerele triple cu vedere la mare sunt opțiunea ideală pentru călătorii cu prietenii sau familia. Dispun de facilități exclusive concepute pentru a satisface chiar și cei mai pretențioși oaspeți.Decorul și atmosfera modernă oferă o senzație de bunăstare în timpul șederii și vă permit să vă simțiți ca acasă. Camera dispune de un pat dublu sau două separate și o canapea extensibilă.",
      "apartment-description":
        "Apartamentul „Sofia” dispune de două camere separate. Opțiunea Ideală atunci când călătoriți cu prietenii sau familia. Apartamentele au baie cu duș, paturi duble sau separate, canapea extensibilă, minibar si seif. Vă puteți bucura de o vedere panoramică la mare de pe balcon în timp ce beți o gură de cafea de dimineață!",
      pricing: "Prețuri",
      meters: "m²",
      "see-map": "Vezi harta",
      "about-subtitle-1":
        "Vară, mare, nisip, soare, zâmbet, Sofia tot ce este frumos poate fi gasit aici.",
      "home-text-1":
        "Hotelul „Sofia” este unul dintre cele mai confortabile, vacanțe, complet renovat pentru sezonul 2015 de pe litoralul bulgar al Mării Negre. Situat la 100 de metri de plajele stațiunii „Nisipurile de Aur”",
      "about-text-1":
        "Hotelul „Sofia” este unul dintre hotelurile de lux, de vacanță, complet renovate pentru sezonul 2015 de pe litoralul bulgar al Mării Negre. Este situat la 100 de metri de plajele din statiunea Nisipurile de Aur. Aici vă puteți bucura de confort, liniște și condiții excelente pentru o vacanță de neuitat. Complex hotelier modern, care oferă o combinație unică de arhitectură modernă, atmosferă confortabilă, panoramă remarcabilă la mare sau parc și prețuri atractive.",
      "about-text-2":
        "Combinăm facilități impresionante, servicii profesionale și bucătărie rafinată pentru a oferi atmosfera elegantă și amintiri de neuitat la care ai visat să-ți amintești vacanța.",
      "wedding-text-1":
        "Restaurantul Sofia are 250 de locuri in lounge si o terasa de vara cu 100 de locuri si isi intampina oaspetii intr-o atmosfera moderna, placuta si relaxanta. Oferă stil și rafinament pentru organizarea evenimentelor dumneavoastră - nunți, cocktailuri, zile de naștere, aniversari.",
      "wedding-text-2":
        "Sărbătoarea nunții tale va lăsa amintiri de neuitat în tine și în cei dragi. Combinăm serviciile excelente și bucătăria rafinată pentru a oferi atmosfera elegantă și luxoasă în care ați visat să vă petreceți ziua nunții.",
      "wedding-text-3":
        "Vă vom ajuta să organizați o sărbătoare de nuntă inedită – cu posibilitatea formării unui meniu cu prețuri diferite, muzică de sărbători, tort de nuntă, șampanie, aranjamente și decorațiuni florale frumoase.",
      "seminars-text-1":
        "Sala de Conferințe „Sofia” oferă toate condițiile pentru întâlnirile dumneavoastră de afaceri, seminarii, traininguri, evenimente, prezentări și altele. Dispune de 70 de locuri, design modern și atmosferă confortabilă",
      "business-meetings": "Întalniri de afaceri",
      conferences: "Conferințe",
      tutoring: "tutoring",
      "events-lowercase": "evenimente",
      presentation: "prezentare",
      "other-events": "altele",
      "booking-summary": "Rezumatul rezervării",
      bgn: "BGN",
      view: "Vedere",
      description: "Descriere",
      showerhead: "Cabină de duș",
      baby: "Patuț (la cerere)",
      tv: "TV",
      conditioner: "Aer condiționat",
      dryer: "Uscător de pâr",
      phone: "Telefon",
      minibar: "Minibar",
      adults: "adulți",
      team: "Echipa noastră",
      wrong: "Ceva a mers prost",
      "send-review-success": "V-am trimis recenzia cu succes!",
      "send-email-success": "Mesajul a fost trimis cu succes!",
      "send-email-fail":
        "A aparut o problema la trimiterea mesajului dumneavoastră!",
      "subscribe-success": "Abonat cu succes la buletinul nostru informativ!",
      "mediterano-description":
        "Restaurant a la carte Mediteranean: Doriți să vă scufundați într-o atmosferă mediteraneană cu mâncăruri tipice - vizitați restaurantul à la carte „Mediteranean” !. Alegeți între un interiorul tematic sau cina în grădina restaurantului",
      "lobby-bar-description":
        "Savurați o ceașcă de cafea aromată sau răsfățați-vă în momente împărtășite cu un cocktail în mână. Lobby-ul Hotelului Sofia este situat în holul spațios și este un loc minunat de relaxare într-o atmosferă fără pretenții.",
      "pool-bar-description":
        "Barul de la piscină este situat lângă piscina în aer liber a Hotelului Sofia. Aici vă puteți bucura de cocktailuri răcoritoare, cafea aromată și băuturi tonice.",
      "beach-bar-description": `"Barul este langa mare si marea langa bar" - langa plaja Hotelului "Sofia" se afla barul de pe plaja, unde vă poti inviora cu un sortiment de băuturi in timp ce vă bucurați de timpul petrecut sub razele soarelui .`,
      "no-offers": "Nu există oferte in acest moment.",
      "invalid-period": "Vă rugam sa selectati o perioada validă",
      reservations: "Rezervări",
      cancellation: "Anulati rezervarea",
      "ref-number": "Număr de referință",
      "ref-not-found": "Numar de referință invalid.",
      "booking-cancelled": "Rezervare anulată",
      apply: "Aplica",
      "not-found": "Nu a fost găsit niciun cupon.",
      voucher: "Cupon",
      "voucher-expired": "Cuponul este expirat",
      "wrong-room-type":
        "Cuponul nu poate fi folosit pentru acest tip de cameră.",
      "voucher-success": "Cupon aplicat cu succes",
      "no-room-found": "Nu exista camere disponibile pentru perioada aleasă",
      "enjoy-dream-vacation": "Bucurași-vă de vacanța de vis.",
      size: "Dimensiune",
      "capacity-single": "Capacitate",
      "total-price": "Preț total",
      continue: "Continuă",
      "dates-were-booked": "Datele sunt deja rezervate",
      "no-rooms": "Nu exista camere disponibile pentru perioada dorita",
      "required-field": "Câmpul este obligatoriu!",

      "room-number": "№ camere",
      "home-title-1": "Scăpa de obișnuitul",
      "team-text-1":
        "Relaxați-vă, distrați-vă și ăsați-ne pe noi să avem grijă de dvs!",
      "team-text-2": "Echipa noastră vă așteaptă cu nerăbdare!",
      "team-text-3": "Suntem creativi",
      "team-text-4": "Suntem responsabili",
      "team-text-5": "Ne place ceea ce facem",
      "team-text-6": "Suntem mereu gata să ne asistam oaspeții!",
      "feel-relax": "Feel. Relax. Enjoy",
      "about-title-1": "O gură de cafea cu vedere la mare și raze de soare",
      "about-text-4":
        "Cu locația sa excelentă imediat lângă plajă, hotelul dispune de 127 de camere și 9 apartamente, dintre care majoritatea au vedere la frumoasa mare a stațiunii Nisipurile de Aur. Combinația de atmosferă confortabilă, bucătăria variată și oportunitatea de a evada din viața de zi cu zi, contribuie la alegerea perfectă a vacanței dvs. de vară, a momentului romantic sau a evenimentului important.",
      "about-text-5":
        "La dispoziția oaspeților noștri sunt a plajă și barul pe plajă adiacente hotelului, parcarea cu acces controlat, restaurantul à la carte în stil mediteranean, restaurantul principal complet renovat și piscina în aer liber.",
      "wedding-text": `Nunta de basm intr-un loc frumos - Hotel Sofia, Nisipurile de Aur!
      Aveții încredere în noi și noi vă facem visele să devină realitate!
      Sărbătoarea nunții dvs. va lăsa amintiri de neuitat în dvs. și în cei dragi. Vă vom ajuta să organizați o sărbătoare de nuntă unică - cu posibilitatea de a forma un meniu, muzică de sărbători, aranjamente florale și decorațiuni frumoase. Combinăm serviciile excelente și bucătăria rafinată pentru a oferi atmosfera elegantă și luxoasă în care ați visat să vă petreceți ziua nunții.`,
      sunlight: "Lumina naturală a soarelui",
      blinds: "Drapele pentru o întunecare completă",
      "projector-wall": "Perete alb pentru proiectie",
      projector: "Proiector și flipchart",
      "wifi-access": "Acces Wi-Fi",
      "dif-alignment":
        "Posibilitate pentru diferite tipuri de amenajare a sălii",
      "custom-ac": "Aer condiționat cu posibilitate de control individual",
      "free-access": "Acces gratuit pentru persoanele cu dizabilități",
      "close-to-restaurant": "Aproape de restaurantul principal „Sofia”",
      separated: "Separarea de fluxul oaspeților hotelului",
      "paid-parking": "Parcare nepăzită contra cost",
      "mid-international": "Mediteranean/Intrenațională",
      "with-reservation": "Booking required",
      "official-dresscode": "Oficial",
      "casual-dresscode": "Liber",
      "main-restaurant-description": `Restaurantul principal Sofia este deschis pentru mic dejun, prânz și cină tip bufet. Restaurantul este cu un interior renovat, care contribuie la atmosfera confortabilă și rafinată. Luați pașaportul culinar pentru gusturile bucătăriei internaționale. Ca o varietate pentru oaspeții noștri oferim o excursie în țări apropiate și îndepărtate, prin seri tematice organizate de noi cu preparate tipice alese.`,
      "main-restaurant": "Restaurantul principal Sofia",
      "working-hours": "Program de lucru",
      "year-cap": "(de până la 11,99 ani.)",
      maximum: "Maxim",
      "general-terms": "Termeni generali",
      "already-paid":
        "Rezervarea este deja plătită. Vă rugăm să ne contactați pentru mai multe informații.",
      "cannot-cancel":
        "Rezervarea nu poate fi anulată. Vă rugăm să ne contactați pentru mai multe informații.",
      "already-cancelled": "Rezervarea este deja anulată.",

      "type-reference-number": "Vă rugăm să introduceți un număr de referință.",

      "voucher-min-nights":
        "Voucherul poate fi utilizat pentru rezervări de minim {{minNights}} nopți",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "bg", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
