import http from "./httpService";
import { apiUrl } from "../config/config.json";
const apiEndpoint = apiUrl + "/subscribers";

const createSubscriber = async (email) => {
  const subscriber = await http.post(apiEndpoint, { email });
  return subscriber;
};

const getSubscribers = async () => {
  return await http.get(apiEndpoint);
};

const exporedFunctions = {
  createSubscriber,
  getSubscribers,
};

export default exporedFunctions;
