import React from "react";
import Button from "../Button/Button";
import "./style.css";

export default function Modal({
  text,
  buttonOneText,
  buttonTwoText,
  onConfirm,
  onDeny,
}) {
  return (
    <div onClick={(e) => onDeny(e)} className="delete-offer-modal">
      <div className="delete-offer-modal__content">
        <p>{text}</p>
        <div className="delete-offer-modal__content-buttons">
          <Button
            text={buttonOneText}
            onClick={(e) => onConfirm(e)}
            classes={["confirm-delete-button"]}
          />
          <Button text={buttonTwoText} onClick={(e) => onDeny(e)} />
        </div>
      </div>
    </div>
  );
}
