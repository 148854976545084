import http from "./httpService";
import config from "../config/config.json";
const apiUrl = config.apiUrl;

const apiEndpoint = apiUrl + "/xmlreport";

const generate = async (data) => {
  const { data: XMLString } = await http.post(`${apiEndpoint}/generate`, data);
  return XMLString;
};

const exportedFunctions = {
  generate,
};

export default exportedFunctions;
