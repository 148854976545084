import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

const Editor = ({
  onChange,
  content,
  placeholder,
  shouldClear,
  setShouldClear,
}) => {
  const [editorHtml, setEditorHtml] = useState(content ? content : "");

  useEffect(() => {
    if (content) {
      setEditorHtml(content);
    }
  }, [content]);

  useEffect(() => {
    if (shouldClear) {
      setTimeout(() => {
        setEditorHtml("");
        setShouldClear(false);
      }, 500);
    }
  }, [shouldClear, setShouldClear]);

  const handleChange = (html) => {
    setEditorHtml(html);
    onChange(html);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      [
        {
          color: [
            "#023047",
            "#1687a7",
            "#219ebc",
            "#bfffab",
            "#f2ffed",
            "#000000",
            "#4e4e4e",
            "#979797",
            "#c4c4c4",
            "#ededed",
            "#ffffff",
            "#f16464",
            "#27ae60",
            "#f2c94c",
            "#8ecae6",
            "#b0dff5",
            "#f9f9f9",
          ],
        },
        {
          background: [
            "#023047",
            "#1687a7",
            "#219ebc",
            "#bfffab",
            "#f2ffed",
            "#000000",
            "#4e4e4e",
            "#979797",
            "#c4c4c4",
            "#ededed",
            "#ffffff",
            "#f16464",
            "#27ae60",
            "#f2c94c",
            "#8ecae6",
            "#b0dff5",
            "#f9f9f9",
          ],
        },
      ],
      ["image"],
    ],
  };
  return (
    <div className="text-editor">
      {/* <QuillToolbar /> */}
      <ReactQuill
        value={editorHtml}
        onChange={handleChange}
        placeholder={placeholder}
        modules={modules}
        formats={Editor.formats}
      />
    </div>
  );
};

export default Editor;
