import React from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "../ContactFrom";
import "./style.css";

const Contactpage = () => {
  const { t } = useTranslation();
  return (
    <div id="Contact" className="contact-area section-padding">
      <div className="container">
        <div className="wpo-contact-info">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-internet"></i>
                  </div>
                  <div className="info-text">
                    <span>{t("address")}</span>
                  </div>
                </div>
                <h2>{t("hotel-address")}</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-email"></i>
                  </div>
                  <div className="info-text">
                    <span>{t("email")}</span>
                  </div>
                </div>
                <h2>reservation@sofiahotel.net</h2>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="info-item">
                <div className="info-wrap">
                  <div className="info-icon">
                    <i className="fi flaticon-null-1"></i>
                  </div>
                  <div className="info-text">
                    <span>{t("telephone")}</span>
                  </div>
                </div>
                <a href="tel:+35952355120">
                  <h2>+ 359 52 355 120</h2>
                </a>
                <a href="tel:+359898774435">
                  <h2>+ 359 898 774 435</h2>
                </a>
                <a href="tel:+35952385800">
                  <h2>+ 359 52 385 800</h2>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-content">
          <h2>{t("send-message")}</h2>
          <div className="contact-form">
            <ContactForm />
          </div>
        </div>
        <div className="contact-map">
          <iframe
            title="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11618.615370895252!2d28.0406391!3d43.2796273!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfaf3e6201159e0ba!2z0KHQvtGE0LjRjyDQpdC-0YLQtdC7!5e0!3m2!1sbg!2sbg!4v1651513859819!5m2!1sbg!2sbg"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contactpage;
