import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "../../components/Modal/Modal";
import Navbar from "../../components/Navbar";
import Review from "../../components/Review/Review";

import reviewService from "../../services/reviewService";

import "./style.css";

export default function AdminAllReviewsPage() {
  const [reviews, setReviews] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState();

  useEffect(() => {
    reviewService.getAllReviews().then((res) => {
      if (res.data && res.data.length) {
        setReviews(res.data);
      }
    });
  }, []);

  const approveReview = async (review) => {
    try {
      const res = await reviewService.approveReview(review._id);
      if (res.data) {
        let reviewsCopy = [...reviews];
        reviewsCopy[reviewsCopy.indexOf(review)].status = "approved";
        setReviews(reviewsCopy);
        toast.success("Успешно одобрено ревю!");
      }
    } catch (ex) {
      toast.error("Нещо се обърка. Моля опитай отново");
    }
  };

  const deleteReview = async () => {
    const reviewsCopy = [...reviews];
    const filteredReviews = reviewsCopy.filter((x) => x._id !== selectedReview);
    try {
      await reviewService.deleteReview(selectedReview);
      setReviews([...filteredReviews]);
      toast.success("Успешно изтрито ревю!");
    } catch (ex) {
      setReviews([...reviewsCopy]);
      toast.error("Нещо се обърка. Моля опитай отново");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="all-reviews-page-container">
        <h3>Всички ревюта</h3>
        <div className="admin-display-reviews-container">
          {reviews &&
            reviews.map((review, index) => {
              return (
                <Review
                  isAdmin
                  item={review}
                  key={index}
                  deleteReview={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedReview(review._id);
                  }}
                  approveReview={approveReview}
                />
              );
            })}
        </div>
      </div>
      {isDeleteModalOpen && (
        <Modal
          buttonOneText={"Да"}
          buttonTwoText={"Не"}
          onConfirm={() => deleteReview()}
          onDeny={() => setIsDeleteModalOpen(false)}
          text="Сигурен ли си, че искаш да изтриеш това ревю ?"
        />
      )}
    </div>
  );
}
