import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Homepage2 from "../HomePage2";
import RoomPage from "../RoomPage";
import RoomSinglePage from "../RoomSinglePage";
import AboutPage from "../AboutPage";
import ServicePage from "../ServicePage";
import FaqPage from "../FaqPage";
import ErrorPage from "../ErrorPage";
import ContactPage from "../ContactPage";

import ForgotPassword from "../ForgotPassword";
import EventsPage from "../EventsPage";
import ReviewPage from "../ReviewPage";
import GalleryPage from "../GalleryPage";
import OffersPage from "../OffersPage/OffersPage";
import AdminRoot from "../AdminRoot/AdminRoot";
import RestaurantPage from "../RestaurantsPage/RestaurantPage";
import PaymentPage from "../PaymentPage/PaymentPage";
import CancellationPage from "../CancellationPage/CancellationPage";
import GeneralTerms from "../GeneralTerms";
import HotelPolicy from "../HotelPolicy";
import PrivacyPolicy from "../PrivacyPolicy";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Homepage2} />
          <Route path="/home" component={Homepage2} />
          <Route path="/room" component={RoomPage} />
          <Route path="/room-single" component={RoomSinglePage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/services" component={ServicePage} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/events" component={EventsPage} />
          <Route path="/reviews" component={ReviewPage} />
          <Route path="/gallery" component={GalleryPage} />
          <Route path="/offers" component={OffersPage} />
          <Route path="/restaurants" component={RestaurantPage} />
          <Route path="/admin" component={AdminRoot} />
          <Route path="/payment" component={PaymentPage} />
          <Route path="/cancellation" component={CancellationPage} />
          <Route path="/hotel-policy" component={HotelPolicy} />
          <Route path="/privacy" component={PrivacyPolicy} />
          <Route path="/general-terms" component={GeneralTerms} />
          <Route component={ErrorPage} />
        </Switch>
      </Router>
    </div>
  );
};

export default AllRoute;
