import { useEffect, useState } from "react";
import offersService from "../services/offersService";

export default function useOffers(language = null) {
  const [offers, setOffers] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    try {
      offersService.getAllOffers().then((res) => {
        if (language) {
          const text = "text" + language;
          const title = "title" + language;
          let filteredOffers;
          if (!res.data || !res.data.length) {
            filteredOffers = [];
          } else {
            filteredOffers = res.data.filter(
              (offer) => offer[text] && offer[title]
            );
          }
          setOffers(filteredOffers);
        } else {
          setOffers(res.data);
        }
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
    }
  }, [language]);
  return { offers, setOffers, loading };
}
