import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";
import Navbar from "../components/Navbar";

export default function GeneralTerms() {
  const { i18n } = useTranslation();
  const bulgarian = (
    <Fragment>
      <h2 classNameName="center-text">
        <strong>
          Политика на хотел &ldquo;София&rdquo; и условия за настаняване
        </strong>
      </h2>
      <p>&nbsp;</p>
      <ul>
        <li>Условия за настаняване</li>
      </ul>
      <p>Настаняване след 14:00 ч.</p>
      <p>Напускане преди 12:00 ч.</p>
      <p>Домашни любимци не се допускат в хотела.&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        В случай, че хотелът не е в състояние да приеме госта след потвърдена
        резервация, хотелът се задължава да предостави алтернативно настаняване
        в хотел от същата категоризация, без промяна в условията на
        резервацията.
      </p>
      <p>&nbsp;</p>
      <p>
        Хотелът разполага с платен паркинг с 80 места в непосредствена близост
        до рецепция.
      </p>
      <p>Резервация на паркомясто не е възможна.</p>
      <p>Паркоместата са до изчерпване на наличните свободни.</p>
      <p>
        В случай на пълна заетост, хотелът не се ангажира с осигуряване на
        свободно място за паркиране, но бихме съдействали с информация за други
        в близост.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>Условия за плащане</li>
      </ul>
      <p>
        В процеса на Вашата резервация, имате възможност да изберете начин на
        плащане.
      </p>
      <p>Плащане според условията на резервацията.</p>
      <p>
        Плащане с карта, като ако изберете този метод, вие трябва да платите
        пълната сума за настаняването.&nbsp;
      </p>
      <p>Плащане по банков път</p>
      <p>
        Плащанията по банков път трябва да бъдат постъпили до 5 дни от датата,
        която е направена резервацията. В противен случай резервацията се
        анулира.
      </p>
      <p>&nbsp;</p>
      <p>Банкови детайли в ЛЕВА:</p>
      <p>
        <strong>&bdquo; Голдън Турс &bdquo;ЕООД</strong>
      </p>
      <p>
        <strong>Гр.София 1407,България</strong>
      </p>
      <p>
        <strong>Бул.&ldquo;Джеймс Баучер&ldquo;60-64</strong>
      </p>
      <p>
        <strong>МОЛ :Милена Замфирова</strong>
      </p>
      <p>
        <strong>ИН по ДДС&nbsp; BG131208096</strong>
      </p>
      <p>
        <strong>Първа Инвестиционна Банка</strong>
      </p>
      <p>
        <strong>IBAN : BG38FINV91501016644608</strong>
      </p>
      <p>Основание за плащане: № на резервацията&hellip;..</p>
      <p>&nbsp;</p>
      <p>Банкови детайли в ЕВРО:</p>
      <p>
        <strong>&ldquo;Golden Tours&rdquo; EOOD</strong>
      </p>
      <p>
        <strong>Sofia 1407,Bulgaria</strong>
      </p>
      <p>
        <strong>Bul.&rdquo;James Baucher&rdquo;60-64</strong>
      </p>
      <p>
        <strong>Milena Zamfirova</strong>
      </p>
      <p>
        <strong>VAT&nbsp; BG131208096</strong>
      </p>
      <p>
        <strong>First Investment Bank&nbsp; - FIBANK</strong>
      </p>
      <p>
        <strong>IBAN: BG57FINV91501016644557</strong>
      </p>
      <p>
        <strong>SWIFT : FINVBGSF</strong>
      </p>
      <p>&nbsp;</p>
      <p>Основание за плащане: № на резервацията&hellip;..</p>
      <p>&nbsp;</p>
      <ul>
        <li>Условия за анулация</li>
      </ul>
      <p>
        При анулация на потвърдена резервация до 7 дни преди пристигане, не се
        дължи неустойка. При получено плащане от Ваша страна, се възстановява в
        срок от 7 дни, считано от датата на получената анулация.
      </p>
      <p>&nbsp;</p>
      <p>
        При анулация на резервация 6 или по-малко дни преди пристигане, се дължи
        неустойка в размер на 1 нощувка за всяка резервирана стая. Останалата
        сума се възстановява по сметката ви в срок от 7 дни, считано датата на
        получената анулация.
      </p>
      <p>&nbsp;</p>
      <p>
        В случай на неявяване, сумата по Вашата резервация няма да ви бъде
        възстановена.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>Допълнителна информация:</li>
      </ul>
      <p>
        За допълнителни въпроси относно резервация или начин на плащане, може да
        се свържете с нас на имейл: reservation@sofiahotel.net или на телефон:
        Tel: + 359 52 355 120&nbsp; / Mob: + 359 898 774 435
      </p>
    </Fragment>
  );

  const english = (
    <Fragment>
      <h2 classNameName="center-text">
        <strong>Sofia Hotel Policy and Accommodation Conditions</strong>
      </h2>
      <ul>
        <li>Accommodation</li>
      </ul>
      <p>Check-in after 2:00 p.m.</p>
      <p>Check out before 12:00 p.m.</p>
      <p>Pets are not allowed in the hotel.</p>
      <p>&nbsp;</p>
      <p>
        In the event that the hotel is unable to accept the guest after a
        confirmed reservation, the hotel undertakes to provide alternative
        accommodation in a hotel of the same category, without changing the
        conditions of the reservation.
      </p>
      <p>&nbsp;</p>
      <p>
        The hotel has a paid parking lot with 80 spaces in close proximity to
        the reception.
      </p>
      <p>
        Parking space booking is not possible.
        <span className="Apple-converted-space">&nbsp;</span>
      </p>
      <p>
        Parking spaces are subject to availability. In case of full occupancy,
        the hotel is not responsible and does not undertake to provide a free
        parking space but may assist with information about others nearby.
      </p>
      <p>&nbsp;</p>
      <p>
        The hotel has its own beach and an outdoor pool. Every paid reservation
        includes the use of an umbrella and chaise-longue at the pool or on the
        beach (subject to availability). Two chaise-longues and one umbrella are
        included in the price of a double room.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          Payment conditions
          <span className="Apple-converted-space">&nbsp;</span>
        </li>
      </ul>
      <p>
        In the process of making a reservation, you have the opportunity to
        choose the payment method.
      </p>
      <p>Payment according to the terms of the reservation.</p>
      <p>
        Bank card payment, and if you choose this method you must pay the full
        amount for the accommodation.
      </p>
      <p>Bank payment</p>
      <p>
        Bank payments must be received within 5 days of the date the
        <span className="Apple-converted-space">&nbsp;</span>
      </p>
      <p>reservation was made. Otherwise, the reservation is cancelled.</p>
      <p>&nbsp;</p>
      <p>Bank details in BGN:</p>
      <p>
        <strong>BGN</strong>
      </p>
      <p>&bdquo;Голдън Турс &bdquo;ЕООД</p>
      <p>Гр.София1407,България</p>
      <p>Бул.&ldquo;Джеймс Баучер&ldquo;60-64</p>
      <p>МОЛ :Милена Замфирова</p>
      <p>ИН по ДДС BG131208096</p>
      <p>Първа Инвестиционна Банка</p>
      <p>IBAN : BG38FINV91501016644608</p>
      <p>Основание за плащане: № на резервацията&hellip;..</p>
      <p>&nbsp;</p>
      <p>
        <strong>EUR</strong>
      </p>
      <p>&ldquo;Golden Tours&rdquo; EOOD</p>
      <p>Sofia 1407,Bulgaria</p>
      <p>Bul.&rdquo;James Baucher&rdquo;60-64</p>
      <p>Milena Zamfirova</p>
      <p>VAT BG131208096</p>
      <p>First Investment Bank - FIBANK</p>
      <p>IBAN: BG57FINV91501016644557</p>
      <p>
        SWIFT : FINVBGSF<span className="Apple-converted-space">&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <p>Grounds for payment: The number of the reservation&hellip;.</p>
      <p>&nbsp;</p>
      <ul>
        <li>Cancellations</li>
      </ul>
      <p>
        In the event of a cancellation of a confirmed reservation up to 7 days
        before arrival, no penalty is due. If payment has been made by you, such
        payment shall be refunded within 7 work days from the date of the
        received cancellation.
      </p>
      <p>&nbsp;</p>
      <p>
        Cancellations made 6 days or less prior to arrival incur a penalty of 1
        night for each room booked. The remaining amount is refunded to your
        account within 7 work days, counting from the date of the cancellation
        received.
      </p>
      <p>&nbsp;</p>
      <p>
        In the event of no-show, the amount of your reservation will not be
        refunded.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>For more information:</li>
      </ul>
      <p>
        For additional questions regarding reservation or payment methods you
        can contact us by email at reservation@sofiahotel.net or by phone: +359
        52&nbsp;355 120
      </p>
    </Fragment>
  );

  const language = i18n.language;
  return (
    <Fragment>
      <Navbar />
      <div style={{ padding: "20px" }}>
        {language === "bg" ? bulgarian : english}
      </div>
      <Footer />
    </Fragment>
  );
}
