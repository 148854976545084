import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUser } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button/Button";
import RoomInfo from "../../js/RoomInfo";
import { getDateDB, getDatesInRange } from "../../utils/date";
import usePeriodRoomPrices from "../../utils/usePeriodRoomPrices";
import "./style.css";

export default function RoomBookingView({ roomsToDisplay: rooms, data }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [roomsPrices, daysInPeriods] = usePeriodRoomPrices(
    data && data.startDate,
    data && data.endDate
  );
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [bookedDays, setBookedDays] = useState();
  const [daysLength, setDaysLength] = useState();

  useEffect(() => {
    if (data) {
      const dates = getDatesInRange(
        getDateDB(data.startDate),
        getDateDB(data.endDate)
      );

      setDaysLength(dates.length - 1);
      setBookedDays(dates);
    }
  }, [data]);

  /**
   *
   * @param {Number} count - the ammount of rooms to select
   * @param {String} roomName - the name of the room to select
   */
  const handleRoomSelect = (count, roomName) => {
    let selectedRoomsCopy = [...selectedRooms];
    const roomExists = selectedRooms.find((x) => x.roomName === roomName);
    let totalPriceForAllPeriods = 0;
    Object.keys(daysInPeriods).forEach((period) => {
      totalPriceForAllPeriods +=
        roomsPrices[roomName][period] * daysInPeriods[period];
    });
    if (roomExists) {
      selectedRoomsCopy[selectedRoomsCopy.indexOf(roomExists)] = {
        roomName,
        count,
        totalPriceForAllPeriods,
        daysInPeriods,
      };
    } else {
      selectedRoomsCopy.push({
        roomName,
        count,
        totalPriceForAllPeriods,
        daysInPeriods,
      });
    }
    console.log(selectedRoomsCopy, "rooms copy");
    setSelectedRooms(selectedRoomsCopy);
    let totalAmmount = 0;
    for (let i = 0; i < selectedRoomsCopy.length; i++) {
      const tempTotal =
        selectedRoomsCopy[i].totalPriceForAllPeriods *
        selectedRoomsCopy[i].count;
      totalAmmount += tempTotal;
    }
    // console.log("days length", daysLength);
    setTotalPrice(totalAmmount.toFixed(2));
  };

  const handleContinue = () => {
    if (!totalPrice || totalPrice === "0.00") {
      return;
    }
    window.scrollTo(10, 0);
    const finalData = {
      ...data,
      rooms: selectedRooms.filter((x) => x.count !== 0),
      price: Number(totalPrice),
      daysLength,
    };
    history.push({
      pathname: "payment",
      state: {
        bookingData: finalData,
        roomsPrices,
        bookedDays,
      },
    });
  };
  return !rooms || !rooms.length ? (
    <div className="no-rooms-available">
      <h4>{t("no-rooms")}</h4>
    </div>
  ) : (
    <div className="room-booking-view">
      <div className="total-price-container">
        <h5>{`${t("total-price")}: ${totalPrice} ${t("bgn")}`}</h5>
        <Button text={t("continue")} onClick={handleContinue} />
      </div>
      {rooms &&
        rooms.length &&
        roomsPrices &&
        rooms.map((room, index) => {
          return (
            <RoomBookingViewSingle
              key={index}
              room={RoomInfo[room.roomName]}
              price={roomsPrices ? roomsPrices[room.roomName] : ""}
              availableRooms={room.availableRooms}
              t={t}
              handleRoomSelect={handleRoomSelect}
            />
          );
        })}
      <div className="total-price-container">
        <h5>{`${t("total-price")}: ${totalPrice} ${t("bgn")}`}</h5>
        <Button text={t("continue")} onClick={handleContinue} />
      </div>
    </div>
  );
}

const RoomBookingViewSingle = ({
  room,
  t,
  price,
  availableRooms,
  handleRoomSelect,
}) => {
  const defaultValue = { value: 0, label: "0" };
  const [roomsCount, setRoomsCount] = useState(defaultValue);

  const handleChange = (value) => {
    handleRoomSelect(value.value, room.roomKey);
    setRoomsCount(value);
  };

  let options = [];
  for (let i = 0; i <= availableRooms; i++) {
    options.push({ value: i, label: i.toString() });
  }

  /**
   *
   * @param {Object} prices - the prices for different periods - {"periodOne": 150}
   * @returns
   */
  const getPricesString = (prices) => {
    if (!prices) return <div />;
    if (Object.keys(prices).length === 1) {
      const firstPriceKey = Object.keys(prices)[0];
      const firstPrice = prices[firstPriceKey];
      return `${firstPrice}${t("bgn")} / ${t("night")}`;
    } else {
      const pricesValues = Object.values(prices);
      const firstPrice = `${Math.min(...pricesValues)}${t("bgn")}`;
      const lastPrice = `${Math.max(...pricesValues)}${t("bgn")}`;
      return `${firstPrice} - ${lastPrice} / ${t("night")}`;
    }
  };

  return (
    <div className="room-booking-view__single">
      <img
        className="room-booking-view__single-image"
        src={room.image}
        alt=""
      />
      <div className="room-booking-view__single-content">
        <div className="room-booking-view__single-icon-list">
          <div className="single-icon__item">
            <FontAwesomeIcon icon={faUser} />
            <p>{t("capacity-single")}</p>
            <p>
              {RoomInfo[room.roomKey].adults} {t("adults")}
            </p>
            <p>{`${RoomInfo[room.roomKey].children} ${t("children")}`}</p>
          </div>
          <div className="single-icon__item">
            <FontAwesomeIcon icon={faUser} />
            <p>{t("size")}</p>
            <p>{`${RoomInfo[room.roomKey].size} m2`}</p>
          </div>
          <div className="single-icon__item">
            <FontAwesomeIcon icon={faEye} />
            <p>{t("view")}</p>
            <p>{t(RoomInfo[room.roomKey].view)}</p>
          </div>
        </div>
        <h4>{t(room.roomKey)}</h4>
        <h6>{t(`${room.roomKey}-description`)}</h6>
      </div>
      {/* <LineSeparator /> */}
      <div className="room-booking-view__book-container">
        <div className="room-booking-view__single-price">
          {getPricesString(price)}
        </div>

        <div className="booking-view-select-container">
          <p>{t("room-number")}</p>
          <ReactSelect
            defaultValue={defaultValue}
            value={roomsCount}
            className="select booking-view-select"
            onChange={(value) => handleChange(value)}
            options={options}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              control: (base, state) => ({
                ...base,
                border: 0,
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid black",
                },
              }),
            }}
          />
        </div>
      </div>
    </div>
  );
};
