import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RoomInfo from "../../js/RoomInfo";
import periods from "../../utils/periods";
import roomService from "../../services/roomService";
import loadingImage from "../../images/loading-spinner.gif";
import getTaxedPrice from "../../utils/getRoomPriceWithTax";

import "./style.css";

const RoomSection = ({ rClass, data, roomsToDisplay, isRoomsLoading }) => {
  let rooms = roomsToDisplay ? roomsToDisplay : Object.keys(RoomInfo);
  const history = useHistory();
  const { t } = useTranslation();

  const [roomsPrices, setRoomsPrices] = useState({});

  useEffect(() => {
    roomService.getRooms().then((res) => {
      const roomData = res.data;
      let prices = {};
      let currentPeriod;
      const today = new Date();
      Object.keys(periods).forEach((period) => {
        periods[period].forEach((interval) => {
          const dates = interval.split(" - ");
          const startDate = new Date(dates[0]);
          const endDate = new Date(dates[1]);
          if (today >= startDate && today <= endDate) {
            currentPeriod = period;
          }
        });
      });
      Object.keys(RoomInfo).forEach((room) => {
        const currentRoomInfo = roomData.find((x) => x.name === room);
        const currentRoomPrice = currentRoomInfo[currentPeriod + "Price"];
        prices[room] = getTaxedPrice(currentRoomPrice);
      });
      setRoomsPrices(prices);
    });
  }, []);

  const handleRoomClick = (room) => {
    room["price"] = roomsPrices[room.roomKey];
    window.scrollTo(10, 0);
    history.push({
      pathname: "/room-single",
      state: { room: room, bookingData: data },
    });
  };

  return (
    <section className={`Room-area section-padding ${rClass}`}>
      <div className="Room-section">
        <div className="container">
          <div className="col-12">
            <div className="wpo-section-title">
              <h3 className="center-text room-title">{t("rooms")}</h3>
            </div>
          </div>
          {/* <SingleRoom2 /> */}
          <div className="row">
            <div className="col col-xs-12 sortable-gallery">
              {isRoomsLoading && (
                <div className="loading-spinner">
                  <img src={loadingImage} alt="loading" />
                </div>
              )}
              <div className="gallery-container">
                {rooms && rooms.length
                  ? rooms.map((room, rm) => (
                      <RoomPreview
                        room={RoomInfo[room]}
                        price={roomsPrices[room]}
                        key={rm}
                        handleRoomClick={handleRoomClick}
                        title={room}
                        t={t}
                      />
                    ))
                  : !isRoomsLoading && <p>{t("no-room-found")}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const RoomPreview = ({ room, handleRoomClick, title, t, price }) => {
  return (
    <div className="grid">
      <div className="room-item room-item-main">
        <img src={room.image} alt="" className="img img-responsive" />
        <div className="room-text-show">
          <h4 className="room-text-show__h4">{t(title)}</h4>
        </div>
        <div className="room-text-hide">
          <div className="room-text-hide__wrapper">
            <h4 className="room-name">{t(title)}</h4>
            <p className="room-description">{t(`${title}-description`)}</p>
          </div>
          {/* <small>From: <span>{room.Price}</span> / Night</small> */}
          <div className="availability-price-container">
            <div
              className="theme-btn-s2 room-check-availability"
              onClick={() => handleRoomClick(room)}
            >
              {t("see-more")}
            </div>
            {price ? (
              <div className="room-price-container">
                <p className="room-price">
                  {price} {t("bgn")}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomSection;
