import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { useTranslation } from "react-i18next";

function Hero(props) {
  const { t } = useTranslation();
  var settings = {
    dots: true,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    fade: true,
  };
  return (
    <section className={`hero ${props.heroClass}`}>
      <div className="hero-slider">
        <Slider {...settings}>
          {props.images &&
            props.images.map((image, index) => {
              return (
                <div className="slide" key={index}>
                  <div
                    className="slide-inner"
                    style={{ backgroundImage: `url(${image})` }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col col-lg-8 col-md-12 col-12 slide-caption">
                          <div className="slide-title">
                            <h2 style={{ fontFamily: "Roboto" }}>
                              {t("hotel-sofia")}
                            </h2>
                          </div>
                          <div className="slide-subtitle">
                            <p>
                              Summer, sea, sand, sun, smile, Sofia everything
                              beautiful starts with a "S"
                            </p>
                          </div>
                          {/* <div className="btns">
                        <Link to="/room" className="theme-btn">
                          Book A Room
                        </Link>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </section>
  );
}
export default Hero;
