import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import settings from "../../../utils/sliderSettings";
import RoomInfo from "../../../js/RoomInfo";
function Rooms({ room }) {
  const [roomData, setRoomData] = useState();

  useEffect(() => {
    if (room) {
      setRoomData(RoomInfo[room.roomKey]);
    }
  }, [room]);
  return (
    <div className="room-slide-area section-padding">
      <div className="Room-carousel">
        <Slider {...settings}>
          {roomData && roomData.image2 && (
            <div className="item">
              <div className="destination-item">
                <div className="destination-img">
                  <img src={roomData.image1} alt="" />
                </div>
              </div>
            </div>
          )}
          {roomData && roomData.image2 && (
            <div className="item">
              <div className="destination-item">
                <div className="destination-img">
                  <img src={roomData.image2} alt="" />
                </div>
              </div>
            </div>
          )}
          {roomData && roomData.image3 && (
            <div className="item">
              <div className="destination-item">
                <div className="destination-img">
                  <img src={roomData.image3} alt="" />
                </div>
              </div>
            </div>
          )}
        </Slider>
      </div>
    </div>
  );
}

export default Rooms;
