import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Button from "../../components/Button/Button";
import Navbar from "../../components/Navbar";
import Select from "react-select";
import RoomInfo from "../../js/RoomInfo";

import vouchersService from "../../services/vouchersService";

import "./style.css";
import { useTranslation } from "react-i18next";
import { getDateDB } from "../../utils/date";
import { validate } from "../../utils/validation";
import Joi from "joi";
import { toast } from "react-toastify";

const schema = Joi.object({
  name: Joi.string().required().label("Името е задължително"),
  startDate: Joi.string().required(),
  endDate: Joi.string().required(),
  discount: Joi.number()
    .required()
    .label("Процентът е задължителен и трябва да е число!"),
  roomsType: Joi.array()
    .min(1)
    .required()
    .label("Моля изберете поне един тип стая!"),
  minNights: Joi.number().label("Минимум нощувки трябва да е повече от 1!"),
});

export default function AdminVouchersPage() {
  const [vouchers, setVouchers] = useState();
  const [voucherToDelete, setVoucherToDelete] = useState();
  const [addNewVoucherModalOpen, setAddNewVoucherModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (!addNewVoucherModalOpen) {
      vouchersService.getAllVouchers().then((res) => {
        if (res.data && res.data.length) {
          setVouchers(res.data);
        }
      });
    }
  }, [addNewVoucherModalOpen]);

  const openDeleteModal = (id) => {
    setConfirmDeleteModalOpen(true);
    setVoucherToDelete(id);
  };

  const deleteVoucher = async () => {
    const id = voucherToDelete;
    try {
      const res = await vouchersService.deleteVoucher(id);
      if (res.status === 200) {
        const vouchersCopy = [...vouchers].filter((x) => x._id !== id);
        setVouchers([...vouchersCopy]);
      }
    } catch (ex) {
      console.log(ex);
      toast.error("Нещо се обърка!");
    }
    setConfirmDeleteModalOpen(false);
  };

  return (
    <div>
      <Navbar />
      <div className="vouchers-container">
        <Button
          text="Добави нов ваучер"
          onClick={() => setAddNewVoucherModalOpen(true)}
        />
        <table className="vouchers-table">
          <thead>
            <tr>
              <th>Име</th>
              <th>Тип стаи</th>
              <th>Валиден от</th>
              <th>Валиден до</th>
              <th>Минимум нощувки</th>
              <th>Отстъпка</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {vouchers &&
              vouchers.map((voucher, index) => {
                return (
                  <tr key={index}>
                    <td>{voucher.name}</td>
                    <td>
                      {voucher.roomsType.map((room, index) => {
                        return (
                          <span key={index}>
                            {t(room)}
                            {index !== voucher.roomsType.length - 1 && ", "}
                          </span>
                        );
                      })}
                    </td>
                    <td>{voucher.startDate}</td>
                    <td>{voucher.endDate}</td>
                    <td>{voucher.minNights}</td>
                    <td>{voucher.discount}%</td>
                    <td>
                      <Button
                        text="Изтрий"
                        classes={["delete-voucher-button"]}
                        onClick={() => openDeleteModal(voucher._id)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {addNewVoucherModalOpen && (
        <AddNewVoucherModal onClose={() => setAddNewVoucherModalOpen(false)} />
      )}
      {confirmDeleteModalOpen && (
        <div
          className="add-new-voucher-modal"
          onClick={(e) => {
            e.stopPropagation();
            setConfirmDeleteModalOpen(false);
          }}
        >
          <div
            className="add-new-voucher-modal__content"
            onClick={(e) => e.stopPropagation()}
          >
            <p>Сигурни ли сте, че искате да изтриете този ваучер?</p>
            <div>
              <Button
                text="Да"
                classes={["delete-voucher-button"]}
                onClick={deleteVoucher}
              />
              <Button
                text="Не"
                onClick={() => setConfirmDeleteModalOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ height: "500px" }} />
    </div>
  );
}

const AddNewVoucherModal = ({ onClose }) => {
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    discount: "",
    roomsType: [],
    minNights: 1,
  });

  let roomOptions = [];
  Object.keys(RoomInfo).forEach((x) => {
    roomOptions.push({ value: x, label: t(x) });
  });

  const closeModal = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleChange = (field, value) => {
    let dataCopy = { ...data };
    if (field === "roomsType") {
      value = value.map((x) => x.value);
    }
    dataCopy[field] = value;
    setData(dataCopy);
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error(errors[Object.keys(errors)[0]]);
    }
  }, [errors]);

  const createVoucher = async (e) => {
    e.preventDefault();
    let dataCopy = { ...data };
    dataCopy.startDate = getDateDB(dataCopy.startDate);
    dataCopy.endDate = getDateDB(dataCopy.endDate);
    dataCopy.discount = Number(dataCopy.discount);
    dataCopy.minNights = Number(dataCopy.minNights);

    if ((await validate(dataCopy, schema, setErrors)) === null) {
      try {
        const res = await vouchersService.createVoucher(dataCopy);
        if (res.data) {
          toast.success("Успешно добавен нов ваучер!");
          onClose();
        }
      } catch (ex) {
        console.log(ex);
        toast.error("Нещо се обърка!");
      }
    }
  };

  return (
    <div className="add-new-voucher-modal" onClick={(e) => closeModal(e)}>
      <div
        className="add-new-voucher-modal__content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <form>
          <input
            value={data.name}
            onChange={(e) => handleChange("name", e.currentTarget.value)}
            placeholder="Име на ваучер"
            className="add-new-voucher-modal__normal-input"
          />
          <Select
            onChange={(opt) => handleChange("roomsType", opt)}
            closeMenuOnSelect={false}
            isMulti
            options={roomOptions}
          />

          <div className="add-new-voucher-modal__date-selector">
            <p style={{ margin: 0 }}>
              <i className="fi flaticon-calendar"></i>Валиден от
            </p>
            <label>
              <ReactDatePicker
                onChange={(date) => handleChange("startDate", date)}
                selected={data.startDate}
                dateFormat="dd.MM.yyyy"
              />
              <span className="ti-angle-down"></span>
            </label>
          </div>

          <div className="add-new-voucher-modal__date-selector">
            <p style={{ margin: 0 }}>
              <i className="fi flaticon-calendar"></i>Валиден до
            </p>
            <label>
              <ReactDatePicker
                onChange={(date) => handleChange("endDate", date)}
                selected={data.endDate}
                dateFormat="dd.MM.yyyy"
              />
              <span className="ti-angle-down"></span>
            </label>
          </div>
          <input
            value={data.discount}
            onChange={(e) => handleChange("discount", e.currentTarget.value)}
            placeholder="Процент отстъпка"
            className="add-new-voucher-modal__normal-input"
          />
          <input
            value={data.minNights}
            onChange={(e) => handleChange("minNights", e.currentTarget.value)}
            placeholder="Минимум нощувки"
            className="add-new-voucher-modal__normal-input"
            style={{ marginTop: "18px" }}
            type="number"
          />
          <Button
            text="Създай"
            type="submit"
            onClick={(e) => createVoucher(e)}
            classes={["create-voucher-button"]}
          />
        </form>
      </div>
    </div>
  );
};
