import React from "react";
import { useTranslation } from "react-i18next";
import { getDateViewFromPeriod } from "../../../../utils/date";
import getTaxedPrice from "../../../../utils/getRoomPriceWithTax";
import periods from "../../../../utils/periods";

const Pricing = ({ roomData }) => {
  const { t } = useTranslation();
  return (
    <div className="pricing-area">
      <div className="room-title">
        <h2>{t("pricing")}</h2>
      </div>
      <div className="pricing-table">
        <table className="table-responsive pricing-wrap">
          <thead>
            <tr>
              {Object.keys(periods).map((period, index) => {
                return (
                  <th key={index}>
                    {periods[period].map((item, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          {getDateViewFromPeriod(item)}
                          {idx !== periods[period].length - 1 && ","}
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {getTaxedPrice(roomData.periodOnePrice)}
                {t("bgn")}
              </td>
              <td>
                {getTaxedPrice(roomData.periodTwoPrice)}
                {t("bgn")}
              </td>
              <td>
                {getTaxedPrice(roomData.periodThreePrice)}
                {t("bgn")}
              </td>
              <td>
                {getTaxedPrice(roomData.periodFourPrice)}
                {t("bgn")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="map-area">
        <iframe
          title="hotel-location"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11618.615370895252!2d28.0406391!3d43.2796273!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfaf3e6201159e0ba!2z0KHQvtGE0LjRjyDQpdC-0YLQtdC7!5e0!3m2!1sbg!2sbg!4v1651513859819!5m2!1sbg!2sbg"
          width="600"
          height="450"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Pricing;
