import http from "./httpService";
import { apiUrl } from "../config/config.json";
const apiEndpoint = apiUrl + "/rooms";

const getRooms = async () => {
  return await http.get(apiEndpoint);
};

const getRoomByName = async (name) => {
  return await http.get(apiEndpoint + "/" + name);
};

const editRoom = async (type, data) => {
  const res = await http.put(apiEndpoint + "/" + type, data);
  return res;
};

const createRoom = async (data) => {
  await http.post(apiEndpoint, data);
};

const exportedFunctions = {
  getRooms,
  getRoomByName,
  editRoom,
  createRoom,
};

export default exportedFunctions;
