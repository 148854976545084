import React from "react";
import { useTranslation } from "react-i18next";
import Features from "../Features";
import { Icon } from "../Icon/Icon";

import "./style.css";

const Service = (props) => {
  const { t } = useTranslation();

  const serviceWrap = [
    // { title: t("bike-rent"), faIcon: "bike" },
    // {
    //   sIcon: "fi flaticon-pool",
    //   title: "Външен басейн",
    // },
    // { title: "Детски кът", faIcon: "playground" },
    { title: t("animation"), faIcon: "animation" },
    // { title: t("fitness"), faIcon: "fitness" },
    { title: t("pool-table"), faIcon: "8-ball" },
    { title: t("vault"), faIcon: "vault" },
    // { title: "Паркинг", faIcon: "parking" },
    { title: t("currency-exchange"), faIcon: "money-transfer" },
    { title: t("a-la-carte"), faIcon: "restaurant" },
    // { title: t("lobby-bar"), faIcon: "lobby-bar" },
    { title: t("conference"), faIcon: "conference" },
    { title: t("laundry"), faIcon: "laundry" },
    {
      title: t("football-field"),
      faIcon: "football-field",
    },
    // {
    //   title: t("souvenir"),
    //   faIcon: "shopping-bag",
    // },

    // {
    //     sIcon: "fi flaticon-tray",
    //     title: "Delicious Food",
    //     des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    //     link: "/service-single",
    //   },
    // {
    //   sIcon: "fi flaticon-herbal",
    //   title: "Spa salon",
    //   des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    //   link: "/service-single",
    // },
    // {
    //   sIcon: "fi flaticon-bowling",
    //   title: "Game Room",
    //   des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    //   link: "/service-single",
    // },
    // {
    //   sIcon: "fi flaticon-taxi",
    //   title: "Airport Taxi",
    //   des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    //   link: "/service-single",
    // },
    // {
    //   sIcon: "fi flaticon-breakfast",
    //   title: "Breakfast",
    //   des: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    //   link: "/service-single",
    // },
  ];

  return (
    <div className="service-area section-padding">
      <div className="container">
        <Features />
        <div className="other-services">
          <h3>{t("others")}</h3>
          <div className="row">
            {serviceWrap.map((service, sev) => (
              <div
                className="col col-lg-4 col-md-6 custom-grid col-12"
                key={sev}
              >
                <div className="service-item">
                  <div className="service-icon">
                    {service.sIcon ? (
                      <i className={service.sIcon}></i>
                    ) : (
                      <Icon
                        icon={service.faIcon}
                        classes={["service-icon-svg"]}
                      />
                    )}
                  </div>
                  <div className="service-text">
                    <h2>
                      <p>{service.title}</p>
                    </h2>
                    <p>{service.des}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
