import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import settings from "../../utils/sliderSettings";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import margarita from "../../images/team/margarita.png";
import nikol from "../../images/team/nikol.png";
import kiril from "../../images/team/kiril.png";
import petq from "../../images/team/petq.png";
import qnica from "../../images/team/qnica.png";
import didi from "../../images/team/didi.png";

export default function Team() {
  const { t } = useTranslation();
  let newSettings = { ...settings, variableWidth: true };
  const team = [kiril, margarita, nikol, didi, petq, qnica];

  return (
    <div className="team-container">
      <h2>{t("team")}</h2>
      <div className="team-description-container">
        <div className="team-description">
          <h5 className="team-description__heading">
            {t("team-text-1")}
            <br />
            {t("team-text-2")}
          </h5>
          <ul>
            <li>{t("team-text-3")}</li>
            <li>{t("team-text-4")}</li>
            <li>{t("team-text-5")}</li>
            <li>{t("team-text-6")}</li>
          </ul>
        </div>
      </div>
      <Slider className="team-slider" {...newSettings}>
        {team.map((item, index) => {
          return (
            <div key={index} className="item">
              <div className="team-item">
                <div className="team-img">
                  <img src={item} alt="" />
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
