import React, { Fragment } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Service from "../../components/Service";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import cover from "../../images/services/cover.jpg";

const ServicePage = () => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle pageTitle={"services"} img={cover} />
      <Service />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
