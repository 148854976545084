import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import hotelMain from "../../images/hotel-main.jpg";

const About2 = (props) => {
  const { t } = useTranslation();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  return (
    <div className="wpo-about-area-2 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="wpo-about-img">
              <img src={hotelMain} alt="" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 colsm-12">
            <div className="wpo-about-text">
              <div className="wpo-section-title">
                <h3>{t("home-title-1")}</h3>
              </div>
              <p>{t("about-text-4")}</p>
              <div className="btns">
                <Link
                  onClick={ClickHandler}
                  to="/about"
                  className="theme-btn-s2"
                >
                  {t("more-about-us")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About2;
