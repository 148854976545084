import React from "react";
import Event from "../../components/Event";
import Footer from "../../components/footer";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import cover from "../../images/events/cover.jpg";
import "./style.css";

export default function index() {
  return (
    <React.Fragment>
      <Navbar />
      <PageTitle pageTitle={"events"} img={cover} />
      <Event title="weddings" />
      <Event title="seminars" />
      <Footer />
    </React.Fragment>
  );
}
