import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDateDB, getTomorrowDate } from "../../utils/date";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import availabilityService from "../../services/availabilityService";
import "./style.css";
import { toast } from "react-toastify";

const options = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
];

const SearchSection = ({
  selectClass,
  hasDates,
  setBookingData,
  setRoomsToDisplay,
  setIsRoomsLoading,
  setShowBookingView,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const childrenOptions = options.map((x) => {
    return {
      value: x.value,
      label: `${x.label} ${x.value > 0 ? t("year-cap") : ""}`,
    };
  });

  const [data, setData] = useState({
    startDate: new Date(),
    endDate: getTomorrowDate(),
    adults: { value: 1, label: "1" },
    children: { value: 0, label: "0" },
  });

  const getAvailability = async (data) => {
    let dataCopy = { ...data };
    dataCopy["children"] = data.children.value;
    dataCopy["adults"] = data.adults.value;
    dataCopy["startDate"] = getDateDB(data.startDate);
    dataCopy["endDate"] = getDateDB(data.endDate);
    const res = await availabilityService.getAvailabilityForPeriod(dataCopy);
    setShowBookingView(true);
    setRoomsToDisplay(res.data);
    setIsRoomsLoading(false);
  };

  useEffect(() => {
    if (location.state) {
      getAvailability(location.state.data);
      setData(location.state.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const handleChange = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData({ ...dataCopy });
    if (field === "adults" || field === "children") {
      setBookingData({ ...dataCopy });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (data.adults.value === 0) {
      toast.error(t("choose-adults"));
      return;
    }

    const currentLocation = history.location.pathname;

    const startDate = new Date(data.startDate.setHours(0, 0, 0, 0)).getTime();
    const endDate = new Date(data.endDate.setHours(0, 0, 0, 0)).getTime();
    if (startDate >= endDate) {
      toast.error(t("invalid-period"));
      return;
    }
    if (currentLocation === "/home" || currentLocation === "/") {
      history.push({
        pathname: "/room",
        state: {
          data,
        },
      });
    } else {
      setIsRoomsLoading(true);
      await getAvailability(data);
      setIsRoomsLoading(false);
      setBookingData(data);
    }
  };

  return (
    <div
      className={`wpo-select-section ${selectClass} ${hasDates && "has-dates"}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="wpo-select-wrap">
              <div className="wpo-select-area">
                <form onSubmit={SubmitHandler} className="clearfix">
                  <div className="select-sub">
                    <span className="search-section-span">
                      <i className="fi flaticon-calendar"></i>
                      {t("check-in")}
                    </span>
                    <div className="form-group">
                      <div id="filterDate">
                        <div
                          className="input-group date"
                          data-date-format="dd.mm.yyyy"
                        >
                          <label className="datepicker-label">
                            <DatePicker
                              selected={data.startDate}
                              onChange={(date) =>
                                handleChange("startDate", date)
                              }
                              dateFormat="dd.MM.yyyy"
                              isClearable={false}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                            {/* <div className="input-group-addon"> */}
                            <span className="ti-angle-down"></span>
                            {/* </div> */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select-sub second-child">
                    <span className="search-section-span">
                      <i className="fi flaticon-calendar"></i>
                      {t("check-out")}
                    </span>
                    <div className="form-group">
                      <div id="filterDate2">
                        <div
                          className="input-group date"
                          data-date-format="dd.mm.yyyy"
                        >
                          <label className="datepicker-label">
                            <DatePicker
                              selected={data.endDate}
                              onChange={(date) => handleChange("endDate", date)}
                              dateFormat="dd.MM.yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                            {/* <div className="input-group-addon"> */}
                            <span className="ti-angle-down"></span>
                            {/* </div> */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select-sub wide">
                    <span className="search-section-span">
                      <i className="fi flaticon-user"></i> {t("adults")}
                    </span>
                    <FormSelect
                      options={options}
                      field="adults"
                      handleChange={handleChange}
                      defaultValue={data.adults}
                    />
                  </div>
                  <div className="select-sub wide">
                    <span className="search-section-span">
                      <i className="fi flaticon-user"></i> {t("children")}
                    </span>
                    <FormSelect
                      field="children"
                      options={childrenOptions}
                      handleChange={handleChange}
                      defaultValue={data.children}
                    />
                  </div>

                  {width < 768 && (
                    <div className="select-sub select-sub__submit-button">
                      <button
                        onClick={(e) => onSubmit(e)}
                        className="theme-btn-s2"
                        type="submit"
                      >
                        {t("check-availability")}
                      </button>
                    </div>
                  )}
                </form>
                {width >= 768 && (
                  <div className="solo-button-container">
                    <button
                      onClick={(e) => onSubmit(e)}
                      className="theme-btn-s2"
                      type="submit"
                    >
                      {t("check-availability")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FormSelect = ({ field, options, handleChange, defaultValue }) => {
  return (
    <Select
      key={field}
      defaultValue={defaultValue}
      value={defaultValue}
      className="select search-section-select"
      onChange={(value) => handleChange(field, value)}
      options={options}
      components={{
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (base, state) => ({
          ...base,
          border: 0,
          boxShadow: "none",
          "&:hover": {
            border: "1px solid black",
          },
        }),
      }}
    />
  );
};

export default SearchSection;
export { FormSelect };
