import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";

import subscibersService from "../../services/subscribersService";
import "./style.css";
export default function SubscribersPage() {
  const [subscribers, setSubscribers] = useState([]);
  useEffect(() => {
    subscibersService.getSubscribers().then((res) => {
      if (res.data) {
        setSubscribers(res.data);
      }
    });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="subscribers-container">
        <h3>Общо {subscribers.length} абоната</h3>
        <table className="subscribers-table">
          <thead>
            <tr>
              <th>Имейл</th>
            </tr>
          </thead>
          <tbody>
            {subscribers.map((sub, index) => {
              return (
                <tr key={index}>
                  <td>
                    <h3>{sub.email}</h3>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
