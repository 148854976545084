import React from "react";
import SearchSection from "./Rcomponents/select";
import Rooms from "./Rcomponents/room";
import Description from "./Rcomponents/description";
import "./style.css";

const RoomSingleSection = ({ room, bookingData }) => {
  return (
    <div className="room-single-page">
      <SearchSection room={room} bookingData={bookingData} />
      <Rooms room={room} />
      <Description room={room} canBook={true} />
    </div>
  );
};

export default RoomSingleSection;
