import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";

const userTabs = [
  "home",
  "about",
  "room",
  "events",
  "offers",
  "services",
  "restaurants",
  "reviews",
  "gallery",
  "contact",
];

const adminTabs = [
  "rooms",
  "reservations",
  "offers",
  "reviews",
  "vouchers",
  "subscribers",
];

export default function MobileMenu() {
  const { t, i18n } = useTranslation();
  const [isMenuShown, setIsMenuShown] = useState(false);
  const [isOpen, setIsOpen] = useState(0);
  const language = i18n.language;
  const languages = ["bg", "en", "de", "ro", "ru"];
  const activeTab = window.location.href.split("/")[3];
  // const adminTab = window.location.href.split("/")[4];
  const isAdmin = activeTab === "admin";

  const tabs = isAdmin ? adminTabs : userTabs;
  const menus = tabs.map((tab, index) => {
    return {
      id: index + 1,
      title: t(tab),
      link: `/${tab}`,
    };
  });

  const menuHandler = () => {
    setIsMenuShown(!isMenuShown);
  };

  const handleIsOpen = (id) => () => {
    setIsOpen(id);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("hotel-sofia-lang", newLanguage);
  };

  return (
    <div>
      <div className={`mobileMenu ${isMenuShown ? "show" : ""}`}>
        {/* <div className="clox" onClick={menuHandler}>Close Me</div> */}

        <ul className="responsivemenu">
          {menus.map((item) => {
            return (
              <li key={item.id}>
                {item.submenu ? (
                  <p onClick={handleIsOpen(item.id)}>
                    {item.title}
                    {item.submenu ? (
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  <Link onClick={ClickHandler} to={item.link}>
                    {item.title}
                  </Link>
                )}
                {item.submenu ? (
                  <Collapse isOpen={item.id === isOpen}>
                    <Card>
                      <CardBody>
                        <ul>
                          {item.submenu.map((submenu) => (
                            <li key={submenu.id}>
                              <Link
                                onClick={ClickHandler}
                                className="active"
                                to={submenu.link}
                              >
                                {submenu.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>
                ) : (
                  ""
                )}
              </li>
            );
          })}
        </ul>
        <hr className="mobile-menu-separator" />
        <div className="mobile-menu-languages">
          {languages.map((lang, i) => {
            return (
              <div
                onClick={() => changeLanguage(lang)}
                key={i}
                className={`mobile-menu-single-language ${
                  lang === language && "selected-language"
                }`}
              >
                {lang.toUpperCase()}
              </div>
            );
          })}
        </div>
      </div>

      <div className="showmenu" onClick={menuHandler}>
        <i className="fa fa-bars" aria-hidden="true"></i>
      </div>
    </div>
  );
}
