import React, { useEffect } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const hasChosenLanguage = localStorage.getItem("hotel-sofia-lang");
    if (hasChosenLanguage) {
      i18n.changeLanguage(hasChosenLanguage);
    } else if (tz !== "Europe/Sofia") {
      let newLanguage;
      if (tz === "Europe/Berlin") {
        newLanguage = "de";
      } else if (tz === "Europe/Bucharest") {
        newLanguage = "ro";
      } else {
        newLanguage = "en";
      }
      localStorage.setItem("hotel-sofia-lang", newLanguage);
      i18n.changeLanguage(newLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App" id="scrool">
      <AllRoute />
      <ToastContainer />
    </div>
  );
};

export default App;
