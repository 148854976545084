import axios from "axios";

/**
 * sets the jwt token to the request headers
 *
 * @param {string} jwt the jwt token from the local storage
 *
 */
function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const exportedFunctions = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
};

export default exportedFunctions;
