import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import "./style.css";

const PageTitle = (props) => {
  const { t } = useTranslation();
  return (
    <section
      className="page-title"
      style={{
        background: `url(${props.img}) center center/cover no-repeat local`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col col-xs-12 page-title-wrapper">
            <h2 style={{ fontFamily: "Roboto" }}>{t(props.pageTitle)}</h2>
            {props.pagesub && (
              <h4 style={{ color: "white" }}>{t(props.pagesub)}</h4>
              // <ol className="breadcrumb">
              //   <li>
              //     <span>{t(props.pagesub)}</span>
              //   </li>
              // </ol>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
