import React, { useState } from "react";
import GalleryFilter, {
  FullScreenGallery,
  GalleryContent,
} from "../../components/GalleryFilter";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";

import cover from "../../images/gallery/cover.jpg";

// Hotel
import hotel1 from "../../images/gallery/hotel/hotel-1.jpg";
import hotel2 from "../../images/gallery/hotel/hotel-2.jpg";
import hotel3 from "../../images/gallery/hotel/hotel-3.jpg";
import hotel4 from "../../images/gallery/hotel/hotel-4.jpg";
import hotel5 from "../../images/gallery/hotel/hotel-5.jpg";
import hotel6 from "../../images/gallery/hotel/hotel-6.jpg";
import hotel7 from "../../images/gallery/hotel/hotel-7.jpg";
import hotel8 from "../../images/gallery/hotel/hotel-8.jpg";
import hotel9 from "../../images/gallery/hotel/hotel-9.jpg";
import hotel10 from "../../images/gallery/hotel/hotel-10.jpg";
import hotel11 from "../../images/gallery/hotel/hotel-11.jpg";
import hotel12 from "../../images/gallery/hotel/hotel-12.jpg";
import hotel13 from "../../images/gallery/hotel/hotel-13.jpg";
import hotel14 from "../../images/gallery/hotel/hotel-14.jpg";
import hotel15 from "../../images/gallery/hotel/hotel-15.jpg";
import hotel16 from "../../images/gallery/hotel/hotel-16.jpg";
import hotel17 from "../../images/gallery/hotel/hotel-17.jpg";
import hotel18 from "../../images/gallery/hotel/hotel-18.jpg";

// Beach
import beach1 from "../../images/gallery/beach/beach-1.jpg";
import beach2 from "../../images/gallery/beach/beach-2.jpg";
import beach3 from "../../images/gallery/beach/beach-3.jpg";
import beach4 from "../../images/gallery/beach/beach-4.jpg";
import beach5 from "../../images/gallery/beach/beach-5.jpg";
import beach6 from "../../images/gallery/beach/beach-6.jpg";

// Rooms
import room1 from "../../images/gallery/rooms/room-1.jpg";
import room2 from "../../images/gallery/rooms/room-2.jpg";
import room3 from "../../images/gallery/rooms/room-3.jpg";
import room4 from "../../images/gallery/rooms/room-4.jpg";
import room5 from "../../images/gallery/rooms/room-5.jpg";
import room6 from "../../images/gallery/rooms/room-6.jpg";
import room7 from "../../images/gallery/rooms/room-7.jpg";
import room8 from "../../images/gallery/rooms/room-8.jpg";
import room9 from "../../images/gallery/rooms/room-9.jpg";
import room10 from "../../images/gallery/rooms/room-10.jpg";
import room11 from "../../images/gallery/rooms/room-11.jpg";
import room12 from "../../images/gallery/rooms/room-12.jpg";
import room13 from "../../images/gallery/rooms/room-13.jpg";
import room14 from "../../images/gallery/rooms/room-14.jpg";
import room15 from "../../images/gallery/rooms/room-15.jpg";

// Restaurants
import restaurant1 from "../../images/gallery/restaurants/restaurant-1.jpg";
import restaurant2 from "../../images/gallery/restaurants/restaurant-2.jpg";
import restaurant3 from "../../images/gallery/restaurants/restaurant-3.jpg";
import restaurant4 from "../../images/gallery/restaurants/restaurant-4.jpg";
import restaurant5 from "../../images/gallery/restaurants/restaurant-5.jpg";
import restaurant6 from "../../images/gallery/restaurants/restaurant-6.jpg";
import restaurant7 from "../../images/gallery/restaurants/restaurant-7.jpg";
import restaurant8 from "../../images/gallery/restaurants/restaurant-8.jpg";
import restaurant9 from "../../images/gallery/restaurants/restaurant-9.jpg";
import restaurant10 from "../../images/gallery/restaurants/restaurant-10.jpg";
import restaurant11 from "../../images/gallery/restaurants/restaurant-11.jpg";
import restaurant12 from "../../images/gallery/restaurants/restaurant-12.jpg";
import restaurant13 from "../../images/gallery/restaurants/restaurant-13.jpg";
import restaurant14 from "../../images/gallery/restaurants/restaurant-14.jpg";



import { useTranslation } from "react-i18next";
import "./style.css";

export default function GalleryPage() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  const [selectedImage, setSelectedImage] = useState("");

  const tabs = ["all", "hotel", "pool", "rooms", "dining"];
  const handleClick = (tabId) => setActiveTab(tabId);

  const items = {
    all: [
      hotel1,
      hotel2,
      hotel3,
      hotel4,
      hotel5,
      hotel6,
      hotel7,
      hotel8,
      hotel9,
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
      beach1,
      beach2,
      beach3,
      beach4,
      beach5,
      beach6,
      room1,
      room2,
      room3,
      room4,
      room5,
      room6,
      room7,
      room8,
      room9,
      room10,
      room11,
      room12,
      room13,
      room14,
      room15,
      restaurant1,
      restaurant2,
      restaurant3,
      restaurant4,
      restaurant5,
      restaurant6,
      restaurant7,
      restaurant8,
      restaurant9,
      restaurant10,
      restaurant11,
      restaurant12,
      restaurant13,
      restaurant14,
    ],
    hotel: [
      hotel1,
      hotel2,
      hotel3,
      hotel4,
      hotel5,
      hotel6,
      hotel7,
      hotel8,
      hotel9,
      hotel10,
      hotel11,
      hotel12,
      hotel13,
      hotel14,
      hotel15,
      hotel16,
      hotel17,
      hotel18,
    ],
    pool: [
      beach1,
      beach2,
      beach3,
      beach4,
      beach5,
      beach6,
    ],
    rooms: [
      room1,
      room2,
      room3,
      room4,
      room5,
      room6,
      room7,
      room8,
      room9,
      room10,
      room11,
      room12,
      room13,
      room14,
      room15,
    ],
    dining: [restaurant1,
      restaurant2,
      restaurant3,
      restaurant4,
      restaurant5,
      restaurant6,
      restaurant7,
      restaurant8,
      restaurant9,
      restaurant10,
      restaurant11,
      restaurant12,
      restaurant13,
      restaurant14,],
  };

  const renderItem = (item) => {
    return <img src={item} alt="" className="img img-responsive" />;
  };

  const selectImage = (image) => setSelectedImage(image);

  return (
    <div>
      <Navbar />
      <PageTitle pageTitle={t("gallery")} img={cover} />
      <div className="gallery-page-filter">
        <GalleryFilter {...{ tabs, activeTab, handleClick }} />
      </div>
      <GalleryContent {...{ items, activeTab, renderItem, selectImage }} />
      {selectedImage && (
        <FullScreenGallery
          image={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
      <Footer />
    </div>
  );
}
